import React, {Component} from 'react';
import { FormGroup,
    InputGroup,
    Switch,
    Label,
    Spinner
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import Select from "react-select";

class Acta extends Component {
    state={
        numero:'',
        fechaReunion:new Date(),
        lugarReunion: '',
        tipoReunion:null,
        actaAprobada:false,
        fechaAprobacion:new Date(),
        sistemaAprobacion:'',
        actaFirmada:false,
        cargando:false
    }

    componentDidMount=async()=>{
        this.setState({cargando:true}, async () => {
            if(this.props.acta && Object.keys(this.props.acta).length >0){
                let tipoReunion=this.props.tiposReuniones.find(tipo=>{return tipo.value===this.props.acta.ID_TIPO_CONVOCATORIA})
                this.setState({
                    numero:this.props.acta.NUMERO,
                    fechaReunion:new Date(this.props.acta.FECHA_REUNION),
                    lugarReunion:this.props.acta.LUGAR,
                    actaAprobada:this.props.acta.APROBADA,
                    fechaAprobacion:this.props.acta.FECHA_APROBACION?new Date(this.props.acta.FECHA_APROBACION):new Date(),
                    actaFirmada:this.props.acta.FIRMADA,
                    sistemaAprobacion:this.props.acta.SISTEMA_APROBACION?this.props.acta.SISTEMA_APROBACION:'',
                    tipoReunion:tipoReunion,
                })
            }
            this.setState({cargando:false})
        });
        
    }

    render() {
        const handleLugarReunion = (e) => {
            this.setState({ lugarReunion: e.target.value })
        }

        const handleNumero = (e) => {
            this.setState({ numero: e.target.value })
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY",
        }

        const escogeFecha = (nuevaFecha) => {
            this.setState({ fechaReunion: nuevaFecha })
        }

        const seleccionarTipoReunion  = (value) => { 
            this.setState({tipoReunion: value})
        }

        const handleActaAprobada = () => {
            this.setState({ actaAprobada: !this.state.actaAprobada})
        }

        const escogeFechaAprobacion = (nuevaFecha) => {
            this.setState({ fechaAprobacion: nuevaFecha })
        }

        const handleSistemaAprobacion = (e) => {
            this.setState({ sistemaAprobacion: e.target.value })
        }

        const handleActaFirmada = () => {
            this.setState({ actaFirmada: !this.state.actaFirmada})
        }

        return(
            <>
            {this.state.cargando?
                <Spinner/>
            :
            <>
                <div id="dialogo-acta" className="bp3-card separarBordes">
                    <FormGroup
                        label={this.props.i18next.t("actas.nroacta",{ns:"page"})+":"}
                        inline={true}
                    >
                        <InputGroup id="nro-acta" value={this.state.numero} onChange={handleNumero} intent={this.state.numero?"primary":"danger"} />   
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("actas.fecha",{ns:"page"})+":"}
                        labelFor="fecha-reunion"
                        inline={true}
                    >
                        <div id="fecha-acta">
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                value={this.state.fechaReunion}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("actas.lugar",{ns:"page"})+":"}
                        labelFor="lugar-reunion"
                        inline={true}
                    >
                        <InputGroup id="lugar-reunion" value={this.state.lugarReunion} onChange={handleLugarReunion} intent={this.state.lugarReunion?"primary":"danger"} />   
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("actas.reunion",{ns:"page"})+":"}
                        labelFor="numero-acta"
                    >
                        <div id="select-tipo-reunion" style={!this.state.tipoReunion?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.props.tiposReuniones}
                                className="pixelAlrededor"
                                onChange={seleccionarTipoReunion}
                                value={this.state.tipoReunion}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("actas.questionapprove",{ns:"page"})}
                        labelFor="acta-aprobada"
                        inline={true}
                    >
                        <Switch id="boleano-acta-aprobada" innerLabelChecked={this.props.i18next.t("yes",{ns:"global"})} innerLabel={this.props.i18next.t("no",{ns:"global"})} onChange={handleActaAprobada} checked={this.state.actaAprobada} />
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("actas.fechaapprove",{ns:"page"})+":"}
                        labelFor="fecha-aprobacion"
                        inline={true}
                    >
                        <div id="fecha-aprobacion-acta">
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFechaAprobacion}
                                disabled={!this.state.actaAprobada}
                                value={this.state.fechaAprobacion}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("actas.sistemaapprove",{ns:"page"})+":"}
                        labelFor="sistema-aprobacion"
                        inline={true}
                    >
                        <InputGroup id="sistema-aprobacion-acta" value={this.state.sistemaAprobacion}  disabled={!this.state.actaAprobada} onChange={handleSistemaAprobacion} />   
                    </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("actas.questionfirm",{ns:"page"})}
                        labelFor="acta-firmada"
                        inline={true}
                    >
                            <Switch id="boleano-acta-firmada" innerLabelChecked={this.props.i18next.t("yes",{ns:"global"})} innerLabel={this.props.i18next.t("no",{ns:"global"})} onChange={handleActaFirmada} checked={this.state.actaFirmada} />
                    </FormGroup>
                    <Label>{this.props.i18next.t("actas.acudidopersonalmente",{ns:"page"})} <b>{this.props.asistentes_vota}</b> {this.props.i18next.t("actas.deltotal",{ns:"page"})} <b>{this.props.asistentes.length}</b> </Label>
                    <Label>{this.props.i18next.t("actas.acudidorepresentando",{ns:"page"})} <b>{this.props.representantes_vota}</b> {this.props.i18next.t("actas.deltotal",{ns:"page"})} <b>{this.props.asistentes.length}</b> </Label>
                </div>
            </>
            }
            </>
        );
    }
}

export default Acta;