import React, {Component} from 'react';
import { Navbar,
    Button,
    Tooltip,
    Dialog,
    Classes,
    RadioGroup,
    Radio,
    AnchorButton,
    Intent,
    Card,
    Elevation
} from "@blueprintjs/core";
import { Cargando } from '../Cargando';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from "../BootstrapTable";

class VotosAcuerdo extends Component {
    state={
        votoSeleccionado:null,
        cargando:false,
        modalAbierto:false,
        votacion: 1
    }

    render() {

        const abrirModal = () => {

            if(!this.state.votoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                votacion:this.state.votoSeleccionado.voto,
                modalAbierto: true
            })

        }

        function boolFormatter(cell, row) {
            let tipo=""
            switch(cell) {
                case -1:
                    tipo="En contra"
                break;
                case 1:
                    tipo="A Favor"
                break;
                case 0:
                    tipo="Abstención"
                break;
                default:
                    tipo="";
                break;
            }
            return (
                tipo
            );
        }

        const columns = [
            {
                dataField: 'nombre',
                text: this.props.i18next.t("name",{ns:"global"})
            },
            {
                dataField: 'voto',
                text: this.props.i18next.t("actas.voto",{ns:"page"}),
                formatter: boolFormatter
            }
        ];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    votoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const cerrarModal=()=>{
            this.setState({modalAbierto:false});
        }

        const handleVotacion = (event) => {
            this.setState({votacion: parseInt(event.currentTarget.value)})
        }

        const guardar=async ()=>{
            let votos=this.props.votos
            let voto=await votos.find(voto=>{return voto.idPersona===this.state.votoSeleccionado.idPersona})
            voto.voto=this.state.votacion
            await  this.props.setVotos(votos)
            cerrarModal()
        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                <>
                    <Dialog
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={this.props.i18next.t("actas.titlevoto",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <div id="dialogo-actas">
                            <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                                <p>{this.props.i18next.t("actas.selectvoto",{ns:"page"})}:</p>
                                <div id="sentido-voto">
                                    <RadioGroup
                                        onChange={handleVotacion}
                                        selectedValue={this.state.votacion}
                                        large={true}
                                    >
                                        <Radio label={this.props.i18next.t("actas.afavor",{ns:"page"})} value={1} />
                                        <Radio label={this.props.i18next.t("actas.encontra",{ns:"page"})} value={-1} />
                                        <Radio label={this.props.i18next.t("actas.abstencion",{ns:"page"})} value={0} />
                                    </RadioGroup>
                                </div>
                            </Card>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <AnchorButton id="guardar-sentido-voto" intent={Intent.PRIMARY} onClick={guardar}>
                                {this.props.i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                            <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                                {this.props.i18next.t("cancelar",{ns:"global"})}
                            </AnchorButton>
                            </div>
                        </div>   
                    </Dialog>
                    <Navbar>
                        <Navbar.Group>
                            <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})}>
                                <Button
                                    icon="insert"
                                    id="btn-cambiar-voto"
                                    text={this.props.i18next.t("actas.voto",{ns:"page"})}
                                    intent="danger"
                                    onClick={abrirModal}
                                    className="iconoDerecha bp3-minimal"
                                />
                            </Tooltip>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                        keyField='idPersona'
                        data={ this.props.votos }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                    />
                </>
                }
            </>  
        );
    }
}

export default VotosAcuerdo;