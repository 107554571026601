/* eslint-disable array-callback-return */
import React from "react"
import { Spinner, Dialog, FormGroup, Button, Collapse, Card, Elevation, Callout } from "@blueprintjs/core"
import pantallaInicio from '../../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import gql from "graphql-tag";
import moment from 'moment';
import { withApollo } from "react-apollo";
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import styles from '../stylesPrint';


class Libros extends React.Component {

    state={
        abierto1: false,
        abierto2: false,
        abierto3: false,
        variaciones:[],
        distribuciones:[],
        distribucionesActuales:[],
        fechaCapitalInicial:moment().format("DD-MM-YYYY"),
        valorTitulolInicial:0.00,
        numTitInicial:0,
        valorCapitalInicial:0.00,
        nTitulosFinal:0.00,
        valorFinal:0.00,
        cargando:true,
        imprimirActivado:false,
        cargandoImprimir:false,
    }

    
    valTitulo=0
    distribucionesActuales=[]
    dist=[]
    


    // obtenerLogoEmpresa=()=>{

    //     //obtencion logo de la empresa
    //     this.props.client 
    //     .query({
    //         query: gql`
    //             {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
    //         `,fetchPolicy:'network-only'             
    //     }).then(result=>{ 
    //         if(result.data.adjuntos.length>0){

    //             var request = require('request').defaults({ encoding: null,withCredentials:true });

    //             request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
    //                 if (!error && response.statusCode === 200) {
    //                 let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
    //                 this.setState({logotipo:data})
    //                 }
    //             });

    //         }
    //     })
    
    // }
    // cargarPersonasFisicas= async()=>{
	// 	let personasFisicas=await this.props.client
	// 	.query({
	// 		query: gql`
    //         {personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}`,
	// 		fetchPolicy:'network-only'

	// 	}).then(result=>{ 
	// 		return result.data.personasFisicas
	// 	})
	// 	return personasFisicas
	// }
	// cargarPersonasJuridicas=async()=>{
	// 	let personasJuridicas= await  this.props.client
	// 	.query({
	// 		query: gql`
    //         {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}`,
	// 		fetchPolicy:'network-only'

	// 	}).then(result=>{ 
	// 		return result.data.personasJuridicas
	// 	})
	// 	return personasJuridicas	
	// }
    // cargarDatos= async()=>{
	// 	let personasFisicas= await this.cargarPersonasFisicas()
	// 	let personasJuridicas= await this.cargarPersonasJuridicas()
	// 	this.setState({
	// 		personasFisicas:personasFisicas,
	// 		personasJuridicas:personasJuridicas,
	// 	})
	// }




    obtenerDistribuciones=async()=>{
        let capInicialDist=this.props.capSocialInicialDistribucion
        // await this.props.client 
        // .query({
        //     query: gql`
        //         {capSocialInicialDistribucion{ID_PERSONA,NUMERO_TITULOS,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
        //     `,fetchPolicy:'network-only'              
        // }).then(result=>{ 
        //     return result.data.capSocialInicialDistribucion
        // })
      
       
        let variaciones=await this.props.client 
        .query({
            query:gql`
            {capSocialVariaciones(ORDEN:"FECHA,CAMBIO_VALOR_TITULO,ASC"){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.capSocialVariaciones
        })  
        // let distribucion=await this.props.client
        //     .query({
        //         query: gql`
        //         {capSocialVariacionesDistribucion{ID_VARIACION,ID_PERSONA,VARIACION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
        //         `,fetchPolicy:'network-only'
        // }).then(result=>{
        //     return result.data.capSocialVariacionesDistribucion
        // })
        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                ID_TRANSMISION,
                FECHA,
                ORIGEN,
                FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                TIPO_ACCION,
                NUMERO_TITULOS,
                NUMERACION_TITULOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
        let capInicial=this.props.capSocialInicial
        // await this.props.client
        // .query({
        //     query: gql`
        //         {capSocialInicial{FECHA_VALIDEZ,VALOR_TITULO}}
        //     `,fetchPolicy:'network-only'
        // }).then(async result=>{ 
        //     return result.data.capSocialInicial
        // })       

        
        let numTitulos=0
        let socios=[]

        //obtenemos el capital social distribucion inicial
        // await this.props.client 
        // .query({
        //     query: gql`
        //         {capSocialInicialDistribucion{ID_PERSONA,NUMERO_TITULOS,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
        //     `,fetchPolicy:'network-only'              
        // }).then(result=>{ 
            capInicialDist.forEach(socio=> {
                numTitulos+=socio.NUMERO_TITULOS    
                socios.push(socio)
            })
        // })
            //buscamos los nombres de los socios
        for(let socio of socios){  
           console.log(socio)
            let nombre=""
            //Si es persona fisica...
            if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                this.props.personasFisicas.find(persona=>{
                    if (persona.ID_PERSONA===socio.ID_PERSONA){
                        nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                    }
                })

                // nombre=await this.props.client
                // .query({
                //     query: gql` 
                //         {personasFisicas(ORDEN:"APELLIDO1",FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                //     `,fetchPolicy:'network-only'
                // }).then(result=>{ 
                //     return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                // })

            //Si es persona juridica
            }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.props.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===socio.ID_PERSONA){
                        nombre= persona.RAZON_SOCIAL
                    }
                })
                // nombre=await this.props.client
                // .query({
                //     query: gql`
                //         {personasJuridicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
                //     `,fetchPolicy:'network-only'
                // }).then(result=>{ 
                //     return result.data.personasJuridicas[0].RAZON_SOCIAL
                // })

            }
            socio.nombre=nombre
        }

        //agrupacion del array de socios por ID_PERSONA
        let sociosAgrupados = [];
        await socios.reduce(function(res, value) {
            if (!res[value.ID_PERSONA]) {
                res[value.ID_PERSONA] = { ID_PERSONA: value.ID_PERSONA,NOMBRE:value.nombre, NUMERO_TITULOS: 0 };
                sociosAgrupados.push(res[value.ID_PERSONA])
            }
            res[value.ID_PERSONA].NUMERO_TITULOS += value.NUMERO_TITULOS;
            return res;
        }, {});


        //obtencion de las varaciones
        // let variaciones=await this.props.client 
        // .query({
        //     query:gql`{capSocialVariaciones(ORDEN:"FECHA,CAMBIO_VALOR_TITULO,ASC"){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
        // }).then(result=>{ 
        //     return result.data.capSocialVariaciones
        // })

        let nTitulos=0
        let valorTitulo=0

        for(let variacion of variaciones){

            //variacion de incremento o decremento del número de títulos.

            if(variacion.ID_TIPO_VARIACION===1){
                //obtener las variaciones distribucion.
                let distribucion=await this.props.client
                .query({
                    query: gql`
                    {capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){ID_PERSONA,VARIACION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
                    `,fetchPolicy:'network-only'
                }).then(result=>{
                    return result.data.capSocialVariacionesDistribucion
                })
                

                for(let dis of distribucion){
                        
                        //buscamos la persona de la variacion.
                        let nombre=""
                        nTitulos+=dis.VARIACION
                        //Si es persona fisica...
                        if(dis.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                            this.props.personasFisicas.find(persona=>{
                                if(persona.ID_PERSONA===dis.ID_PERSONA){
                                    nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                                }
                            })

                        //Si es persona juridica
                        }else if(dis.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                            this.props.personasJuridicas.find(persona=>{
                                if(persona.ID_PERSONA===dis.ID_PERSONA){
                                    nombre= persona.RAZON_SOCIAL
                                }
                            })

                        }
                        dis.nombre=nombre
                } 
                
                variacion.distribucion=distribucion

            }else{

                //variación del valor del título.
                valorTitulo+=variacion.CAMBIO_VALOR_TITULO
            }

        }

        //OBTENCION DE LAS TRANSMISIONES.
        // let transmisiones=await this.props.client.query({
        //     query:gql`{capSocialTransmisiones{
        //         ID_TRANSMISION,
        //         FECHA,
        //         ORIGEN,
        //         FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
        //         DESTINO,
        //         FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
        //         TIPO_ACCION,
        //         NUMERO_TITULOS,
        //         NUMERACION_TITULOS
        //     }}`,fetchPolicy:'network-only'
        // }).then(async(result)=>{
        //     return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        // })

        let sociosAgrupadosActuales=JSON.parse(JSON.stringify(sociosAgrupados))

        //por cada transmision sumamos los titulos.
        for(let transmision of transmisiones){
            await this.obtenerNombreMiembros(transmision.FK_ORIGEN)
            await this.obtenerNombreMiembros(transmision.FK_DESTINO)

            let existe=await sociosAgrupadosActuales.find(socio=>{
                return socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA})
            //si no existe el socios lo insertamos.
            if(!existe){
                sociosAgrupadosActuales.push({
                    "ID_PERSONA":transmision.FK_DESTINO.ID_PERSONA,
                    "NOMBRE":transmision.FK_DESTINO.nombre,
                    "NUMERO_TITULOS":transmision.NUMERO_TITULOS
                })
            }else{

                await sociosAgrupadosActuales.find(socio=>{
                    if(socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                        socio.NUMERO_TITULOS+=transmision.NUMERO_TITULOS
                    }
                })
                //si existe ya le sumamos el numero de titulos al origen
            }

            //descontamos los titulos al origen de la transmision.
            await sociosAgrupadosActuales.find(socio=>{
                if(socio.ID_PERSONA===transmision.FK_ORIGEN.ID_PERSONA){
                    socio.NUMERO_TITULOS-=transmision.NUMERO_TITULOS
                }
            })
        }



        //CAP SOCIAL INICIAL. Obtención de la fecha y el valor inical del capital socila
        // await this.props.client
        // .query({
        //     query: gql`
        //         {capSocialInicial{FECHA_VALIDEZ,VALOR_TITULO}}
        //     `,fetchPolicy:'network-only'
        // }).then(async result=>{ 

            //si tenemos capita social inicial.
            if(capInicial.length>0){
                capInicial.forEach(capital=>{

              

                    this.setState({

                        numTitInicial:numTitulos,
                        existeCapitaSocial:true,
                        valorTitulolInicial:capital.VALOR_TITULO,
                        fechaCapitalInicial:capital.FECHA_VALIDEZ,
                        valorCapitalInicial:parseFloat(capital.VALOR_TITULO*numTitulos).toFixed(2),
                        distribuciones:sociosAgrupados,
                        distribucionesActuales:sociosAgrupadosActuales,
                        nTitulosFinal:parseInt(nTitulos)+parseInt(numTitulos),
                        valorFinal:parseFloat(parseFloat(valorTitulo).toFixed(2))+parseFloat(parseFloat(capital.VALOR_TITULO).toFixed(2)),
                        variaciones:variaciones,
                        transmisiones:transmisiones,
                        cargando:false

                    })

                })
                
            }
    

    }

    //cuando se haya montado el componente lo rellenamos con sus datos.
     componentDidMount(){
        this.setState({
            cargandoL:true
        }, async () => {
             await this.obtenerDistribuciones()
             this.setState({cargandoL:false})
        })
        

    }
    generarImprimirLibro= async () =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async() =>{
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirLibro(), document.getElementById('generarImprimirLibro-pdf'))
            })
        })
    }
    ImprimirLibro=()=>{
        let distribucion= this.cargarDist();
    return(
		
        <PDFViewer style={{width: '100%', height: '100%'}}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.body}>
                        <View fixed style={styles.row}>
                        <Image
                            style={styles.imageHead}
                            src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                            />
                        </View>
                        <View fixed style={styles.rowHead}>
                            <Text style={styles.labelHead}>EMPRESA:</Text>
                            <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                        </View>
                        <View fixed style={styles.rowHeadTwo}>
                            <Text style={styles.labelHead}>INFORME:</Text>
                            <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                        </View>
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>LIBRO DE SOCIOS</Text>
                        </View>							
							<View >
								<View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>CAPITAL SOCIAL INICIAL</Text>
                				</View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA DEL CAPITAL INICIAL:</Text>
                                    <Text style={styles.textData}>{moment(this.state.fechaCapitalInicial).format('DD-MM-YYYY')}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>VALOR DEL TÍTULO:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorTitulolInicial).toFixed(2)}€</Text>
                                </View>
								<View style={styles.row}>
                                    <Text style={styles.labelData}>NÚMERO DE TÍTULOS:</Text>
                                    <Text style={styles.textData}>{this.state.numTitInicial}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>VALOR DEL CAPITAL SOCIAL:</Text>
                                    <Text style={styles.textData}>{this.state.valorCapitalInicial}€</Text>
                                </View>
                                <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>DISTRIBUCIÓN POR SOCIOS</Text>
                				</View>
                                {this.state.distribuciones?this.state.distribuciones.map((distribucion,index) => {
                                   return(
                                       <View key={index}>                                           
                                                <View style={styles.row}>
                                                    <Text style={styles.labelData}>{distribucion.NOMBRE}:</Text>
                                                    <Text style={styles.textData}>{distribucion.NUMERO_TITULOS},{parseFloat(this.state.valorTitulolInicial*distribucion.NUMERO_TITULOS).toFixed(2)}€</Text>
                                                </View>
                                            
                                       </View>
                                   )

                                }):<></>}
                                 <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>VARIACIONES</Text>
                				</View>
                                {distribucion?distribucion.map((dist, index)=>{
                                    return(
                                        <View key={index} style={styles.row}>
                                            <Text style={styles.textData}>{dist}:</Text>
                                        </View>
                                    )
                                }):<></>}
                                 <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>CAPITAL SOCIAL ACTUAL</Text>
                				</View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>VALOR DEL TÍTULO:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorFinal).toFixed(2)} €</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>NÚMERO DE TÍTULOS:</Text>
                                    <Text style={styles.textData}>{this.state.nTitulosFinal}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>VALOR DEL CAPITAL:</Text>
                                    <Text style={styles.textData}>{(parseFloat(parseFloat(this.state.valorFinal).toFixed(2))*parseInt(this.state.nTitulosFinal)).toFixed(2)} €</Text>
                                </View>
                                <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>DISTRIBUCIÓN POR SOCIOS </Text>
                				</View>
                                {this.state.distribucionesActuales?this.state.distribucionesActuales.map((distribuciones, index)=>{
                                    return(
                                        <View key={index} style={styles.row}>
                                            <Text style={styles.labelData}>{distribuciones.NOMBRE}:</Text>
                                            <Text style={styles.textData}>{distribuciones.NUMERO_TITULOS}, {parseFloat(this.state.valorFinal*distribuciones.NUMERO_TITULOS).toFixed(2)} € </Text>
                                        </View>
                                    )
                                }):<></>}

                            </View>     
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
     )
    }     
     cargarDist=() =>{
        this.state.variaciones.forEach((variacion,index) => {    
                            console.log(variacion)          
                            if(variacion.ID_TIPO_VARIACION===2){
                                let anterior=this.valTitulo
                                this.valTitulo=parseFloat(this.valTitulo)+parseFloat(variacion.CAMBIO_VALOR_TITULO)
                                this.dist.push("-Con fecha "+moment(variacion.FECHA).format("DD-MM-YYYY")+ " el valor del título pasó de "+parseFloat(anterior).toFixed(2)+" a "+ parseFloat(this.valTitulo).toFixed(2)) 
                                
                            }else if(variacion.ID_TIPO_VARIACION===1){
                                for(let dis of variacion.distribucion){
                                    //aumentamos en el array la variacion.
                                    this.distribucionesActuales.find((soc,index)=>{
                                        if(soc.ID_PERSONA===dis.ID_PERSONA){
                                            this.distribucionesActuales[index].NUMERO_TITULOS+=dis.VARIACION
                                        }
                                        return soc.ID_PERSONA===dis.ID_PERSONA
                                    })
                                    this.dist.push(`Con fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} se ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} el número de títulos en un número de ${Math.abs(dis.VARIACION)}
                                    títulos según las siguiente distribución:
                                         ${dis.nombre} ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} en un total de ${Math.abs(dis.VARIACION)}`)
                                    
                                }
                                
                            }                   
                        })
                        return this.dist
     }

    obtenerNombreMiembros=async(persona)=>{
        let nombre=''
        if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===persona.ID_PERSONA){
                    nombre=`${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                }
            })
          
         //Si es persona juridica
         }else if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(miembro=>{
                 if(miembro.ID_PERSONA===persona.ID_PERSONA){
                     nombre= miembro.RAZON_SOCIAL
                 }
             })
 
         }
         persona.nombre=nombre
    }


    // libroSocios =()=> (
    //     <div className="html-pdf" ref={(input) => { this.pdfLibroSocios= input; }}>
    //         <div className="body-pdf">
    //             <br />
    //             <img
    //                 className="App-logo-pdf"
    //                 src={this.state.logotipo?this.state.logotipo:pantallaInicio}
    //                 alt="logotipo"
    //             />
    //             <br />
    //             <h4>LIBRO DE SOCIOS</h4>
    //             <h4>CAPITAL SOCIAL INICIAL</h4>
    //             <ul>
    //                 <li>Fecha del capital social:{moment(this.state.fechaCapitalInicial).format("DD-MM-YYYY")}</li>
    //                 <li>Valor del titulo:{parseFloat(this.state.valorTitulolInicial).toFixed(2)} €</li>
    //                 <li>Numero de titulos:{this.state.numTitInicial}</li>
    //                 <li>Valor del capital social:{this.state.valorCapitalInicial} €</li>
    //             </ul>
    //             <h4>DISTRIBUCIÓN POR SOCIOS:</h4>
                
    //             {this.state.distribuciones.map((distribucion,index) => {
    //                 return(
    //                     <p>-{distribucion.NOMBRE}: {distribucion.NUMERO_TITULOS}, {parseFloat(this.state.valorTitulolInicial*distribucion.NUMERO_TITULOS).toFixed(2)} € </p>
    //                 )
    //             })}
    //             <h4>VARIACIONES:</h4>
    //             {this.state.variaciones.forEach((variacion,index) => {    
    //                 console.log(variacion)          
    //                 if(variacion.ID_TIPO_VARIACION===2){
    //                     let anterior=this.valTitulo
    //                     this.valTitulo=parseFloat(this.valTitulo)+parseFloat(variacion.CAMBIO_VALOR_TITULO)
    //                     this.dist.push("-Con fecha "+moment(variacion.FECHA).format("DD-MM-YYYY")+ " el valor del título pasó de "+parseFloat(anterior).toFixed(2)+" a "+ parseFloat(this.valTitulo).toFixed(2)) 
                        
    //                 }else if(variacion.ID_TIPO_VARIACION===1){
    //                     for(let dis of variacion.distribucion){
    //                         //aumentamos en el array la variacion.
    //                         this.distribucionesActuales.find((soc,index)=>{
    //                             if(soc.ID_PERSONA===dis.ID_PERSONA){
    //                                 this.distribucionesActuales[index].NUMERO_TITULOS+=dis.VARIACION
    //                             }
    //                             return soc.ID_PERSONA===dis.ID_PERSONA
    //                         })
    //                         this.dist.push(`-Con fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} se ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} el número de títulos en un número de ${Math.abs(dis.VARIACION)}
    //                         títulos según las siguiente distribución:
    //                             - ${dis.nombre} ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} en un total de ${Math.abs(dis.VARIACION)}`)
                            
    //                     }
                        
    //                 }                   
    //             })}
    //                 {this.dist.map(dis=>{
    //                         return(
    //                             <p>{dis}</p>
    //                         )
    //                     })}
    //             <p>
    //             <h4>CAPITAL SOCIAL ACTUAL</h4>
    //             <ul>
    //                 <li>Valor del titulo:{parseFloat(this.state.valorFinal).toFixed(2)} €</li>
    //                 <li>Numero de titulos:{this.state.nTitulosFinal}</li>
    //                 <li>Valor del capital social:{(parseFloat(parseFloat(this.state.valorFinal).toFixed(2))*parseInt(this.state.nTitulosFinal)).toFixed(2)} €</li>
    //             </ul>
    //             <h4>DISTRIBUCIÓN POR SOCIOS:</h4>
    //             </p>
    //             {this.state.distribucionesActuales.map((distribucion,index) => {
    //                 return(
    //                     <p>-{distribucion.NOMBRE}: {distribucion.NUMERO_TITULOS}, {parseFloat(this.state.valorFinal*distribucion.NUMERO_TITULOS).toFixed(2)} € </p>
    //                 )
    //             })}
    //         </div>
    //         <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
    //     </div>
    // );

    libroHistorico =()=>(
        <div ref={(input) => { this.pdfLibroRegistro= input; }}>
            <p>Este libro lo teneis que diseñarlo tú y ángel primero.</p>
        </div>
    );



    render(){

        document.title ="LexControl | Libros"
        const cerrarModalImprimir=()=>{
            this.setState({modalImprimir:false})
        }

        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1, abierto2: false, abierto3: false });
        }

        const handleClick2 = () => {
            this.setState({ abierto2: !this.state.abierto2, abierto1: false, abierto3: false });
        }

        const handleClick3 = () => {
            this.setState({ abierto3: !this.state.abierto3, abierto2: false, abierto1: false });
        }

        this.valTitulo=this.state.valorTitulolInicial
        this.distribucionesActuales=this.state.distribucionesActuales
        this.dist=[]
    
        return (
            <>
                {this.state.cargandoL===true?
                    <Cargando/>
                :
                <>
                <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title="Imprimir libro de socios"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimir}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimir?
                                <div style={{ width: '100%', height: '600px'}} id='generarImprimirLibro-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                </Dialog>

           
              
                {/* <div style={{display:"none"}}>
                    <this.libroHistorico/>
                </div> */}
                
                <div className="tablaSesenta">
                    <div>
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <FormGroup
                                label="Para crear el libro de variaciones del capital, pulse aqui: "
                                labelFor="informacion"
                                inline={true}
                            >
                            {!this.state.cargando
                            ?
                             <Button className="bp3-minimal" icon="print" active={this.state.imprimirActivado} onClick={()=>this.generarImprimirLibro()}>Imprimir libro de variaciones del capital</Button>
                            :
                                <></>
                            }
                        
                            </FormGroup>
                        </Card>
                    </div>
                    <div>
                </div>
                </div>
                <div className="tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>                    
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">LEY 1/2010 DE SOCIEDADES DE CAPITAL</Callout>
                <br />
                        <div className="tablaTercio">
                            <Button icon="git-repo"onClick={handleClick1}>
                                Artículo 104.
                            </Button>
                        </div>
                        <div className="tablaTercio">
                            <Button icon="git-repo"  onClick={handleClick2}>
                                Artículo 105.
                            </Button>
                        </div>
                        <div className="tablaTercio">
                            <Button icon="git-repo" onClick={handleClick3}>
                                Artículo 116.
                            </Button>
                        </div>
                    <br />
                    <br />
                        <Collapse isOpen={this.state.abierto1}>
                            <ol>
                                <li>La sociedad limitada llevará un Libro registro de socios, en el que se harán constar la titularidad originaria y las sucesivas transmisiones, voluntarias o forzosas, de las participaciones sociales, así como la constitución de derechos reales y otros gravámenes sobre las mismas.</li>
                                <li>La sociedad sólo reputará socio a quien se halle inscrito en dicho libro. </li>
                                <li>En cada anotación se indicará la identidad y domicilio del titular de la participación o del derecho o gravamen constituido sobre aquélla. </li>
                                <li>La sociedad sólo podrá rectificar el contenido del Libro registro si los interesados no se hubieran opuesto a la rectificación en el plazo de un mes desde la notificación fehaciente del propósito de proceder a la misma. </li>
                            </ol>
                            <p>Los datos personales de los socios podrán modificarse a su instancia, no surtiendo entre tanto efectos frente a la sociedad. </p>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto2}>
                            <ol>
                                <li>Cualquier socio podrá examinar el Libro registro de socios, cuya llevanza y custodia corresponde al órgano de administración. </li>
                                <li>El socio y los titulares de derechos reales o de gravámenes sobre las participaciones sociales, tienen derecho a obtener certificación de las participaciones, derechos o gravámenes registrados a su nombre.</li>
                            </ol>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto3}>
                            <ol>
                                <li>Las acciones nominativas figurarán en un libro-registro que llevará la sociedad, en el que se inscribirán las sucesivas transferencias de las acciones, con expresión del nombre, apellidos, razón o denominación social, en su caso, nacionalidad y domicilio de los sucesivos titulares, así como la constitución de derechos reales y otros gravámenes sobre aquellas.</li>
                                <li>La sociedad solo reputará accionista a quien se halle inscrito en dicho libro. </li>
                                <li>Cualquier accionista que lo solicite podrá examinar el libro registro de acciones nominativas. </li>
                                <li>La sociedad solo podrá rectificar las inscripciones que repute falsas o inexactas cuando haya notificado a los interesados su intención de proceder en tal sentido y estos no hayan manifestado su oposición durante los treinta días siguientes a la notificación. </li>
                                <li>Mientras que no se hayan impreso y entregado los títulos de las acciones nominativas, el accionista tiene derecho a obtener certificación de las inscritas a su nombre. </li>
                            </ol>
                        </Collapse>
                    </Card>
                </div>
            </>
        }
    </>
        )
    }
}

export default withApollo(Libros)