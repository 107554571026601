import React from "react";
import { InputGroup, Checkbox, FormGroup, TextArea,Dialog, Classes, Tooltip,AnchorButton, Intent, Button } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import moment from 'moment';
import Select from "react-select"
let client = new ApolloClient({
  uri: process.env.REACT_APP_DOMINIO+"/api",
  credentials: 'include',
});


export class DatosPersonasFisicas extends React.Component {

  state={
    nombre:null,
    primerApellido:null,
    segundoApellido:null,
    idiomaSeleccionado:null,
    bolFechaNacimiento:false,
    fechaNacimiento:null,
    idiomasSelect:[],
    idPersonaCreada:null,
    idPersonaActualizada:null,
    gravamenes: null,
    domicilio: null,
    localidad: null,
    fechaAlta: null,
    fechaBaja: null,
    observaciones: null,
    paises:[],
    generos:[],
    paisSeleccionado:null,
    nif:null,
    nifValido:true,
    idNif:null,
    paisNuevo:null,
    generoSeleccionado:null,
    edad:null
  }

  cargarGeneros=async()=>{
    let generos=[]
    generos.push({"label":this.props.i18next.t("organosinstruccion.femenino",{ns:"page"}),"value":"Femenino"})
    generos.push({"label":this.props.i18next.t("organosinstruccion.masculino",{ns:"page"}),"value":"Masculino"})
    generos.push({"label":this.props.i18next.t("organosinstruccion.neutro",{ns:"page"}),"value":"Neutro"})

    this.setState({generos:generos})

  }

  cargarPaises=async()=>{

    await client
    .query({
        query: gql`
        {
            paises{ID_PAIS,DESCRIPCION}
        }
        `,fetchPolicy:'network-only'
    }).then(result=>{ 
        let paises=[]
        for(let pais of result.data.paises){
            paises.push({"label":pais.DESCRIPCION,"value":pais.ID_PAIS})
        }

        this.setState({paises:paises})
    })

  }

  
  crearPersona=async()=>{


    if(!this.state.nombre)return
    if(!this.state.primerApellido)return
    if(!this.state.nif)return
    //if(!this.validarDni(this.state.nif)){
    //  this.setState({nifValido:false})
    //  return
    //}else{
      this.setState({nifValido:true})

      let existe=await client
      .query({
          query: gql`
          {
              documentosIdentificativos(FILTROS:{VALOR:"${this.state.nif}"}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR}
          }
          `,
          fetchPolicy: 'network-only'

      }).then(result=>{

        if(result.data.documentosIdentificativos.length>0)return true

        return false
        
      })

      if(existe){
        alert(this.props.i18next.t("yaexiste",{ns:"global"}))
        return
      }

    //}

    //mutation para cerar la persona.
    let persona=await client.mutate({
        mutation:gql`mutation{crearPersona(VALORES:{ID_TIPO_PERSONA:1}){ID_PERSONA}}`
    }).then(result=>{
        return result.data.crearPersona
    }).catch(err=>{
      alert(this.props.i18next.t("error",{ns:"global"}))
    })

    //mutation apra crear la persona fisica.
    await client.mutate({
        mutation:gql`mutation{crearPersonaFisica(VALORES:{ID_PERSONA:${persona.ID_PERSONA},NOMBRE:"${this.state.nombre}",APELLIDO1:"${this.state.primerApellido}",APELLIDO2:${this.state.segundoApellido?'"'+this.state.segundoApellido+'"':null},FECHA_NACIMIENTO:${this.state.fechaNacimiento?'"'+moment(this.state.fechaNacimiento).format('YYYY-MM-DD')+'"':null},ID_LENGUA_MATERNA:${this.state.idiomaSeleccionado?this.state.idiomaSeleccionado.value:null},EDAD:${this.state.edad?'"'+this.state.edad+'"':null},GRAVAMENES:${this.state.gravamenes?'"'+this.state.gravamenes+'"':null},DOMICILIO:${this.state.domicilio?'"'+this.state.domicilio+'"':null},LOCALIDAD:${this.state.localidad?'"'+this.state.localidad+'"':null},FECHA_ALTA:${this.state.fechaAlta?'"'+moment(this.state.fechaAlta).format("YYYY-MM-DD")+'"':null},FECHA_BAJA:${this.state.fechaBaja?'"'+moment(this.state.fechaBaja).format("YYYY-MM-DD")+'"':null},GENERO:${this.state.generoSeleccionado?'"'+this.state.generoSeleccionado.value+'"':null},ID_NACIONALIDAD:${this.state.paisSeleccionado?this.state.paisSeleccionado.value:null},OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}}){ID_PERSONA}}`
    }).then(async result=>{

      client.mutate({
          mutation: gql`
              mutation{crearDocumentoIdentificativo(VALORES:{ID_PERSONA:${result.data.crearPersonaFisica.ID_PERSONA},ID_TIPO_DOCUMENTO:${1},VALOR:"${this.state.nif}",FECHA_EMISION:${null},FECHA_CADUCIDAD:${null}}){ID_DOCUMENTO_IDENTIFICATIVO}}
          `
      }).catch(err=>{
          alert(this.props.i18next.t("error",{ns:"global"}))
      })

      this.setState({idPersonaCreada:persona.ID_PERSONA}) 
    }).catch(err=>{
      alert(this.props.i18next.t("error",{ns:"global"}))
    })
    
  }

  validarDni=(value)=>{
  
    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var str = value.toString().toUpperCase();
  
    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;
  
    var nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');
  
    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;
  
    if (validChars.charAt(charIndex) === letter) return true;
  
    return false;
  }

  actualizarPersona=async ()=>{

    if(!this.state.nombre)return
    if(!this.state.primerApellido)return
    if(!this.state.nif)return
    //if(!this.validarDni(this.state.nif)){
    //  this.setState({nifValido:false})
    //  return
    //}else{
      this.setState({nifValido:true})

      let existe=await client
      .query({
          query: gql`
          {
              documentosIdentificativos(FILTROS:{VALOR:"${this.state.nif}"}){ID_PERSONA}
          }
          `,
          fetchPolicy: 'network-only'

      }).then(result=>{

        if(result.data.documentosIdentificativos.length>0)return result.data.documentosIdentificativos[0]

        return false
        
      })

      if(existe && existe.ID_PERSONA!==this.props.datosPersona){
        alert(this.props.i18next.t("yaexiste",{ns:"global"}))
        return
      }

    //}
    //mutation apra crear la persona fisica.
    await client.mutate({
        mutation:gql`mutation{actualizarPersonasFisicas(ID_PERSONA:${this.props.datosPersona},VALORES:{NOMBRE:"${this.state.nombre}",APELLIDO1:"${this.state.primerApellido}",APELLIDO2:${this.state.segundoApellido?'"'+this.state.segundoApellido+'"':null},FECHA_NACIMIENTO:${this.state.fechaNacimiento?'"'+moment(this.state.fechaNacimiento).format('YYYY-MM-DD')+'"':null},ID_LENGUA_MATERNA:${this.state.idiomaSeleccionado?this.state.idiomaSeleccionado.value:null},EDAD:${this.state.edad?'"'+this.state.edad+'"':null},GRAVAMENES:${this.state.gravamenes?'"'+this.state.gravamenes+'"':null},DOMICILIO:${this.state.domicilio?'"'+this.state.domicilio+'"':null},LOCALIDAD:${this.state.localidad?'"'+this.state.localidad+'"':null},FECHA_ALTA:${this.state.fechaAlta?'"'+moment(this.state.fechaAlta).format("YYYY-MM-DD")+'"':null},FECHA_BAJA:${this.state.fechaBaja?'"'+moment(this.state.fechaBaja).format("YYYY-MM-DD")+'"':null},GENERO:${this.state.generoSeleccionado?'"'+this.state.generoSeleccionado.value+'"':null},ID_NACIONALIDAD:${this.state.paisSeleccionado?this.state.paisSeleccionado.value:null},OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}}){ID_PERSONA}}`
    }).then(async result=>{

        if(this.state.idNif){

          client.mutate({
            mutation: gql`
                mutation{actualizarDocumentoIdentificativo(ID_DOCUMENTO_IDENTIFICATIVO:${this.state.idNif},VALORES:{VALOR:"${this.state.nif}"}){ID_DOCUMENTO_IDENTIFICATIVO}}
              `
          }).catch(err=>{
              alert(this.props.i18next.t("error",{ns:"global"}))
          })

        }else{

          client.mutate({
            mutation: gql`
                mutation{crearDocumentoIdentificativo(VALORES:{ID_PERSONA:${this.props.datosPersona},ID_TIPO_DOCUMENTO:${1},VALOR:"${this.state.nif}",FECHA_EMISION:${null},FECHA_CADUCIDAD:${null}}){ID_DOCUMENTO_IDENTIFICATIVO}}
            `
          }).catch(err=>{
              alert(this.props.i18next.t("error",{ns:"global"}))
          })

        }
    

        this.setState({idPersonaActualizada:this.props.datosPersona})
    }).catch(err=>{
      alert(this.props.i18next.t("error",{ns:"global"}))
    })
    
  }

  cargarPersonas=async()=>{

    client = new ApolloClient({
      uri: process.env.REACT_APP_DOMINIO+"/api",
      credentials: 'include',
    });

    //carga del desplegable de idiomas.
    await client
    .query({
        query: gql`
        {idiomas{ID_IDIOMA,DESCRIPCION}}
        `
    }).then(result=>{ 
        let idiomas=[]
        result.data.idiomas.forEach(idioma=>{
            idiomas.push({"value":idioma.ID_IDIOMA,"label":idioma.DESCRIPCION})
        })
        this.setState({idiomasSelect:idiomas})

    
    })
     //si recibimos props
     if(this.props.datosPersona && this.props.datosPersona!==null){
      //buscamos a la pesona pasada como parametro
      await client
      .query({
          query: gql`
          {personasFisicas(FILTROS:{ID_PERSONA:${this.props.datosPersona}}){NOMBRE,APELLIDO1,APELLIDO2,FECHA_NACIMIENTO,ID_LENGUA_MATERNA,EDAD,GENERO,GRAVAMENES,DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,ID_NACIONALIDAD,OBSERVACIONES}}
          `
      }).then(result=>{ 
        let persona=result.data.personasFisicas[0]
        this.setState({
          nombre:persona.NOMBRE,
          primerApellido:persona.APELLIDO1,
          segundoApellido:persona.APELLIDO2?persona.APELLIDO2:null,
          fechaNacimiento:persona.FECHA_NACIMIENTO?new Date(persona.FECHA_NACIMIENTO):null,
          bolFechaNacimiento:persona.FECHA_NACIMIENTO?true:false,
          gravamenes: persona.GRAVAMENES,
          edad: persona.EDAD,
          domicilio: persona.DOMICILIO,
          localidad: persona.LOCALIDAD,
          fechaAlta: persona.FECHA_ALTA?new Date(persona.FECHA_ALTA):null,
          fechaBaja: persona.FECHA_BAJA?new Date(persona.FECHA_BAJA):null,
          observaciones: persona.OBSERVACIONES
        })

        if(persona.ID_LENGUA_MATERNA){
          let idiomaSeleccionado=this.state.idiomasSelect.find(idioma=>{return idioma.value===persona.ID_LENGUA_MATERNA})
          this.setState({idiomaSeleccionado:idiomaSeleccionado})
        }

        if(persona.ID_NACIONALIDAD){
          let paisSeleccionado=this.state.paises.find(pais=>{return pais.value===persona.ID_NACIONALIDAD})
          this.setState({paisSeleccionado:paisSeleccionado})
        }
        if(persona.GENERO){
          let generoSeleccionado=this.state.generos.find(genero=>{return genero.value===persona.GENERO})
          this.setState({generoSeleccionado:generoSeleccionado})
        }

        client
            .query({
                query: gql`
                {
                    documentosIdentificativos(FILTROS:{ID_PERSONA:${this.props.datosPersona}}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR}
                }
                `,
                fetchPolicy: 'network-only'
    
            }).then(result=>{

                if(result.data.documentosIdentificativos.length>0){
                  this.setState({nif:result.data.documentosIdentificativos[0].VALOR,idNif:result.data.documentosIdentificativos[0].ID_DOCUMENTO_IDENTIFICATIVO})
                }
            })
      })
    }

  }

  componentDidMount(props){
    this.cargarPersonas()
    this.cargarPaises()
    this.cargarGeneros()
  }
  
  render() {

    const handleCambiarNombre = ev => {
      this.setState({ nombre: ev.currentTarget.value });
    };

    const handleCambiarPrimerApellido = ev => {
      this.setState({ primerApellido: ev.currentTarget.value });
    };

    const handleCambiarSegundoApellido = ev => {
      this.setState({ segundoApellido: ev.currentTarget.value });
    };

    const cambiarNif = (ev) => {
      this.setState({nif: ev.currentTarget.value})
    }

    const handleCambiarGravamenes = ev => {
      this.setState({ gravamenes: ev.currentTarget.value });
    };

    const handleCambiarEdad = ev => {
      this.setState({ edad: ev.currentTarget.value });
    };
    
    
    const handleCambiarDomicilio = ev => {
      this.setState({ domicilio: ev.currentTarget.value });
    };

    const handleCambiarLocalidad = ev => {
      this.setState({ localidad: ev.currentTarget.value });
    };

    const cambiarTextoObservaciones = ev => {
      this.setState({ observaciones: ev.currentTarget.value });
    };

    const handleEnabledChangeBolFechaNacimiento = () => {
      let nuevoValor = !this.state.bolFechaNacimiento;
      this.setState({ bolFechaNacimiento: nuevoValor });
      //si hacemos click en el boton y no hay fecha seleccionada, cogeremos le facha por defecto.
      if (nuevoValor) {
        let birthday=new Date()
        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs);
        let nuevaEdad=Math.abs(ageDate.getUTCFullYear() - 1970) 
        this.setState({ fechaNacimiento: new Date(),edad:nuevaEdad });
      } else {
        this.setState({ fechaNacimiento: null });
      }
    };

    const escogeFecha = nuevaFecha => {
      let birthday=new Date(moment(nuevaFecha).format('YYYY-MM-DD'))
      let ageDifMs = Date.now() - birthday.getTime();
      let ageDate = new Date(ageDifMs);
      let nuevaEdad=Math.abs(ageDate.getUTCFullYear() - 1970) 
      this.setState({ fechaNacimiento: nuevaFecha,edad:nuevaEdad });
    };

    const escogeFechaAlta = nuevaFecha => {
      this.setState({ fechaAlta: nuevaFecha });
    };

    const escogeFechaBaja = nuevaFecha => {
      this.setState({ fechaBaja: nuevaFecha });
    };

    const seleccionarPais = pais => {
      this.setState({ paisSeleccionado: pais });
    };

    const seleccionarGenero = genero => {
      this.setState({ generoSeleccionado: genero });
    };

    const jsDateFormatter = {
      // note that the native implementation of Date functions differs between browsers
      formatDate: date => moment(date).format("DD/MM/YYYY"),
      parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
      placeholder: "DD/MM/YYYY"
    };

    const anadirPais=()=>{

      //si no se escribe nada.
      if(!this.state.paisNuevo)return

      let existe=this.state.paises.find(pais=>{return pais.label===this.state.paisNuevo})
      if(existe){
          alert(this.props.i18next.t("yaexiste",{ns:"global"}))
          return
      }

      //insertamos el pais.
      client.mutate({
      mutation: gql`
        mutation{crearPais(VALORES:{DESCRIPCION:"${this.state.paisNuevo}"}){ID_PAIS}}
      `
      }).then(async result=>{

          await this.cargarPaises()

          let paisSel=this.state.paises.find(pais=>{
              return pais.value===result.data.crearPais.ID_PAIS
          })
          
          this.setState({paisSeleccionado:paisSel,modalPais:false})
         

      }).catch(err=>{
          alert(this.props.i18next.t("error",{ns:"global"}))
      })
    }

  
    const handleCambiarNuevoPais=(ev)=>{
        this.setState({paisNuevo:ev.currentTarget.value})
    }

    return (
      <>
      <Dialog 
        transitionDuration={400}
        autoFocus={true}
        enforceFocus={true}
        icon="info-sign"
        onClose={()=>{this.setState({modalPais:false})}}
        title={this.props.i18next.t("organosinstruccion.crearpais",{ns:"page"})}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        isOpen={this.state.modalPais}
        usePortal={true}
        className="zIndexAlto"
    >
        <div className={Classes.DIALOG_BODY}>
            <FormGroup
            label={this.props.i18next.t("organosinstruccion.enterpais",{ns:"page"})}
            labelFor="informacion"
            >
                <InputGroup id="texto-nombre-grupo" onChange={handleCambiarNuevoPais}  intent="primary"/>
            </FormGroup>

        </div>
        <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                <AnchorButton
                    intent={Intent.PRIMARY}
                    onClick={anadirPais}
                >
                    {this.props.i18next.t("aceptar",{ns:"global"})}
                </AnchorButton>
            </Tooltip>
            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                <Button onClick={()=>{this.setState({modalPais:false})}}>{this.props.i18next.t("cancelar",{ns:"global"})}</Button>
            </Tooltip>
        </div>
        </div>
    </Dialog>
      <div className="pixelAlrededor bp3-card separarBordes">
        <FormGroup
          label={this.props.i18next.t("identificacion.name",{ns:"page"})}
          labelFor="nombre"
          intent="danger"
          helperText={this.state.nombre ? "" : this.props.i18next.t("organosinstruccion.emptyname",{ns:"page"})}
        >
          <InputGroup
            id="nombre"
            defaultValue={this.state.nombre ? this.state.nombre : ""}
            onChange={handleCambiarNombre}
            intent={this.state.nombre ? "primary" : "danger"}
          />
        </FormGroup>
        <FormGroup
          label={this.props.i18next.t("organosinstruccion.primerapellido",{ns:"page"})}
          labelFor="primer-apellido"
          intent="danger"
          helperText={
            this.state.primerApellido ? "" : this.props.i18next.t("organosinstruccion.emptyprimerapellido",{ns:"page"})
          }
        >
          <InputGroup
            onChange={handleCambiarPrimerApellido}
            defaultValue={
              this.state.primerApellido ? this.state.primerApellido : ""
            }
            id="primer-apellido"
            intent={this.state.primerApellido ? "primary" : "danger"}
          />
        </FormGroup>
        <FormGroup label={this.props.i18next.t("organosinstruccion.segundoapellido",{ns:"page"})} labelFor="segundo-apellido">
          <InputGroup
            id="segundo-apellido"
            defaultValue={
              this.state.segundoApellido ? this.state.segundoApellido : ""
            }
            onChange={handleCambiarSegundoApellido}
          />
        </FormGroup>
        <FormGroup
          label={this.props.i18next.t("organosinstruccion.nif",{ns:"page"})}
          labelFor="nif"
          helperText={this.state.nif?(!this.state.nifValido?this.props.i18next.t("organosinstruccion.validnif",{ns:"page"}):null):this.props.i18next.t("organosinstruccion.emptynif",{ns:"page"})}
          intent={this.state.nifValido && this.state.nif?"":"danger"}
        >  
          <InputGroup 
            id="nif" 
            intent={this.state.nifValido && this.state.nif?"primary":"danger"}
            value={this.state.nif?this.state.nif:''}
            onChange={cambiarNif}
          />
        </FormGroup>
         
        <FormGroup
            label={this.props.i18next.t("organosinstruccion.fechanacimiento",{ns:"page"})}
            labelFor="fecha-nacimiento"
            intent="danger"
        >
          <Checkbox
            checked={this.state.bolFechaNacimiento}
            onChange={handleEnabledChangeBolFechaNacimiento}
          />
          <DateInput minDate={new Date(1900, 1 ,1)}
            {...jsDateFormatter}
            onChange={escogeFecha}
            value={this.state.fechaNacimiento}
            disabled={!this.state.bolFechaNacimiento}
          />
        </FormGroup>
        
        <FormGroup label={this.props.i18next.t("organosinstruccion.edad",{ns:"page"})} labelFor="edad">
          <InputGroup
            id="edad"
            defaultValue={
              this.state.edad
            }
            onChange={handleCambiarEdad}
          />
        </FormGroup>
        <FormGroup
            label={this.props.i18next.t("organosinstruccion.genero",{ns:"page"})}
            labelFor="genero"
        >
          <Select
              options={this.state.generos}
              className="pixelAlrededor"
              onChange={seleccionarGenero}
              value={this.state.generoSeleccionado}
          />
      </FormGroup>
        <FormGroup label={this.props.i18next.t("organosinstruccion.gravamenes",{ns:"page"})} labelFor="gravamenes">
          <InputGroup
            id="gravamenes"
            defaultValue={
              this.state.gravamenes
            }
            onChange={handleCambiarGravamenes}
          />
        </FormGroup>
        <FormGroup label={this.props.i18next.t("organosinstruccion.domicilio",{ns:"page"})} labelFor="domicilio">
          <InputGroup
            id="domicilio"
            defaultValue={
              this.state.domicilio
            }
            onChange={handleCambiarDomicilio}
          />
        </FormGroup>
        <FormGroup label={this.props.i18next.t("organosinstruccion.localidad",{ns:"page"})} labelFor="localidad">
          <InputGroup
            id="localidad"
            defaultValue={
              this.state.localidad
            }
            onChange={handleCambiarLocalidad}
          />
        </FormGroup>
        <FormGroup
            label={this.props.i18next.t("organosinstruccion.fechaalta",{ns:"page"})}
            labelFor="fecha-alta"
            intent="danger"
        >
          <DateInput minDate={new Date(1900, 1 ,1)}
            {...jsDateFormatter}
            onChange={escogeFechaAlta}
            value={this.state.fechaAlta}
          />
        </FormGroup>
        <FormGroup
            label={this.props.i18next.t("organosinstruccion.fechabaja",{ns:"page"})}
            labelFor="fecha-baja"
            intent="danger"
        >
          <DateInput minDate={new Date(1900, 1 ,1)}
            {...jsDateFormatter}
            onChange={escogeFechaBaja}
            value={this.state.fechaBaja}
          />
        </FormGroup>
        <FormGroup
            label={this.props.i18next.t("organosinstruccion.nacionalidad",{ns:"page"})}
            labelFor="nacionalidad"
        >
          <Select
              options={this.state.paises}
              className="pixelAlrededor"
              onChange={seleccionarPais}
              value={this.state.paisSeleccionado}
          />
          <Button 
            alignText="right"
            icon="add"
            className="iconoIzquierda"
            onClick={()=>{this.setState({modalPais:true})}}
          />
      </FormGroup>
      <FormGroup
          label={this.props.i18next.t("organosinstruccion.observaciones",{ns:"page"})}
          labelFor="observaciones"
      >
        <TextArea 
            fill={true}
            onChange={cambiarTextoObservaciones}
            value={this.state.observaciones?this.state.observaciones:''}
        />
      </FormGroup>
      </div>
      </>
    );
  }
}