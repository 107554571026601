import React, { Component } from 'react';
import { Card, FormGroup, Navbar, ButtonGroup, Alignment, Button, NavbarDivider, Dialog, Classes, Elevation, Callout, Tooltip, AnchorButton, Intent, TextArea, RadioGroup, Radio, Spinner } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas"
import { withApollo } from 'react-apollo';
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';
import { withTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';

class ConsejerosDelegados extends Component {

    state={

        nuevoActivado: false,
        editarActivado: false,
        borrarActivado: false,
        consejeros: [
        ],
        personas:[],
        abierto: false,
        value: 1,
        contenido:null,
        limites:null,
        personaSeleccionada:null,
        consejeroSeleccionado:null,
        enabled: true,
        cargando:false,
        cargandoForm:false,
        datosEmpresa:null,
        cargandoImprimirCD:false,
        modalImprimirCD: false,
        cargandoImprimirCDG:false,
        modalImprimirCDG: false,
        logotipo:null,
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    obtenerNombreConsejeros=async(consejero)=>{
        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${consejero.ID_CONSEJERO_DELEGADO}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only',
        }).then(async(result)=>{
            consejero.nombre=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
        })
    }

    cargarConsejeros=async ()=>{
        

        let consejeros=await this.props.client.query({
            query:gql`{consejerosDelegados{ID_CONSEJERO_DELEGADO,ID_MODALIDAD_DELEGACION,LIMITES,CONTENIDO}}`,
            fetchPolicy:'network-only',
        }).then(async(result)=>{
           return JSON.parse(JSON.stringify(result.data.consejerosDelegados))
        })

        //si no hay ningun consejero
        if(!consejeros){
            this.setState({cargando:false})
            return
        }

        //console.log(result)
        let index=0

        for(let persona of consejeros){

            //await this.obtenerNombreConsejeros(persona)
            let nombre=''
            // eslint-disable-next-line array-callback-return
            this.state.personas.find((pers)=>{
                if (pers.value===persona.ID_CONSEJERO_DELEGADO) {
                    nombre=pers.label;
                }
              
            })
           consejeros[index].nombre=nombre
            consejeros[index].id=persona.ID_CONSEJERO_DELEGADO
            consejeros[index].limites=persona.LIMITES
            consejeros[index].contenido=persona.CONTENIDO
            consejeros[index].idModalidad=persona.ID_MODALIDAD_DELEGACION
            index++
        }
        this.setState({
            consejeros:consejeros,
            cargando:false
        })

    }

    cargarPersonas=async()=>{

        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only',
        }).then(async(result)=>{
            //console.log(result)
            let personas=[]
            result.data.personasFisicas.forEach(async(persona)=>{
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 
            })
            this.setState({personas:personas})
        })


    }
    
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.cargarPersonas()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarConsejeros()
            this.setState({cargandoForm:false})
        });
        
    }

    generarImprimirCD= async() => {
        this.setState({
            cargandoImprimirCD:true,
            modalImprimirCD:true
        }, async () => {
             this.setState({cargandoImprimirCD:false}, () => {
                 ReactDOM.render(this.ImprimirCD(), document.getElementById('generarCD-pdf'))
             })
        })
    }

    generarImprimirCDG= async() => {
        this.setState({
            cargandoImprimirCDG:true,
            modalImprimirCDG:true,
            cargando:true,
            consejeroSeleccionado:null
        }, async () => {
             this.setState({cargandoImprimirCDG:false}, () => {
                 ReactDOM.render(this.ImprimirCDG(), document.getElementById('generarCDG-pdf'))
             })
        })
    }

    ImprimirCD=()=>{
        let apoderadoSel=this.state.personas.find(persona=>{return persona.value===this.state.consejeroSeleccionado.id})
                
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("consejeros_delegados.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("consejeros_delegados.consejero",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.consejeroSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("name",{ns:"global"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{apoderadoSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("consejeros_delegados.contenido",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.consejeroSeleccionado.contenido}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("consejeros_delegados.limites",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.consejeroSeleccionado.limites}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("consejeros_delegados.modalidad",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{this.state.consejeroSeleccionado.idModalidad===1?i18next.t("consejeros_delegados.permanente",{ns:"page"}):i18next.t("consejeros_delegados.contrato",{ns:"page"})}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirCDG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("consejeros_delegados.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("list",{ns:"global"}).toUpperCase() + i18next.t("consejeros_delegados.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.consejeros?this.state.consejeros.map((consejero, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{consejero.nombre?consejero.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    render() {

            document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("consejeros_delegados.title",{ns:"page"});
            
            const abrirModalNuevo = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso",{ns:"global"}))
                    return
                }

                this.setState({
                    cargando: true,
                    modalAbierto: true,
                    nuevoActivado:true,
                    editarActivado:false,
                    borrarActivado:false,
                    value:1,
                    limites:null,
                    contenido:null,
                    personaSeleccionada:null,
                    consejeroSeleccionado:null
                })
            }

            const abrirModalEditar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso",{ns:"global"}))
                    return
                }

                if(!this.state.consejeroSeleccionado){
                    alert(i18next.t("selectempty",{ns:"global"})+i18next.t("consejeros_delegados.consejero",{ns:"page"}))
                    return
                }

                
                let apoderadoSel=this.state.personas.find(persona=>{return persona.value===this.state.consejeroSeleccionado.id})
                this.setState({
                    cargando: true,
                    personaSeleccionada:apoderadoSel,
                    nuevoActivado:false,
                    editarActivado:true,
                    borrarActivado:false,
                    value:this.state.consejeroSeleccionado.idModalidad,
                    limites:this.state.consejeroSeleccionado.limites,
                    contenido:this.state.consejeroSeleccionado.contenido,
                    modalAbierto: true,
                })
            }

            const abrirModalBorrar = () => {

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso",{ns:"global"}))
                    return
                }

                if(!this.state.consejeroSeleccionado){
                    alert(i18next.t("selectempty",{ns:"global"})+i18next.t("consejeros_delegados.apoderado",{ns:"page"}))
                    return
                }
                this.setState({modalBorrarAbierto: true})
            }

            const seleccionPersona  = (value) => { 
                this.setState({personaSeleccionada: value})
            }

            const handleRadioChange = (event) => {
                console.log(event.currentTarget.value)
                this.setState({value: parseInt(event.currentTarget.value)})
            }

            const cerrarModal = () => {
                this.setState({
                    modalAbierto: false,
                    modalBorrarAbierto: false,
                    modalPersonaAbierto: false,
                    consejeroSeleccionado:null
                },async ()=>{
                    await this.cargarConsejeros()
                })
            }

            const anadirNuevo = (value) => {
                
                if(!this.state.personaSeleccionada){
                    return
                }

                //comporbacion de que existe en la tabla.
                let existe=this.state.consejeros.find(consejeros=>{return consejeros.id===this.state.personaSeleccionada.value})
                if(existe){
                    alert(i18next.t("yaexiste",{ns:"global"}))
                    return
                }

                this.setState({pendienteGuardar:true})
                this.setState({controlesDesactivados:true})
                
                cerrarModal()
            }

            const actualizarApoderado = () => {

                if(!this.state.personaSeleccionada && !this.state.consejeroSeleccionado){
                    return
                }
    
    
                let consejeros=this.state.consejeros
                this.state.consejeros.find((apoderado,index) =>{
                    if(apoderado.id===this.state.consejeroSeleccionado.id){
                        consejeros[index]["nombre"]=this.state.personaSeleccionada?this.state.personaSeleccionada.label:this.state.consejeroSeleccionado.nombre
                    }
                    return apoderado.id===this.state.consejeroSeleccionado.id
                })
                this.setState({pendienteActualizar:true})
                this.setState({controlesDesactivados:true})
                this.setState({consejeros:consejeros})
                
        
                cerrarModal()
            }    

            const eliminarApoderado = () => {
                let consejeros=this.state.consejeros.filter(apoderado => apoderado.id !== this.state.consejeroSeleccionado.id)
                this.setState({consejeros:consejeros})
                this.setState({pendienteBorrar:true})     
                this.setState({controlesDesactivados:true,consejeroSeleccionado:null})
                cerrarModal()
            };

            const guardarCambios=()=>{

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso",{ns:"global"}))
                    return
                }

                if(this.state.pendienteGuardar){
                
                    this.props.client.mutate({
                        mutation:gql`mutation{crearConsejeroDelegado(VALORES:{ID_CONSEJERO_DELEGADO:${this.state.personaSeleccionada.value},ID_MODALIDAD_DELEGACION:${this.state.value},CONTENIDO:${this.state.contenido?'"""'+this.state.contenido+'"""':null},LIMITES:${this.state.limites?'"""'+this.state.limites+'"""':null}}){ID_CONSEJERO_DELEGADO}}`
                    }).then(async result=>{
                      
                        alert(i18next.t("insertcorrecto",{ns:"global"}))
                        await this.cargarConsejeros()

                        this.setState({
                            pendienteGuardar:false,
                            controlesDesactivados:false
                        })
                        
                    }).catch(err=>{
                        alert(i18next.t("error",{ns:"global"}))
                    })
    
                }else if(this.state.pendienteActualizar){
    
                    this.props.client.mutate({
                        mutation:gql`mutation{actualizarConsejeroDelegado(ID_CONSEJERO_DELEGADO:${this.state.consejeroSeleccionado.id},VALORES:{CONTENIDO:${this.state.contenido?'"""'+this.state.contenido+'"""':null},LIMITES:${this.state.limites?'"""'+this.state.limites+'"""':null},ID_MODALIDAD_DELEGACION:${this.state.value}}){ID_CONSEJERO_DELEGADO}}`
                    }).then(async result=>{

                        alert(i18next.t("updatecorrecto",{ns:"global"}))
                        await this.cargarConsejeros()

                        this.setState({
                            pendienteActualizar:false,
                            controlesDesactivados:false
                        })

                    }).catch(err=>{
                        console.log(err)
                        alert(i18next.t("error",{ns:"global"}))
                    })
    
    
    
                }else if(this.state.pendienteBorrar){
    
                    this.props.client.mutate({
                        mutation:gql`mutation{eliminarConsejeroDelegado(ID_CONSEJERO_DELEGADO:${this.state.consejeroSeleccionado.id}){ID_CONSEJERO_DELEGADO}}`
                    }).then(result=>{
                    
                        alert(i18next.t("deletecorrecto",{ns:"global"}))
                        this.cargarConsejeros()
                        this.setState({
                            pendienteBorrar:false,
                            controlesDesactivados:false,
                            consejeroSeleccionado:null
                        })
                        
                    }).catch(err=>{
                        alert(i18next.t("error",{ns:"global"}))
                    })
                }             
            }

            const cancelarCambios=()=>{

                //si no tiene permisos para editar la pantalla
                if(this.props.editable===false){
                    alert(i18next.t("sinpermiso",{ns:"global"}))
                    return
                }

                this.setState({
                    personaSeleccionada:null,
                    consejeroSeleccionado:null,
                    pendienteGuardar:false,
                    pendienteActualizar:false,
                    pendienteBorrar:false,
                    controlesDesactivados:false
                })
                this.cargarConsejeros()
            }

            const cambiarlimites = (ev) => { 
                this.setState({limites: ev.currentTarget.value})
            }

            const cambiarcontenido = (ev) => { 
                this.setState({contenido: ev.currentTarget.value})
            }

            const crearPersona=async()=>{

                await this.datosPersona.crearPersona()
                if(this.datosPersona.state.idPersonaCreada){
                    await this.cargarPersonas()
                    //seleccionar empleado creado

                    let personaSel=await this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }
    
            const actualizarPersona=async ()=>{
    
                await this.datosPersona.actualizarPersona()
                if(this.datosPersona.state.idPersonaActualizada){
                    await this.cargarPersonas()
                    await this.cargarConsejeros()

                    //seleccionar empleado creado
                    let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                    this.setState({personaSeleccionada:personaSel})
                    //cerrar el modal
                    cerrarModal2()
                }
    
            }
    
            const abrirModalNuevaPersona=()=>{
    
                //abrir modal de persona física
                this.setState({
                    modalPersonaAbierto:true,
                    popoverAbierto:false,
                    nuevaPresonaActivado:true,
                    editarPersonaActivado:false,
                    personaSeleccionada:null
                })
                
            }
    
            const cerrarModal2 = () => {
                this.setState({
                    modalPersonaAbierto: false,
                })
            }

            const cerrarModalImprimir= () => {
                this.setState({
                    modalImprimirCD:false,
                    modalImprimirCDG:false,
                    cargando:false
                })
            }

            const handleOnSelect=(row, isSelect) => {
                if (isSelect) {
                    this.setState({
                      consejeroSeleccionado: row
                    });
                }
                //console.log(row);
                return true; // return true or dont return to approve current select action
            }

            const columns = [
                {
                    dataField: 'nombre',
                    text: i18next.t("name",{ns:"global"})
                }
            ];

            
        const abrirModalEditarPersona=()=>{

            if(!this.state.personaSeleccionada){
                alert(i18next.t("selectempty",{ns:"global"}))
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({editarPersonaActivado:true})
            //si es personas física
            this.setState({modalPersonaAbierto:true})
        }

            return (
                <>
                {this.state.cargandoForm===true?
                    <Cargando/>
                :
                <>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("print",{ns:"global"})+ " " +i18next.t("consejeros_delegados.title",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirCDG}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirCDG?
                                <div style={{ width: '100%', height: '500px'}} id='generarCDG-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={i18next.t("print",{ns:"global"})+ " " +i18next.t("consejeros_delegados.title",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirCD}
                        style={styles.dialogL}
                    >
                        {!this.state.cargandoImprimirCD?
                                <div style={{ width: '100%', height: '500px'}} id='generarCD-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.state.nuevoActivado?i18next.t("consejeros_delegados.newconsejero",{ns:"page"}):i18next.t("consejeros_delegados.upconsejero",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalAbierto}
                    >
                        <div className="pixelAlrededor bp3-card separarBordes">
                            <FormGroup
                                label={i18next.t("consejeros_delegados.persona",{ns:"page"})+":"}
                                labelFor="persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?i18next.t("selectEmpty",{ns:"global"})+i18next.t("consejeros_delegados.apoderado",{ns:"page"}):""}
                            >
                                <div style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                    <Select
                                            isDisabled={this.state.editarActivado}
                                            options={this.state.personas}
                                            className="pixelAlrededor"
                                            onChange={seleccionPersona}
                                            value={this.state.personaSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevaPresonaActivado} onClick={abrirModalNuevaPersona} />
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                            </FormGroup>
                            <FormGroup
                            label={i18next.t("consejeros_delegados.contenido",{ns:"page"})+":"}
                            labelFor="contenido"
                        >
                            <TextArea id="contenido"  value={this.state.contenido?this.state.contenido:''} onChange={cambiarcontenido} fill={true} />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("consejeros_delegados.limites",{ns:"page"})+":"}
                            labelFor="limites"
                        >
                            <TextArea id="limites"  value={this.state.limites?this.state.limites:''} onChange={cambiarlimites}  fill={true} />
                        </FormGroup>
                        <RadioGroup
                            label={i18next.t("consejeros_delegados.modalidad",{ns:"page"})+":"}
                            name="group"
                            onChange={handleRadioChange}
                            selectedValue={this.state.value}
                        >
                            <Radio label={i18next.t("consejeros_delegados.permanente",{ns:"page"})} value={1} />
                            <Radio label={i18next.t("consejeros_delegados.contrato",{ns:"page"})} value={2} />
                        </RadioGroup>
                
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?anadirNuevo:actualizarApoderado}
                                >
                                {i18next.t("guardar",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                    </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("consejeros_delegados.questiondelapoderado",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarApoderado}
                                >
                                    {i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("consejeros_delegados.proppersona",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas i18next={i18next} datosPersona={this.state.personaSeleccionada?this.state.personaSeleccionada.value:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaPresonaActivado?crearPersona:actualizarPersona} 
                            >
                            {i18next.t("guardar",{ns:"global"})} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                {this.state.cargando===true?
                    <Cargando/>
                :
                <>
                    <div className="pixelAlrededor tablaSesenta">
                    <Card >
                        <h4>{i18next.t("consejeros_delegados.title",{ns:"page"})}</h4>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    {this.state.controlesDesactivados?[
                                        <>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled/>
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled/>
                                            <Button className="bp3-minimal" icon="print"  text={i18next.t("print",{ns:"global"})} disabled/>
                                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} disabled/>
                                        </>
                                    ]:[
                                        <>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                            <NavbarDivider />
                                            <Tooltip 
                                                content={i18next.t("print",{ns:"global"})} 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    disabled={this.state.consejeroSeleccionado?false:true}  
                                                    text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirCD()}
                                                />
                                            </Tooltip>  
                                            <Tooltip 
                                                content={i18next.t("printgral",{ns:"global"})} 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    text={i18next.t("printgral",{ns:"global"})} 
                                                    onClick={()=>this.generarImprimirCDG()}
                                                />
                                            </Tooltip>
                                            
                                        </>
                                    ]}
                                   
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                            keyField='id'
                            data={ this.state.consejeros }
                            columns={ columns }
                            selectRow={ const_table.selectRow(handleOnSelect) }
                            pagination={ paginationFactory(const_table.options) }
                            headerClasses="header-class"
                            rowClasses="row-class"
                            hover
                            condensed
                            noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        <br />
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text={i18next.t("guardar",{ns:"global"})}
                            intent="danger"
                            disabled={!this.state.controlesDesactivados}
                            onClick={guardarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            text={i18next.t("cancelar",{ns:"global"})}
                            disabled={!this.state.controlesDesactivados}
                            onClick={cancelarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <br />
                        <br />
                    </Card>
                    </div>
                    <div className="pixelAlrededor tablaCuarenta">
                        <Card interactive={true} elevation={Elevation.TWO}>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("consejeros_delegados.articulo",{ns:"page"})}</Callout>
                            <br />
                            <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&tn=1&p=20181229#a249", "_blank")}>{i18next.t("consejeros_delegados.articulo",{ns:"page"})}</AnchorButton>
                            <br />
                        </Card>
                    </div>
                    </>
                }
                </>
                }
                </>
            )   
    } 

}
export default withTranslation()(withApollo(ConsejerosDelegados))




