import gql from "graphql-tag";
import ApolloClient from "apollo-boost";

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: "include"
});

async function  obtenerNombreEmpresa() {
    try {
        let result=await client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
            }
            `,
            fetchPolicy: 'network-only'
    
        });

        if(result.data.personasJuridicas.length>0){
            return result.data.personasJuridicas[0].RAZON_SOCIAL;
        } else {
            return '';
        }
    } catch (error) {
        return '';
    }
	
}

async function  obtenerLogoEmpresa() {
    try {
        let result=await client
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        })

        if(result.data.adjuntos.length>0){

            var request = require('request').defaults({ encoding: null,withCredentials:true });

            request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                if (!error && response.statusCode === 200) {
                let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                return data;
                }
            });


        }

        return null;
    } catch (error) {
        return null;
    }
 }

export default {obtenerNombreEmpresa,obtenerLogoEmpresa}

