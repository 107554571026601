import React, {Component} from 'react';
import { withApollo } from 'react-apollo';
import { Navbar,
    ButtonGroup,
    Alignment,
    Button,
    Dialog,
    Classes,
    Tooltip,
    AnchorButton,
    Intent,
    FormGroup,
    Popover,
    Switch
} from "@blueprintjs/core";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from "../BootstrapTable";
import Select from "react-select";
import { Cargando } from '../Cargando';
import { DatosPersonasFisicas } from "./DatosPersonasFisicas";
import InformacionGeneralNuevo from  "./InformacionGeneralNuevo"

class AsistentesActa extends Component {
    state={
        asistenteSeleccionado: {},
        nuevoActivado: false,
        modalAbierto: false,
        modalPersonaAbierto:false,
        modalNuevaJuridicaAbierto:false,
        personaSeleccionada:null,
        personaSeleccionadaRepresentada:null,
        vota: false,
        modalBorrarAsistente:false,
        cargando:false
    }

    render() {
        function boolFormatter(cell, row) {
            return (
                (cell)?'SI':'NO'
            );
        }

        const handleDerechoVoto = () => {
            this.setState ({ vota: !this.state.vota})
        }

        const abrirModalNuevo = () =>{
            this.setState({ 
                nuevoActivado:true,
                modalAbierto: true,
                personaSeleccionada:null,
                personaSeleccionadaRepresentada:null,
                vota:false 
            })
        }

        const abrirModalEditar = () =>{
            if(!this.state.asistenteSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            let personaSel=this.props.personas.find(persona=>{return persona.value===this.state.asistenteSeleccionado.value})
            let personaSelRepresentada=this.state.asistenteSeleccionado.idRepresenta?this.props.personas.find(persona=>{return persona.value===this.state.asistenteSeleccionado.idRepresenta}):null
            this.setState({ 
                nuevoActivado:false,
                personaSeleccionada:personaSel,
                personaSeleccionadaRepresentada:personaSelRepresentada,
                vota:this.state.asistenteSeleccionado.vota,
                modalAbierto: true
             })
        }

        const abrirModalBorrar = () =>{
            if(!this.state.asistenteSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({ 
                nuevoActivado:false,
                modalBorrarAsistente: true 
            });
        }

        const defaultSorted = [{
            dataField: 'NUMERO',
            order: 'asc'
        }];
        const columns = [
            {
                dataField: 'label',
                text: this.props.i18next.t("name",{ns:"global"}),
                sort: true
            },
            {
                dataField: 'vota',
                text: this.props.i18next.t("actas.derechoavoto",{ns:"page"}),
                sort: true,
                formatter: boolFormatter
            },
            {
                dataField: 'representa',
                text: this.props.i18next.t("actas.representaa",{ns:"page"}),
                sort: true
            }
        ];

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    asistenteSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const cerrarModal=()=>{
            this.setState({
                nuevoActivado:false,
                modalAbierto:false
            });
        }

        const agregarAsistente=async ()=>{
            if(!this.state.personaSeleccionada){
                alert(this.props.i18next.t("actas.validasistente1",{ns:"page"}))
                return
            }
            let existe=this.props.asistentes.find(asistente=>{
                return asistente.value===this.state.personaSeleccionada.value
            })
            if(existe){
                alert(this.props.i18next.t("actas.validasistente2",{ns:"page"}))
                return
            }

            let asistentes=this.props.asistentes
            asistentes.push({ value: this.state.personaSeleccionada.value, label: this.state.personaSeleccionada.label, vota: this.state.vota?this.state.vota:false, representa: this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.label:null,idRepresenta:this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.value:null })
            await this.props.setAsistentes(asistentes)

            await this.setState({
                cargando:true
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            });
            
        }

        const eliminarAsistente=async()=>{
            
            let asistentes=this.props.asistentes
            asistentes=await asistentes.filter(asistente=>{return asistente.value!==this.state.asistenteSeleccionado.value})
            await  this.props.setAsistentes(asistentes)
            
            await this.setState({
                asistenteSeleccionado:null,
                cargando:true,
                modalBorrarAsistente:false
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            })

        
        }
        
        const actualizarAsistente=async ()=>{
            if(!this.state.asistenteSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(!this.state.personaSeleccionada){
                alert(this.props.i18next.t("actas.validasistente1",{ns:"page"}))
                return
            }

            let existe=this.props.asistentes.find(asistente=>{
                return asistente.value===this.state.personaSeleccionada.value
            })
            if(existe && this.state.asistenteSeleccionado.value!==this.state.personaSeleccionada.value){
                alert(this.props.i18next.t("actas.validasistente2",{ns:"page"}))
                return
            }

            let asistentes=this.props.asistentes
            let asistente=await asistentes.find(asistente=>{return asistente.value===this.state.asistenteSeleccionado.value})
            asistente.value=this.state.personaSeleccionada.value
            asistente.label=this.state.personaSeleccionada.label
            asistente.vota=this.state.vota
            asistente.representa=this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.label:null
            asistente.idRepresenta=this.state.personaSeleccionadaRepresentada?this.state.personaSeleccionadaRepresentada.value:null
            await  this.props.setAsistentes(asistentes)
            
            await this.setState({
                cargando:true
            },()=>{
                cerrarModal();
                this.setState({cargando:false});
            });
        }

        const seleccionPersona=(value)=>{
            this.setState({personaSeleccionada: value})
        }

        const seleccionPersonaRepresentada=(value)=>{
            if(!this.state.personaSeleccionada){
                alert(this.props.i18next.t("actas.validasistente1",{ns:"page"}))
                return
            }
            if(this.state.personaSeleccionada.value===value.value){
                alert(this.props.i18next.t("actas.validasistente3",{ns:"page"}))
                return
            }

            this.setState({personaSeleccionadaRepresentada: value})
        }

        const abrirModalNuevaPersona=()=>{
    
            //abrir modal de persona física
            this.setState({
                modalPersonaAbierto:true,
                personaSeleccionada:null
            })

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({
                modalNuevaJuridicaAbierto:true,
                personaSeleccionada:null
            })
        
        }

        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text={this.props.i18next.t("newpersfisica",{ns:"global"})} fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text={this.props.i18next.t("newpersjuridica",{ns:"global"})} onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const cerrarModalPersona=()=>{
            this.setState({
                modalPersonaAbierto:false,
            });
        }

        const cerrarModalBorrar=()=>{
            this.setState({
                nuevoActivado:false,
                modalBorrarAsistente:false
            });
        }

        const crearPersona=async ()=>{
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                cerrarModalPersona();
                await this.props.cargarPersonas();
                let personaSel=this.props.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
            }
        }

        const guardarPersonaJuridica=async ()=>{
            
            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                <>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModalPersona}
                    title={this.props.i18next.t("proppersona.proppersona",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                    >
                    <DatosPersonasFisicas
                        datosPersona={
                        this.state.personaSeleccionada
                            ? this.state.personaSeleccionada.value
                            : null
                        }
                        ref={input => {
                        this.datosPersona = input;
                        }}
                        i18next={this.props.i18next}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModalPersona}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip
                            content={this.props.i18next.t("agregar",{ns:"global"})}
                            position="top"
                        >
                            <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={crearPersona}
                            >
                            {this.props.i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                    title={this.props.i18next.t("organoadministracion.propcompany",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevaJuridicaAbierto}
                    className="datos-empresa-dialog"
                >
                        <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={this.props.i18next}/>  
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("cancelar",{ns:"global"})} position="top">          
                            <Button
                                alignText="left"
                                icon="cross"
                                text={this.props.i18next.t("cancelar",{ns:"global"})}
                                intent="danger"
                                onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                                className="iconoIzquierda bp3-minimal"
                            />
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
                        <Button
                            alignText="left"
                            icon="floppy-disk"
                            text={this.props.i18next.t("guardar",{ns:"global"})}
                            intent="danger"
                            onClick={guardarPersonaJuridica}
                            className="iconoIzquierda bp3-minimal"
                        />
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div id="dialogo-asistentes" className="bp3-card separarBordes">
                            <FormGroup>
                                <div id="botones-creacion-persona">
                                    <Popover  content={<Botonera />} transitionDuration={400} target={<Button  className="bp3-minimal" icon="add" text={this.props.i18next.t("newpers",{ns:"global"})} />} />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label={this.props.i18next.t("actas.personaasiste",{ns:"page"})+":"}
                                labelFor="persona"
                                intent="danger"
                                helperText={!this.state.personaSeleccionada?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("actas.personaasiste",{ns:"page"}):""}
                            >
                                <div id="select-persona-asiste" style={this.state.personaSeleccionada?{}:{border:"1px solid red"}}>
                                    <Select
                                        options={this.props.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersona}
                                        value={this.state.personaSeleccionada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label={this.props.i18next.t("actas.personarepresenta",{ns:"page"})+":"}
                                labelFor="persona"
                                intent="danger"
                            >
                                <div id="select-persona-representada">
                                    <Select
                                        options={this.props.personas}
                                        className="pixelAlrededor"
                                        onChange={seleccionPersonaRepresentada}
                                        value={this.state.personaSeleccionadaRepresentada}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label={this.props.i18next.t("actas.questionvoto",{ns:"page"})}
                                inline={true}
                            >
                                <Switch id="boleano-derecho-voto" innerLabelChecked={this.props.i18next.t("yes",{ns:"global"})} innerLabel={this.props.i18next.t("no",{ns:"global"})} onChange={handleDerechoVoto} checked={this.state.vota} />
                            </FormGroup>
                            
                        </div> 
                    </div>   
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                            </Tooltip>
                            <Tooltip content={this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                                <AnchorButton
                                    id="agregar-asistente"
                                    intent={Intent.PRIMARY}
                                    onClick={this.state.nuevoActivado?agregarAsistente:actualizarAsistente}
                                >
                                {this.state.nuevoActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModalBorrar}
                    title={this.props.i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAsistente}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{this.props.i18next.t("actas.questiondelasistente",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={this.props.i18next.t("eliminar",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarAsistente}
                                >
                                    {this.props.i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModalBorrar}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup id="botonera-asistentes" align={Alignment.LEFT}>
                            <Button className="bp3-minimal" id="btn-nuevo-asistente" icon="add" text={this.props.i18next.t("new",{ns:"global"})} onClick={abrirModalNuevo} />
                            <Button className="bp3-minimal" icon="edit" id="btn-editar-asistente" text={this.props.i18next.t("edit",{ns:"global"})}  onClick={abrirModalEditar} />
                            <Button className="bp3-minimal" icon="delete" id="btn-eliminar-asistente" text={this.props.i18next.t("delete",{ns:"global"})}  onClick={abrirModalBorrar} />
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                <BootstrapTable
                    keyField='value'
                    data={ this.props.asistentes }
                    columns={ columns }
                    selectRow={ const_table.selectRow(handleOnSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    defaultSorted={ defaultSorted }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                />
                </>
                }
            </>
        )
    }
}
export default withApollo(AsistentesActa);