/* eslint-disable no-useless-escape */
import React, { Component } from "react"
import { Card, Elevation,Tooltip,Dialog, Classes, AnchorButton, Intent, Navbar, Button, Alignment, Switch, InputGroup, FormGroup, Callout, TextArea, Spinner } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import moment from 'moment'
import Select from "react-select"
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { PDFViewer, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from './BootstrapTable';


import styles from './stylesPrint';

const today = new Date();

class Administradores extends Component {

    state = {
        administradores: [
        ],
        modalAdmins: false,
        personaFoJ: false,
        nombreAdmin: null,
        CIF: null,
        fechaNombramiento: new Date(),
        fechaCese: new Date(),
        cese: false,
        tiposAdmin: [
        ],
        adminElegido: null,
        retribucion: false,
        tipoRetribucion: null,
        cuantia: 0,
        relacionLaboral: false,
        observaciones: null,
        cifInvalido:false,
        dniInvalido:false,
        cuantiaValido:true,
        cargando:false,
        columnWidths: [100,100,100,100,100,100,100,100,100],
        resized: false,
        datosEmpresa:null,
        cargandoImprimirAG:false,
        modalImprimirAG: false,
        logotipo: null,
        reloadTable: false
    }


    

    generarImprimirAG= async() => {
        this.setState({
            cargandoImprimirAG:true,
            modalImprimirAG:true,
            reloadTable:true,
            administradorSeleccionado:null
        }, async () => {
             this.setState({cargandoImprimirAG:false}, () => {
                 ReactDOM.render(this.ImprimirAG(), document.getElementById('generarAG-pdf'))
             })
        })
    }

    ImprimirAG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report", { ns: "global" }).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("partesInteresadas.admins", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("partesInteresadas.adminList", { ns: "page2" }).toUpperCase()}</Text>
                </View>
                    {this.state.administradores?this.state.administradores.map((administrador, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("partesInteresadas.nameReason", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("partesInteresadas.appoint", { ns: "page2" }).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("partesInteresadas.cease", { ns: "page2" }).toUpperCase()}</Text>                                                                
                                        <Text style={{ textAlign: 'center', flexDirection: 'column', borderRightWidth: 1, fontSize: 8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width: '10%' }}>{i18next.t("partesInteresadas.admonType", { ns: "page2" }).toUpperCase() }</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'8%'}}>{i18next.t("partesInteresadas.statRet", { ns: "page2" }).toUpperCase()}</Text>                                                                
                                        <Text style={{ textAlign: 'center', flexDirection: 'column', borderRightWidth: 1, fontSize: 8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width: '10%' }}>{ i18next.t("partesInteresadas.retClass", { ns: "page2" }).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("partesInteresadas.annualAmt", { ns: "page2" }).toUpperCase()}</Text>
                                        <Text style={{ textAlign: 'center', flexDirection: 'column', borderRightWidth: 1, fontSize: 8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width: '8%' }}>{i18next.t("partesInteresadas.laborRel", { ns: "page2" }).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'21%'}}>OBSERVACIONES</Text>                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{administrador.NOMBRE?administrador.NOMBRE:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{administrador.FECHA_NOMBRAMIENTO?moment(administrador.FECHA_NOMBRAMIENTO).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{administrador.FECHA_CESE?moment(administrador.FECHA_CESE).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{administrador.FORMA_ADMINISTRACION?administrador.FORMA_ADMINISTRACION.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{administrador.RETRIBUCION?i18next.t("yes", { ns: "global" }).toUpperCase():i18next.t("no", { ns: "global" }).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{administrador.CLASE_RETRIBUCION?administrador.CLASE_RETRIBUCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{administrador.CUANTIA_ANUAL?administrador.CUANTIA_ANUAL:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'8%'}}>{administrador.RELACION_LABORAL?i18next.t("yes", { ns: "global" }).toUpperCase():i18next.t("no", { ns: "global" }).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'21%'}}>{administrador.OBSERVACIONES?administrador.OBSERVACIONES:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${i18next.t("page",{ns:"global"})} ${pageNumber}${i18next.t("de",{ns:"global"})} ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
    cargarFormas=async()=>{
        await this.props.client
        .query({
          query: gql`
            {formasAdministracion{ID_FORMA_ADMINISTRACION,DESCRIPCION}}
          `
        })
        .then(result =>{
            let opciones=[]
            result.data.formasAdministracion.forEach(forma=>{
                opciones.push({"value":forma.ID_FORMA_ADMINISTRACION,"label":forma.DESCRIPCION})
            })
            this.setState({tiposAdmin:opciones})
        });
    }
    cargarAdministradores=async()=>{

        let admins=await this.props.client
        .query({
            query: gql`
            {
                Administradores{
                    ID_ADMINISTRADOR,
                    PERSONA_FISICA,
                    NOMBRE,
                    DOCUMENTO_IDENTIDAD,
                    FECHA_NOMBRAMIENTO,
                    FECHA_CESE,
                    TIPO,
                    FORMA_ADMINISTRACION{DESCRIPCION},
                    RETRIBUCION,
                    CLASE_RETRIBUCION,
                    CUANTIA_ANUAL,
                    RELACION_LABORAL,
                    OBSERVACIONES
                }
            }
            `,fetchPolicy:'network-only'
        }).then((result)=>{
            return JSON.parse(JSON.stringify(result.data.Administradores))
        })

        this.setState({administradores:admins})
        
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        this.setState({
            datosEmpresa:datosEmpresa
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    componentDidMount=()=>{
        this.setState({
            cargando: true
        },async () => {
            await this.cargarAdministradores()
            await this.cargarFormas()
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            this.setState({cargando: false})
        });
    }

    
    render(){

        document.title =`${i18next.t("lexcontrol", {ns:"global"})} | ${i18next.t("partesInteresadas.admins", { ns: "page2" })}`

        function dateFormatter(cell, row) {
            return (
                cell? moment(cell).format("DD-MM-YYYY") : ""
            );
        }

        function conditionalFormatter(cell, row) {
            return (
                cell?i18next.t("yes", { ns: "global" }): i18next.t("no", { ns: "global" })
            );
        }

        const columns = [
            {
                dataField: 'NOMBRE',
                text: i18next.t("partesInteresadas.nameReason", { ns: "page2" }),
                style: { minWidth: '150px' }
            },
            {
                dataField: 'FECHA_NOMBRAMIENTO',
                text: i18next.t("partesInteresadas.appoint", { ns: "page2" }),
                formatter: dateFormatter
            },
            {
                dataField: 'FECHA_CESE',
                text: i18next.t("partesInteresadas.cease", { ns: "page2" }),
                formatter: dateFormatter,
                style: { minWidth: '90px' }
            },
            {
                dataField: 'FORMA_ADMINISTRACION.DESCRIPCION',
                text: i18next.t("partesInteresadas.admonType", { ns: "page2" })
            },
            {
                dataField: 'RETRIBUCION',
                text: i18next.t("partesInteresadas.statComp", { ns: "page2" }),
                formatter: conditionalFormatter
            },
            {
                dataField: 'CLASE_RETRIBUCION',
                text: i18next.t("partesInteresadas.compClass", { ns: "page2" })
            },
            {
                dataField: 'CUANTIA_ANUAL',
                text: i18next.t("partesInteresadas.annualAmt", { ns: "page2" })
            },
            {
                dataField: 'RELACION_LABORAL',
                text: i18next.t("partesInteresadas.laborRel", { ns: "page2" }),
                formatter: conditionalFormatter
            }
        ];

        const handleOnSelect = (row, isSelect) => {
            if (isSelect) {
                this.setState({
                    administradorSeleccionado: row,
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            onSelect: handleOnSelect,
            style: { backgroundColor: '#c8e6c9' }
        };

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirAG:false,
                reloadTable:false
            })
        }

        const guardarCambios = () => {
            
            if(!this.state.nombreAdmin)return
            if(!this.state.CIF)return
            if(!this.state.tipoRetribucion)return
            if(!this.state.adminElegido)return
            if(!this.state.cuantiaValido)return

            let existe=this.state.administradores.find(admin=>{return admin.DOCUMENTO_IDENTIDAD===this.state.CIF})
            if(existe){
                alert(i18next.t("partesInteresadas.existingAdmin", { ns: "page2" }))
                return
            }

            //validacion del dni o cif
            if(!this.state.personaFoJ){
                //if(!validarDni(this.state.CIF)){
                //    this.setState({dniInvalido:true})
                //    return
                //}else{
                    this.setState({dniInvalido:false})
                //}
            }else{
                //if(!validaCif(this.state.CIF)){
                //    this.setState({cifInvalido:true})
                //    return
                //}else{
                    this.setState({cifInvalido:false})
                //}
            }


            //damos de alta el administrador
            this.props.client.mutate({
                mutation:gql`mutation{crearAdministrador(
                   VALORES:{
                        PERSONA_FISICA:${this.state.personaFoJ}
                        NOMBRE:"${this.state.nombreAdmin}"
                        DOCUMENTO_IDENTIDAD:"${this.state.CIF}"
                        FECHA_NOMBRAMIENTO:"${moment(this.state.fechaNombramiento).format("YYYY-MM-DD")}"
                        FECHA_CESE:${this.state.cese?'"'+moment(this.state.fechaCese).format("YYYY-MM-DD")+'"':null}
                        TIPO:${this.state.adminElegido.value}
                        RETRIBUCION:${this.state.retribucion}
                        CLASE_RETRIBUCION:"${this.state.tipoRetribucion}"
                        CUANTIA_ANUAL:${this.state.cuantia.replace(/\./g, '').replace(/\,/g, '.')}
                        RELACION_LABORAL:${this.state.relacionLaboral}
                        OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}
                   } 
                ){ID_ADMINISTRADOR}}`
            }).then(async result=>{
            
                alert(i18next.t("insertcorrecto", { ns: "global" }))
                cerrarModal()
                await this.cargarAdministradores()
                
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })


        }

        const actualizarAdministrador=()=>{

            if(!this.state.nombreAdmin)return
            if(!this.state.CIF)return
            if(!this.state.tipoRetribucion)return
            if(!this.state.adminElegido)return
            if(!this.state.cuantiaValido)return

            let existe=this.state.administradores.find(admin=>{return admin.DOCUMENTO_IDENTIDAD===this.state.CIF && admin.ID_ADMINISTRADOR!==this.state.administradorSeleccionado.ID_ADMINISTRADOR})
            if(existe){
                alert(i18next.t("partesInteresadas.existingAdmin", { ns: "page2" }))
                return
            }

            //validacion del dni o cif
            if(!this.state.personaFoJ){
                //if(!validarDni(this.state.CIF)){
                //    this.setState({dniInvalido:true})
                //    return
                //}else{
                    this.setState({dniInvalido:false})
                //}
            }else{
                //if(!validaCif(this.state.CIF)){
                //    this.setState({cifInvalido:true})
                //    return
                //}else{
                    this.setState({cifInvalido:false})
                //}
            }   

            //damos de alta el administrador
            this.props.client.mutate({
                mutation:gql`mutation{actualizarAdministrador(
                    ID_ADMINISTRADOR:${this.state.administradorSeleccionado.ID_ADMINISTRADOR},
                    VALORES:{
                        PERSONA_FISICA:${this.state.personaFoJ}
                        NOMBRE:"${this.state.nombreAdmin}"
                        DOCUMENTO_IDENTIDAD:"${this.state.CIF}"
                        FECHA_NOMBRAMIENTO:"${moment(this.state.fechaNombramiento).format("YYYY-MM-DD")}"
                        FECHA_CESE:${this.state.cese?'"'+moment(this.state.fechaCese).format("YYYY-MM-DD")+'"':null}
                        TIPO:${this.state.adminElegido.value}
                        RETRIBUCION:${this.state.retribucion}
                        CLASE_RETRIBUCION:"${this.state.tipoRetribucion}"
                        CUANTIA_ANUAL:${this.state.cuantia.replace(/\./g, '').replace(/\,/g, '.')}
                        RELACION_LABORAL:${this.state.relacionLaboral}
                        OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}
                   } 
                ){ID_ADMINISTRADOR}}`
            }).then(async result=>{
            
                alert(i18next.t("updatecorrecto", { ns: "global" }))
                cerrarModal()
                await this.cargarAdministradores()

            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })


        }

        const borrarAdmin = () => {
            if(!this.state.administradorSeleccionado){
                alert(i18next.t("partesInteresadas.selectAdmin", { ns: "page2" }))
                return
            }

            //eliminamos el administrador
            this.props.client.mutate({
                mutation:gql`mutation{eliminarAdminstrador(
                    ID_ADMINISTRADOR:${this.state.administradorSeleccionado.ID_ADMINISTRADOR},
                ){ID_ADMINISTRADOR}}`
            }).then(async result=>{
            
                alert(i18next.t("deletecorrecto", { ns: "global" }))
                cerrarModal()
                await this.cargarAdministradores()

                this.setState({
                    administradorSeleccionado:null
                })
                
            }).catch(err=>{
                alert(i18next.t("error", { ns: "global" }))
            })
            
        }

        const cerrarModal = () => {
            this.setState({
                modalAdmins: false,
                modalBorrarAbierto: false,
                reloadTable:false,
                administradorSeleccionado: null
            })
        }

        
        const abrirModal = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            this.setState({
                dniInvalido:false,
                cifInvalido:false,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                personaFoJ: false,
                nombreAdmin: null,
                CIF: null,
                fechaNombramiento: new Date(),
                fechaCese: new Date(),
                cese: false,
                adminElegido: null,
                retribucion: false,
                tipoRetribucion: null,
                cuantia: '0',
                relacionLaboral: false,
                observaciones: null,
                modalAdmins: true,
                administradorSeleccionado: null,
                reloadTable:true

            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.administradorSeleccionado){
                alert(i18next.t("partesInteresadas.selectAdmin", { ns: "page2" }))
                return
            }
            
            //buscamos el tipo seleccionado
            let tipo=this.state.tiposAdmin.find(tipo=>{return tipo.value===this.state.administradorSeleccionado.TIPO})

            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                personaFoJ: this.state.administradorSeleccionado.PERSONA_FISICA,
                nombreAdmin: this.state.administradorSeleccionado.NOMBRE,
                CIF: this.state.administradorSeleccionado.DOCUMENTO_IDENTIDAD,
                fechaNombramiento: new Date(this.state.administradorSeleccionado.FECHA_NOMBRAMIENTO),
                fechaCese: (this.state.administradorSeleccionado.FECHA_CESE?new Date(this.state.administradorSeleccionado.FECHA_CESE):null),
                cese: (this.state.administradorSeleccionado.FECHA_CESE?true:false),
                adminElegido: tipo,
                retribucion: this.state.administradorSeleccionado.RETRIBUCION,
                tipoRetribucion: this.state.administradorSeleccionado.CLASE_RETRIBUCION,
                cuantia: Intl.NumberFormat('es-ES').format(this.state.administradorSeleccionado.CUANTIA_ANUAL),
                relacionLaboral: this.state.administradorSeleccionado.RELACION_LABORAL,
                observaciones: this.state.administradorSeleccionado.OBSERVACIONES,
                modalAdmins: true,
                reloadTable:true
            })

        }

        const abrirModalBorrar=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso", { ns: "global" }))
                return
            }

            if(!this.state.administradorSeleccionado){
                alert(i18next.t("partesInteresadas.selectAdmin", { ns: "page2" }))
                return
            }
            
            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true,
            
            })
        }

        const cambiarTipoPersona = () => {            
            this.setState ({
                personaFoJ: !this.state.personaFoJ
            })
        }

        const cambiarNombre = (e) => {
            this.setState({nombreAdmin: e.currentTarget.value})
        }

        const cambiarCif = (e) => {
            this.setState({CIF: e.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        
        const escogeFechaNombramiento = (nuevaFecha) => {
            this.setState({ fechaNombramiento: nuevaFecha })
        }

        
        const escogeFechaCese = (nuevaFecha) => {
            this.setState({ fechaCese: nuevaFecha })
        }

        const handleSiSeCesaOQue = () => {
            let val=!this.state.cese
            if(val){
                this.setState({fechaCese:new Date()})
            }else{
                this.setState({fechaCese:null})
            }
            this.setState({ cese:val  })
        }

        const handleRetribucion = () => {
            this.setState({ retribucion: !this.state.retribucion })
        }

        const handleRelacionLaboral = () => {
            this.setState({ relacionLaboral: !this.state.relacionLaboral })
        }

        const seleccionTipo  = (value) => {
            this.setState({adminElegido: value})
        }

        const cambiarTipoRetribucion = (e) => {
            this.setState({ tipoRetribucion: e.currentTarget.value })
        }

        const formatearMiles=(numero)=>{
            return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }

        const cambiarCuantia = (ev) => {

               //solo permitir en el input numeros,comas y puntos.
               let pattern=/^[0-9,.]*$/
               if(pattern.test(ev.currentTarget.value)===false)return
   
               //comprobacion que sea un número válido
               let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
               if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                   this.setState({cuantiaValido:true})
               }else{
                   this.setState({cuantiaValido:false})
               }
   
               this.setState({cuantia:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })

        }

        const cambiarObservaciones = (e) => {
            this.setState({ observaciones: e.currentTarget.value })
        }
        
        console.log(this.state.administradorSeleccionado)
        return (
            <>
            {this.state.cargando===true?
                <Cargando/>
            :
            <>
                
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={`${i18next.t("print", { ns: "global" })} ${i18next.t("partesInteresadas.admins", { ns: "page2" })}`}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirAG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirAG?
                            <div style={{ width: '100%', height: '500px'}} id='generarAG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={`${i18next.t("create", { ns: "global" })}/${i18next.t("edit", { ns: "global" })} ${i18next.t("partesInteresadas.admin", { ns: "page2" })}`}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAdmins}
                    className="dialog-ancho"
                >
                <div>
                <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                <Switch label={i18next.t("partesInteresadas.natJurPerson", { ns: "page2" })} innerLabel={i18next.t("partesInteresadas.naturalPerson", { ns: "page2" })} innerLabelChecked={i18next.t("partesInteresadas.legalEntity", { ns: "page2" })} checked={this.state.personaFoJ} onChange={cambiarTipoPersona} />
                    {!this.state.personaFoJ
                    ? 
                    <>
                        <FormGroup
                            label={`${i18next.t("name", { ns: "global" })}:`}
                            labelFor="nombre"
                        >
                            <InputGroup value={this.state.nombreAdmin} onChange={cambiarNombre} placeholder={i18next.t("partesInteresadas.personName", { ns: "page2" })} intent={this.state.nombreAdmin?"primary":"danger"} />
                        </FormGroup>
                        <FormGroup
                            label={`${i18next.t("partesInteresadas.nif", { ns: "page2" })}:`}
                            labelFor="NIF"
                            intent="danger"
                            helperText={(this.state.dniInvalido?i18next.t("partesInteresadas.validNif", { ns: "page2" }):"")}
                        >
                            <InputGroup value={this.state.CIF} onChange={cambiarCif}  placeholder={i18next.t("partesInteresadas.writeNif", { ns: "page2" })} intent={!this.state.CIF || this.state.dniInvalido ?"danger":"primary"} />
                        </FormGroup>
                    </>
                    : 
                    <>
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.businessName", { ns: "page2" })}:`}
                        labelFor="razon-social"
                    >
                        <InputGroup value={this.state.nombreAdmin} onChange={cambiarNombre}  placeholder={i18next.t("partesInteresadas.writebusinessName", { ns: "page2" })} intent={this.state.nombreAdmin?"primary":"danger"} />
                    </FormGroup>
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.cif", { ns: "page2" })}:`}
                        labelFor="CIF"
                        intent="danger"
                        helperText={(this.state.cifInvalido?i18next.t("partesInteresadas.validCif", { ns: "page2" }):"")}
                    >
                        <InputGroup value={this.state.CIF} onChange={cambiarCif}  placeholder={i18next.t("partesInteresadas.writeCif", { ns: "page2" })} intent={!this.state.CIF || this.state.cifInvalido ?"danger":"primary"} />
                    </FormGroup>
                    </>
                    }

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.appointDate", { ns: "page2" })}:`}
                        labelFor="fecha-nombramiento"
                    >

                    <DateInput minDate={new Date(1900, 1 ,1)}
                        maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                        {...jsDateFormatter} 
                        onChange={escogeFechaNombramiento}
                        value={this.state.fechaNombramiento}
                    />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.ceaseDate", { ns: "page2" })}:`}
                        labelFor="fecha-cese"
                    >
                    
                    <Switch onChange={handleSiSeCesaOQue} checked={this.state.cese} innerLabelChecked={i18next.t("partesInteresadas.ceases", { ns: "page2" })} innerLabel={i18next.t("partesInteresadas.notCease", { ns: "page2" })} />

                    <DateInput minDate={new Date(1900, 1 ,1)}
                        maxDate={new Date(today.getFullYear()+100, 11 ,31)}
                        {...jsDateFormatter} 
                        onChange={escogeFechaCese}
                        value={this.state.fechaCese}
                        disabled={!this.state.cese}
                    />
                    </FormGroup>
                    
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.adminType", { ns: "page2" })}:`}
                        labelFor="tipo-admin"
                    >
                        <div style={!this.state.adminElegido?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.tiposAdmin}
                                className="pixelAlrededor"
                                onChange={seleccionTipo}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                value={this.state.adminElegido}
                            />
                        </div>
                    </FormGroup>
                    
                    <FormGroup
                        label={`${i18next.t("partesInteresadas.statComp", { ns: "page2" })}:`}
                        labelFor="retribucion-estatutaria"
                        inline={true}
                    >
                                        <Switch onChange={handleRetribucion} checked={this.state.retribucion} innerLabelChecked={i18next.t("yes", { ns: "global" })} innerLabel={i18next.t("no", { ns: "global" }) } />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.compClass", { ns: "page2" })}:`}
                        labelFor="clase-retribucion"
                        inline={true}
                    >
                        <InputGroup value={this.state.tipoRetribucion} onChange={cambiarTipoRetribucion}  intent={this.state.tipoRetribucion?"primary":"danger"} />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.annualAmt", { ns: "page2" })}:`}
                        labelFor="cuantia-anual"
                        inline={true}
                        intent="danger"
                        helperText={!this.state.cuantiaValido?i18next.t("partesInteresadas.validAmount", { ns: "page2" }):""}
                    >
                        <InputGroup type="text"  intent={!this.state.cuantiaValido?"danger":"primary"} value={this.state.cuantia} onChange={cambiarCuantia} />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.laborRelSoc", { ns: "page2" })}:`}
                        labelFor="relacion-laboral"
                        inline={true}
                    >
                        <Switch onChange={handleRelacionLaboral} checked={this.state.relacionLaboral} innerLabelChecked={i18next.t("yes", { ns: "global" })} innerLabel={i18next.t("no", { ns: "global" })} />
                    </FormGroup>

                    <FormGroup
                        label={`${i18next.t("partesInteresadas.observations", { ns: "page2" })}:`}
                        labelFor="observaciones"
                    >
                        <TextArea fill={true} onChange={cambiarObservaciones} value={this.state.observaciones} />
                    </FormGroup>

                </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={this.state.nuevoActivado?guardarCambios:actualizarAdministrador}>
                        {i18next.t("guardar", { ns: "global" })}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
                        {i18next.t("close", { ns: "global" })}
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("partesInteresadas.deleteAcc", { ns: "page2" })}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("partesInteresadas.confirmDelete", { ns: "page2" })}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("partesInteresadas.deleteAdmin", { ns: "page2" })} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={borrarAdmin}
                                >
                                   {i18next.t("yes", { ns: "global" })}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver", { ns: "global" })} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no", { ns: "global" })}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>

                <div className="tablaSesenta pixelAlrededor">
                
                <Card interactive={true} elevation={Elevation.TWO}>

                    <Navbar>
                        <Navbar.Group align={Alignment.LEFT}>
                            <Button 
                                alignText="right"
                                icon="add"
                                text={i18next.t("addnew", { ns: "global" })}
                                onClick={abrirModal}
                                className="iconoDerecha bp3-minimal"
                            />
                            <Button 
                                alignText="right"
                                icon="edit"
                                text={i18next.t("edit", { ns: "global" })}
                                onClick={abrirModalEditar}
                                className="iconoDerecha bp3-minimal"
                            />
                            <Button 
                                alignText="right"
                                icon="cross"
                                text={i18next.t("delete", { ns: "global" })}
                                onClick={abrirModalBorrar}
                                className="iconoDerecha bp3-minimal"
                            />
                            <Tooltip 
                                content={i18next.t("partesInteresadas.printAdmins", { ns: "page2" })} 
                                position="right" 
                            >
                                <Button 
                                    className="bp3-minimal" 
                                    icon="print" 
                                    text={i18next.t("printgral", { ns: "global" })} 
                                    onClick={()=>this.generarImprimirAG()}
                                />
                            </Tooltip>

                        </Navbar.Group>
                        </Navbar>
                        {this.state.reloadTable?
                            <Cargando/>
                            :
                            <>          
                                <BootstrapTable
                                    keyField="ID_ADMINISTRADOR"
                                    data={this.state.administradores}
                                    columns={columns}
                                    selectRow={selectRow}
                                    pagination={ paginationFactory(const_table.generateOptions(4,0,5)) }
                                    headerClasses="header-class"
                                    rowClasses="row-class"
                                    hover
                                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                                />
                            </>
                        }
                </Card>
                </div>

                <div className="pixelAlrededor tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("partesInteresadas.titleViLsc", { ns: "page2" })}</Callout>
                        <br />
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-2010-10544&p=20181229&tn=1#tvi", "_blank")}>{i18next.t("partesInteresadas.titleViLsc", { ns: "page2" })}</AnchorButton>
                        <br />
                    </Card>
                </div>
            </>
            }
            </>
        )
    }
}export default withTranslation()(withApollo(Administradores))