import React from "react";
import moment from 'moment';
import {
  Card,
  Elevation,
  Navbar,
  ButtonGroup,
  Alignment,
  Button,
  NavbarDivider,
  InputGroup,
  Callout,
  Dialog,
  Tabs,
  Tab,
  Classes,
  Tooltip,
  AnchorButton,
  Intent,
  FormGroup,
  Collapse,
  TextArea,
  Switch,
  Spinner
} from "@blueprintjs/core";
import { Table, Column, Cell,Regions,RegionCardinality} from "@blueprintjs/table";
import { DateInput } from "@blueprintjs/datetime";
import Select from "react-select";

import DatosAcuerdo from "./DatosAcuerdo";
import TextoActas from "./TextoActas";
import ControlAcuerdo from "./ControlAcuerdo";
import Votos from "./Votos";
import Anulacion from "./Anulacion"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import { DatosImpugnacion } from "./DatosImpugnacion.1";
import ArchivosAdjuntosImpugnaciones from "../../Pantallas/Elementos/ArchivosAdjuntosImpugnaciones"
import pantallaInicio from '../../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import ArchivosAdjuntosAcuerdos from "../../Pantallas/Elementos/ArchivosAdjuntosAcuerdos"
import { Cargando } from '../Cargando';
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import iconoFooter from '../../../Assets/lc.png';
import styles from '../stylesPrint';

let client = new ApolloClient({
  uri: process.env.REACT_APP_DOMINIO+"/api",
  credentials: "include"
});

export default class CalendarioActasDerecha extends React.Component {
  state = {
	modalNuevoAbierto: false,
	modalEditarActivado: false,
	modalBorrarActivado: false,
	dialogTextoAcuerdo: false,
	pestaña: "datos-acuerdo",
	pestañaImpugnacion: "datos-impugnacion",
	nuevoActivado: true,
	editarActivado: false,
	votacion: {

	},
	fechaPresentacion: new Date(),
	fechaInscripcion: new Date(),
	fechaEfecto: new Date(),
	fechaSolicitud: new Date(),
	inscripcionOptions: [

	],
	inscripcionSeleccionada: null,
	registroOptions: [],
	registroSeleccionada: null,
	acuerdoSeleccionado: null,
	registroNuevo: null,
	diario: null,
	asiento: null,
	tomo: null,
	folio: null,
	inscripcion: null,
	hoja: null,
	personas: [],
	acuerdosCertificados: [

	],
	textoImpugnacion: null,
	textoResolucion: null,
	textoAnulacion: null,
	textoSubsanacion: null,
	fechaEfectoImpugnacion: new Date(),
	textoAcuerdo: null,
	cargando:true,
	nombreEmpresa:null,
	regionSeleccionada:null,
	inscripcionActivado:false,
	certificacionActivado:false,
	impugnacionActivado:false,
	imprimirActivado:false,
  columnWidths: [100,100,100,100,100,100,100],
  resized: false,
  cargandoAcuerdos: false,
  columnWidthsC: [100,100,100],
  resizedC: false,
  cargandoImprimir:false,
  modalImprimir: false
  };

  resizeColumnsC() {
        
    let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
    let totalMinSizeReq = 0;
    let cumulativeColumnWidths= [];
    if (this.tablaCert) {
      for (let index = 0; index < this.tablaCert.locator.grid.numCols; index++) {
          totalMinSizeReq = totalMinSizeReq + this.tablaCert.locator.getWidestVisibleCellInColumn(index);
          cumulativeColumnWidths.push(this.tablaCert.locator.getWidestVisibleCellInColumn(index));
      }
      if (totalMinSizeReq > containerSize) {
          this.setState({
              columnWidthsC: cumulativeColumnWidths,
              resizedC: true
          });
      } else {
          let diff = ((containerSize - totalMinSizeReq) / this.tablaCert.locator.grid.numCols);
          this.setState({
              columnWidthsC: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
              resizedC: true
          });
      }
    }
  }

  resizeColumns() {
        
    let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
    let totalMinSizeReq = 0;
    let cumulativeColumnWidths= [];
    if (this.tablaAcuerdos) {
      for (let index = 0; index < this.tablaAcuerdos.locator.grid.numCols; index++) {
          totalMinSizeReq = totalMinSizeReq + this.tablaAcuerdos.locator.getWidestVisibleCellInColumn(index);
          cumulativeColumnWidths.push(this.tablaAcuerdos.locator.getWidestVisibleCellInColumn(index));
      }
      if (totalMinSizeReq > containerSize) {
          this.setState({
              columnWidths: cumulativeColumnWidths,
              resized: true
          });
      } else {
          let diff = ((containerSize - totalMinSizeReq) / this.tablaAcuerdos.locator.grid.numCols);
          this.setState({
              columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
              resized: true
          });
      }
    }
  }

  
  obtenerLogoEmpresa=async()=>{
    //obtencion logo de la empresa
    let client = new ApolloClient({
      uri: process.env.REACT_APP_DOMINIO+"/api",
      credentials: 'include',
    });
   
    await client
     .query({
         query: gql`
             {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
         `,fetchPolicy:'network-only'             
     }).then(result=>{ 
         if(result.data.adjuntos.length>0){

             var request = require('request').defaults({ encoding: null,withCredentials:true });

             request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                 if (!error && response.statusCode === 200) {
                 let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                 this.setState({logotipo:data})
                 }
             });

         }
     })
 
 }


  obtenerNombreMiembros = async miembro => {
	if (miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA === 1) {
	  //obtenemos el nombre
	  await client
		.query({
		  query: gql`{personasFisicas(FILTROS:{ID_PERSONA:${
			miembro.ID_PERSONA
		  }}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
		  fetchPolicy: "network-only"
		})
		.then(result => {
		  let persona = result.data.personasFisicas[0];
		  miembro.label = `${persona.APELLIDO2 ? persona.APELLIDO2 : ""} ${
			persona.APELLIDO1
		  },${persona.NOMBRE}`;
		});
	} else if (miembro.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA === 2) {
	  await client
		.query({
		  query: gql`{personasJuridicas(FILTROS:{ID_PERSONA:${
			miembro.ID_PERSONA
		  }}){RAZON_SOCIAL}}`,
		  fetchPolicy: "network-only"
		})
		.then(result => {
		  let persona = result.data.personasJuridicas[0];
		  miembro.label = persona.RAZON_SOCIAL;
		});
	}
  };

  cargarIncripcion=async () => {
    //obtenemos la inscripcion de los acuerdos
			await client.query({
				query: gql`
				  {
					actasAcuerdosInscripcionRm(FILTROS:{ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}}){
						ID_ACUERDO,
						FECHA_PRESENTACION,
						ID_CALIFICACION_ACUERDO,
						FECHA_INSCRIPCION,
						FECHA_EFECTO,
						ID_REGISTRO,
						DIARIO,
						ASIENTO,
						TOMO,
						FOLIO,
						INSCRIPCION,
						HOJA,
					}
				  }
				`,fetchPolicy:'network-only'
			}).then(async result =>{
				if(result.data.actasAcuerdosInscripcionRm.length>0){
					this.state.acuerdoSeleccionado.inscripcion=JSON.parse(JSON.stringify(result.data.actasAcuerdosInscripcionRm[0]))
				}
			})
  }

  cargarVotos=async() => {
    let votos=[]
			//obtenemos los votos del acuerdo.
			await client.query({
				query: gql`
				  {
					actasAcuerdosVotos(FILTROS:{ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}}){
						ID_ACUERDO,
						ID_PERSONA,
						PERSONA{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
						VOTO,
					}
				  }
				`,fetchPolicy:'network-only'
			}).then(async result =>{
				let votosAcuerdo=JSON.parse(JSON.stringify(result.data.actasAcuerdosVotos))

				//por cada voto...
				for(let voto of votosAcuerdo){

					//obtenemos el nombre de la persona del voto
					await this.obtenerNombreMiembros(voto)
					votos.push({
						idPersona:voto.ID_PERSONA,
						nombre:voto.label,
						voto:voto.VOTO
					})
				}

			})
			this.state.acuerdoSeleccionado.votos=votos
  }

  cargarDatosImpugnacion=async ()=>{
    //comprobamos si el acuerdo esta esta impugnado.
    await client.query({
			query: gql`
			  {
				actasAcuerdosImpugnaciones(FILTROS:{ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}}){
				  ID_IMPUGNACION,
				  ID_ACUERDO,
				  TEXTO,
				  RESOLUCION,
				  ANULACION,
				  SUBSANACION,
				  FECHA_EFECTO}
			  }
			`,fetchPolicy:'network-only'
		  }).then(async result =>{
			  if(result.data.actasAcuerdosImpugnaciones.length>0){
          this.state.acuerdoSeleccionado.datosImpugnacion=result.data.actasAcuerdosImpugnaciones[0]

				//si tiene impgugnacion comprobamos si tiene anulacion 
				await client.query({
				  query: gql`
					{
					  actasAcuerdosImpugnacionesAnulaciones(FILTROS:{ID_IMPUGNACION:${result.data.actasAcuerdosImpugnaciones[0].ID_IMPUGNACION}}){
						ID_ANULACION,
						ID_IMPUGNACION,
						FECHA_FIRMEZA,
						ID_REGISTRO,
						DIARIO,
						ASIENTO,
						TOMO,
						FOLIO,
						INSCRIPCION,
						HOJA
					  }
					}
				  `,fetchPolicy:'network-only'
				}).then(async result =>{
				  if(result.data.actasAcuerdosImpugnacionesAnulaciones.length>0){
            this.state.acuerdoSeleccionado.datosImpugnacion.datosAnulacion=result.data.actasAcuerdosImpugnacionesAnulaciones[0]
				  }
				})
			  }
		  })
  
  }

  

	cargarCalificacionesAcuerdos=async()=>{

	  await client.query({
		  query:gql`{calificacionesAcuerdo{ID_CALIFICACION_ACUERDO,DESCRIPCION}}`,
		  fetchPolicy:'network-only'
	  }).then((result)=>{

		  let calificaciones=[]
		  for(let calificacion of result.data.calificacionesAcuerdo){
			  calificaciones.push({
				  value:calificacion.ID_CALIFICACION_ACUERDO,
				  label:calificacion.DESCRIPCION
			  })
		  }
		  this.setState({inscripcionOptions:calificaciones})

	  })

  }

  cargarRegistros=async()=>{

	  await client.query({
		  query:gql`{registros{ID_REGISTRO,DESCRIPCION}}`,
		  fetchPolicy:'network-only'
	  }).then((result)=>{

		  let registros=[]
		  for(let registro of result.data.registros){
			  registros.push({
				  value:registro.ID_REGISTRO,
				  label:registro.DESCRIPCION
			  })
		  }
		  this.setState({registroOptions:registros})

	  })

  }

  formatearFecha=(fecha)=>{

	let arrayMeses=[
	  "Enero",
	  "Febrero",
	  "Marzo",
	  "Abril",
	  "Mayo",
	  "Junio",
	  "Julio",
	  "Agosto",
	  "Septiembre",
	  "Octubre",
	  "Noviembre",
	  "Diciembre"
	]


	return `${moment(fecha).format("DD")} de ${arrayMeses[parseInt(moment(fecha).format("MM"))-1]} de ${moment(fecha).format("YYYY")}`
  }

  obtenerReunion=()=>{

	if(!this.props.acta)return 

	let tipoReunion=""
	switch(this.props.acta.ID_TIPO_CONVOCATORIA){
	  case 1:
	  case 2:
	  case 4:
	  case 5:
		  tipoReunion=" de la "+this.props.acta.TIPO_CONVOCATORIA.DESCRIPCION
	  break
	  case 3:
	  case 6:
	  case 7:
	  case 8:
		  tipoReunion=" del "+this.props.acta.TIPO_CONVOCATORIA.DESCRIPCION
	  break;

	  default:
	  break;
	}
	return tipoReunion
  }

  /*componentDidUpdate = props => {
	if (props !== this.props) {
    if (this.props.acta) {
      this.setState({cargandoAcuerdos:true}, async () => {
        await this.cargarAcuerdos(this.props.acta.ID_ACTA);
        this.setState({cargandoAcuerdos:false})
      });
	  }else{
		  this.setState({cargandoAcuerdos:true}, async () => {
        await this.cargarAcuerdos(null);
        this.setState({cargandoAcuerdos:false})
      });
	  }
	}
  };*/

  cargarEmpleados=async ()=>{

	let empleados=await client
	.query({
		query: gql`
		{empleados{ID_EMPLEADO}}
		`
		,fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.empleados
	})

	//buscamos el nombre del empleado
	empleados.forEach(async(empleado,index)=>{
	   await client
		.query({
			query: gql`
			{personasFisicas(FILTROS:{ID_PERSONA:${empleado.ID_EMPLEADO}}){NOMBRE,APELLIDO1,APELLIDO2}}
			`
			,fetchPolicy:'network-only'
		}).then(result=>{ 
			if(result.data.personasFisicas.length>0){
				let empleado=result.data.personasFisicas[0]
				empleados[index].value=empleados[index].ID_EMPLEADO
				empleados[index].label=`${empleado.APELLIDO2?empleado.APELLIDO2:""} ${empleado.APELLIDO1},${empleado.NOMBRE}`
			} 
		})
	})

	this.setState({personas:empleados}) 
  }

  obtenerNombreEmpresa=async()=>{
 
	await client
	.query({
		query: gql`
		{
			personasJuridicas(FILTROS:{ID_PERSONA:1}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},GRUPO_EMPRESARIAL{DESCRIPCION},TIPO_SOCIEDAD{DESCRIPCION},ID_PERSONA,RAZON_SOCIAL,NOMBRE_COMERCIAL,EMPRESA_PRINCIPAL,FECHA_FINALIZACION,INICIO_EJERCICIO,FIN_EJERCICIO,ESCISIONES,WEB_CORPORATIVA,CONVENIOS_COLECTIVOS,TOTAL_PARTIDAS_ACTIVO,CIFRA_ANUAL_NEGOCIO,NUMERO_MEDIO_TRABAJADORES}
		}
		`,
		fetchPolicy: 'network-only'

	}).then(result=>{
		//si tenemos razon social.
		if(result.data.personasJuridicas.length>0){
			this.setState({nombreEmpresa:result.data.personasJuridicas[0].RAZON_SOCIAL})
		}
		
	})
  }

  componentDidMount=async()=>{
	
	this.setState({cargando:true,cargandoAcuerdos:true,resized:false})
	let usuarioActual=await client
	.query({
		query: gql`
		{obtenerUsuarioActual}
		`,
		fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.obtenerUsuarioActual
	})
	await this.setState({ idUsuarioActual:usuarioActual})
	await this.obtenerNombreEmpresa()
	await this.obtenerLogoEmpresa()
	await this.cargarCalificacionesAcuerdos()
	await this.cargarRegistros()
	await this.cargarEmpleados()
  this.resizeColumns()
	this.setState({cargando:false,cargandoAcuerdos:false})


}

generarImprimir= async() => {
  this.setState({
      cargandoImprimir:true,
      modalImprimir:true
  }, async () => {
       this.setState({cargandoImprimir:false}, () => {
           ReactDOM.render(this.Imprimir(), document.getElementById('generar-pdf'))
       })
  })
}

Imprimir=()=>{
  return (
  <PDFViewer style={{width: '100%', height: '100%'}}>
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.body}>
          <View fixed style={styles.row}>
            <Image
              style={styles.imageHead}
              src={this.state.logotipo?this.state.logotipo:pantallaInicio}
              />
          </View>
          <View style={styles.row}>
            <Text style={styles.labelTitleCompliance}>CERTIFICACIÓN DE LOS ACUERDOS ADOPTADOS POR ÓRGANO DE ADMINISTRACIÓN</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.labelTitleCompliance}>D./Dª SECRETARIO, secretario/a de {this.state.nombreEmpresa}</Text>
          </View>
          <View style={{height:5}}></View>
          <View style={styles.row}>
            <Text style={styles.labelTitleCompliance}>CERTIFICA:</Text>
          </View>
          <View style={{height:10}}></View>
          <View style={styles.row}>
            <Text style={styles.text}>Que según consta en el acta número {this.props.acta?this.props.acta.NUMERO:null} en la sesión ordinaria {this.obtenerReunion()},
						  celebrada en {this.props.acta?this.props.acta.LUGAR:null} a {this.props.acta?this.formatearFecha(this.props.acta.FECHA_REUNION):null}, se adoptaron los siguientes acuerdos:</Text>
          </View>
          <View style={{height:5}}></View>
          {this.state.acuerdoSeleccionado ? <View style={styles.row} ><Text style={styles.text}>* {this.state.acuerdoSeleccionado.TEXTO}</Text></View> : <View style={styles.row}><Text style={styles.text}>* No se ha llegado a ningún acuerdo</Text></View>}
						
          <View style={{height:10}}></View>
          <View style={styles.row}>
            <Text style={styles.labelTitleCompliance}>En {this.props.acta?this.props.acta.LUGAR:null}, a {this.formatearFecha(new Date())}.</Text>
          </View>
          <View style={{height:20}}></View>
          <View style={styles.row}>
            <Text style={styles.labelFirmaLeft}>Fdo.: EL SECRETARIO</Text>
            <Text style={styles.labelFirmaRight}>Vº Bº EL PRESIDENTE</Text>
          </View>
      </View>

      <Text
          style={styles.footerPage}
          render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
          fixed
        />
      <Image
              style={styles.icono}
              src={iconoFooter}
              fixed
              />
      <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
    </Page>
  </Document>
</PDFViewer>
)
}

certificacionAcuerdo =()=> (
  <div ref={(input) => { this.PdfCertificadoAcuerdo= input; }}>

	  <div className="html-pdf" ref={(input) => { this.tablaDelegadosAImprimir = input; }} >
		  <div className="body-pdf">
			  <br />
			  <img
				  className="App-logo-pdf"
				  src={this.state.logotipo?this.state.logotipo:pantallaInicio}
				  alt="logotipo"
			  />
			  <br />
			  <Card interactive={true} elevation={Elevation.TWO} className="cardAImprimir-pdf">
			  <div>
					<h3>CERTIFICACIÓN DE LOS ACUERDOS ADOPTADOS POR ÓRGANO DE ADMINISTRACIÓN</h3>
					<h3>D./Dª SECRETARIO, secretario/a de {this.state.nombreEmpresa}</h3>
					<h3>CERTIFICA:</h3>
					<p >Que según consta en el acta número {this.props.acta?this.props.acta.NUMERO:null} en la sesión ordinaria {this.obtenerReunion()},
						  celebrada en {this.props.acta?this.props.acta.LUGAR:null} a {this.props.acta?this.formatearFecha(this.props.acta.FECHA_REUNION):null}, se adoptaron los siguientes acuerdos:</p>
						{/* {this.state.acuerdos.filter(acuerdo=>{return acuerdo.certificado===true && !acuerdo.datosImpugnacion}).map(acuerdo=>{return (<p>-{acuerdo.TEXTO}</p>)})} */}
						
						{this.props.acuerdos.length !== 0? this.props.acuerdos.map((acuerdo,index)=>(<li key={index}>{acuerdo.TEXTO}</li>)) : <p>No se ha llegado a ningún acuerdo</p>}
						

					<h3>En {this.props.acta?this.props.acta.LUGAR:null}, a {this.formatearFecha(new Date())}.</h3>
					<div>
					<h4 className="texto-izquierda">Fdo.: EL SECRETARIO</h4>
					<h4 className="texto-derecha">Vº Bº EL PRESIDENTE</h4>
					</div>
				</div>  
			  </Card>
		  </div>
		  <p className="footer-pdf">{`Documento impreso mediante la aplicación LexControl el día ${moment().format("DD/MM/YYYY")} a las ${moment().format("HH:mm")}`}</p>
	  </div>     

  </div>
);



  render() {

	const abrirModalNuevo = () => {

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert("No tienes permisos para realizar esta acción")
		  return
	  }

	  this.setState({
		modalNuevoAbierto: true,
		nuevoActivado: true,
		editarActivado: false,
		inscripcionActivado:false,
		certificacionActivado:false,
		impugnacionActivado:false,
		acuerdoSeleccionado: null,
		regionSeleccionada:null,
		pestaña: "datos-acuerdo",
	  },()=>{this.tablaAcuerdos.clearSelection()});
	};

	const abrirModalEditar =async () => {

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert("No tienes permisos para realizar esta acción")
		  return
	  }

	  if (!this.state.acuerdoSeleccionado) {
		alert("Debe seleccionar un acuerdo");
		return;
	  }
    this.setState({
      cargandoAcuerdos:true
    }, async ()=>{
      await this.cargarVotos();

      this.setState({
      modalNuevoAbierto: true,
      nuevoActivado: false,
      editarActivado: true,
      inscripcionActivado:false,
      certificacionActivado:false,
      impugnacionActivado:false,
      pestaña:"datos-acuerdo",
      cargandoAcuerdos:false
      });
    })
    
	};

	const cerrarModal = () => {
	  this.setState({
		modalNuevoAbierto: false,
		modalEditarActivado: false,
		modalBorrarActivado: false,
		modalInscripcion: false,
		modalCertificacion: false,
		modalImpugnacion: false,
		modalNuevoRegistro: false,
		dialogTextoAcuerdo: false
	  });
	};

	const cerrarSoloEsteModal = () => {
	  this.setState({ dialogTextoAcuerdo: false });
	};

	const cambiarSwitchAcuerdos = (rowIndex) => {
	  
		let acuerdos=this.state.acuerdosCertificados
		acuerdos[rowIndex].certificado=!acuerdos[rowIndex].certificado
		this.setState({acuerdosCertificados:acuerdos})

	};

	const cellRendererNumero = rowIndex => {
	  return <Cell>{this.props.acuerdos[rowIndex].NUMERO}</Cell>;
	};

	const cellRendererNumeroCertificar = rowIndex => {
	  return <Cell>{this.props.acuerdosCertificados[rowIndex].NUMERO}</Cell>;
	};


	const cellRendererTexto = rowIndex => {
	  return <Cell>{this.props.acuerdos[rowIndex].TEXTO}</Cell>;
	};

	const cellRendererCertificadoSwitch = rowIndex => {
	  return (
		<Cell>
		  <Switch
			innerLabel="No"
			innerLabelChecked="Si"
			onChange={()=>{cambiarSwitchAcuerdos(rowIndex)}}
			checked={this.state.acuerdosCertificados[rowIndex].certificado}
		  />
		  {this.state.acuerdosCertificados[rowIndex].id}
		</Cell>
	  );
	};

	const cellRendererTextoAcuerdo = rowIndex => {
	  return (
		<Button onDoubleClick={()=>{abrirDialogoTextoAcuerdo(this.state.acuerdosCertificados[rowIndex].TEXTO)}}>
		  <Cell>{this.state.acuerdosCertificados[rowIndex].TEXTO}</Cell>
		</Button>
	  );
	};

	const cellRendererImpugnado = rowIndex => {
	  return this.props.acuerdos[rowIndex].datosImpugnacion ? (
		<Cell>SI</Cell>
	  ) : (
		<Cell>NO</Cell>
	  );
	};

  const cellRendererPlazo = rowIndex => {
	  return(<Cell>{this.props.acuerdos[rowIndex].PLAZO}</Cell>)
	};

  const cellRendererCumplimiento = rowIndex => {
	  return(<Cell>{this.props.acuerdos[rowIndex].CUMPLIMIENTO}</Cell>)
	};

  const cellRendererResponsable = rowIndex => {
    return(<Cell>{this.props.acuerdos[rowIndex].RESPONSABLE}</Cell>)
	};

	const cellRendererCertificado = rowIndex => {
	  return this.props.acuerdos[rowIndex].certificado ? (
		<Cell>SI</Cell>
	  ) : (
		<Cell>NO</Cell>
	  );
	};

	const abrirDialogoTextoAcuerdo = (texto) => {
	  this.setState({ textoAcuerdo:texto, dialogTextoAcuerdo: true });
	};

	const añadirNuevo =async () => {
	  
	  if (!this.textoActas.state.texto) {
		this.setState({pestaña:"texto"})
		return
	  };


	  //damos de alta el acuerdo.
	  await client
		.mutate({
		  mutation: gql`mutation{crearActaAcuerdo(VALORES:{          
					ID_ACTA:${this.props.acta.ID_ACTA}
					NUMERO:${this.props.acuerdos.length + 1}
					TEXTO:"""${this.textoActas.state.texto}"""
					INSCRIBIBLE_RM:${
					  this.datosAcuerdos.state.inscripcionRegistro
					}
					VOTOS_F:${this.datosAcuerdos.state.favor}
					VOTOS_C:${this.datosAcuerdos.state.contra}
					VOTOS_A:${this.datosAcuerdos.state.abstencion}
					CERTIFICADO:${false}
          PLAZO:"${this.ControlAcuerdo.state.plazo}"
          CUMPLIMIENTO:"${this.ControlAcuerdo.state.cumplimiento}"
					RESPONSABLE:"${this.ControlAcuerdo.state.responsable}"
					OBSERVACIONES:"""${this.ControlAcuerdo.state.observaciones}"""
				}){ID_ACUERDO}}`
		})
		.then(async result => {

		  alert("Acuerdo creado correctamente");

		  await client
		  .mutate({
			mutation: gql`mutation{crearActaAcuerdoH(VALORES:{     
					  ID_ACUERDO:${result.data.crearActaAcuerdo.ID_ACUERDO},
					  ID_ACTA:${this.props.acta.ID_ACTA}
					  NUMERO:${this.props.acuerdos.length + 1}
					  TEXTO:"""${this.textoActas.state.texto}"""
					  INSCRIBIBLE_RM:${
						this.datosAcuerdos.state.inscripcionRegistro
					  }
					  VOTOS_F:${this.datosAcuerdos.state.favor}
					  VOTOS_C:${this.datosAcuerdos.state.contra}
					  VOTOS_A:${this.datosAcuerdos.state.abstencion}
					  CERTIFICADO:${false},
					  FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
					  ID_USUARIO:${this.state.idUsuarioActual}
				  }){ID_ACUERDO}}`
		  }).catch(err=>{
			console.log(err)
			alert("Ha ocurrido un error al crear el acuerdo.Pongase en contacto con el soporte tecnico")
		  })

		  //damos de alta los votos
		  for (let voto of this.Votos.state.votos) {
			client.mutate({
			  mutation: gql`mutation{crearActaAcuerdoVoto(VALORES:{          
					ID_ACUERDO:${
					  result.data.crearActaAcuerdo.ID_ACUERDO
					},
					ID_PERSONA:${voto.idPersona},
					VOTO:${voto.voto}
				}){ID_ACUERDO}}`
			});
		  }

		  await  this.props.cargarAcuerdos(this.props.acta.ID_ACTA);
		  await this.props.acuerdos.find((acuerdo,index)=>{
			if(acuerdo.ID_ACUERDO===result.data.crearActaAcuerdo.ID_ACUERDO){
			  this.setState({acuerdoSeleccionado:acuerdo,regionSeleccionada:[Regions.row(index)]})
			}
			return acuerdo.ID_ACUERDO===result.data.crearActaAcuerdo.ID_ACUERDO
		  })

		  cerrarModal();
		})
		.catch(err => {
		  alert(
			"Ha ocurrido un error al crear el acuerdo.Pongase en contacto con el soporte tecnico"
		  );
		});
	};

	const actualizarAcuerdo = async () => {
	  if (!this.textoActas.state.texto) {
		this.setState({pestaña:"texto"})
		return
	  };
	  //damos de alta el acuerdo.

	  await client
		.mutate({
		  mutation: gql`mutation{actualizarActaAcuerdo(
			ID_ACUERDO:${
			  this.state.acuerdoSeleccionado.ID_ACUERDO
			},        
			VALORES:{  
				TEXTO:"""${this.textoActas.state.texto}"""
				INSCRIBIBLE_RM:${
				  this.datosAcuerdos.state.inscripcionRegistro
				}
				VOTOS_F:${this.datosAcuerdos.state.favor}
				VOTOS_C:${this.datosAcuerdos.state.contra}
				VOTOS_A:${this.datosAcuerdos.state.abstencion}
        PLAZO:"${this.ControlAcuerdo.state.plazo}"
        CUMPLIMIENTO:"${this.ControlAcuerdo.state.cumplimiento}"
        RESPONSABLE:"${this.ControlAcuerdo.state.responsable}"
        OBSERVACIONES:"""${this.ControlAcuerdo.state.observaciones}"""
			}){ID_ACUERDO}}`
		})
		.then(async result => {
		  alert("Acuerdo actualizado correctamente");

		  await client
		.mutate({
		  mutation: gql`mutation{actualizarActaAcuerdoH(
			ID_ACUERDO:${
			  this.state.acuerdoSeleccionado.ID_ACUERDO
			},        
			VALORES:{  
				TEXTO:"""${this.textoActas.state.texto}"""
				INSCRIBIBLE_RM:${
				  this.datosAcuerdos.state.inscripcionRegistro
				}
				VOTOS_F:${this.datosAcuerdos.state.favor}
				VOTOS_C:${this.datosAcuerdos.state.contra}
				VOTOS_A:${this.datosAcuerdos.state.abstencion}
				FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}"
				ID_USUARIO:${this.state.idUsuarioActual}
			}){ID_ACUERDO}}`
		}).catch(err=>{
		  alert("Ha ocurrido un error al actualizar el acuerdo.Pongase en contacto con el soporte tecnico")
		})

		  //eliminamos los antiguos votos
		  await client.mutate({
			mutation: gql`mutation{eliminarActaAcuerdoVoto(ID_ACUERDO:${
			  this.state.acuerdoSeleccionado.ID_ACUERDO
			}){ID_ACUERDO}}`
		  });

		  //damos de alta los nuevos votos
		  for (let voto of this.Votos.state.votos) {
			await client.mutate({
			  mutation: gql`mutation{crearActaAcuerdoVoto(VALORES:{          
							ID_ACUERDO:${
							  this.state.acuerdoSeleccionado.ID_ACUERDO
							},
							ID_PERSONA:${voto.idPersona},
							VOTO:${voto.voto}
						}){ID_ACUERDO}}`
			});
		  }

		  await this.props.cargarAcuerdos(this.props.acta.ID_ACTA);
		  this.props.acuerdos.find((acuerdo,index)=>{
			if(acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO){
			  this.setState({acuerdoSeleccionado:acuerdo,regionSeleccionada:[Regions.row(index)]})
			}
			return acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO
		  })
		  cerrarModal();
		})
		.catch(err => {
		  alert(
			"Ha ocurrido un error al actualizar el acuerdo.Pongase en contacto con el soporte tecnico"
		  );
		});

	};

	const guardarCertificacion=async()=>{
	  let ceritificados=this.state.acuerdosCertificados.filter(acuerdo=>{return acuerdo.certificado===true}).length
	  if(ceritificados===0){
		alert("Debe seleccionar almenos un acuerdo para certificar")
		return
	  }

	  if(!this.state.personaSeleccionada)return

	  //cogemos los acuerdos que estan certidicados
	  let certificados=await this.state.acuerdosCertificados.filter(acuerdo=>{return acuerdo.certificado===true}).map(acuerdo=>{return acuerdo.ID_ACUERDO}).join(",")
	  //si ya tenemos certificacion creada actualizamos
	  if(this.props.acta.certificados){
		
		await client.mutate({
		  mutation:gql`mutation{actualizarActaAcuerdoSolicitudCertificacion(
			ID_ACTA:${this.props.acta.ID_ACTA},
			VALORES:{
			  ID_SOLICITANTE:${this.state.personaSeleccionada.value}
			  FECHA_SOLICITUD:"${moment(this.state.fechaSolicitud).format("YYYY-MM-DD")}"
			  ACUERDOS:"${certificados}"
			}
		  ){ID_SOLICITUD,ID_ACTA}}`

		}).then(async result=>{
			
		  await client.mutate({
			mutation:gql`mutation{actualizarActaAcuerdoSolicitudCertificacionH(
			  ID_SOLICITUD:${result.data.actualizarActaAcuerdoSolicitudCertificacion.ID_SOLICITUD},
			  VALORES:{
				ID_SOLICITANTE:${this.state.personaSeleccionada.value}
				FECHA_SOLICITUD:"${moment(this.state.fechaSolicitud).format("YYYY-MM-DD")}"
				ACUERDOS:"${certificados}"
				FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
				ID_USUARIO:${this.state.idUsuarioActual}
			  }
			){ID_SOLICITUD,ID_ACTA}}`
  
		  }).catch(err=>{
			console.log(err)
			alert("Ha ocurrido un error al actualizar la solicitud.Por favor contacte con el soporte tecnico")
			return
		  })

		  alert("La solicitud se ha actualizado correctamente")
		  await this.props.cargarActas()

		  cerrarModal()
			
		}).catch(err=>{
			alert("Ha ocurrido un error al actualizar la solicitud.Pongase en contacto con el soporte tecnico")
		})

	  }else{

		await client.mutate({
			mutation:gql`mutation{crearActaAcuerdoSolicitudCertificacion(
			VALORES:{
				ID_ACTA:${this.props.acta.ID_ACTA}
				ID_SOLICITANTE:${this.state.personaSeleccionada.value}
				FECHA_SOLICITUD:"${moment(this.state.fechaSolicitud).format("YYYY-MM-DD")}"
				ACUERDOS:"${certificados}"
			}){ID_SOLICITUD,ID_ACTA}}`

		}).then(async result=>{

			await client.mutate({
				mutation:gql`mutation{crearActaAcuerdoSolicitudCertificacionH(
				VALORES:{
					ID_SOLICITUD:${result.data.crearActaAcuerdoSolicitudCertificacion.ID_SOLICITUD}
					ID_ACTA:${this.props.acta.ID_ACTA}
					ID_SOLICITANTE:${this.state.personaSeleccionada.value}
					FECHA_SOLICITUD:"${moment(this.state.fechaSolicitud).format("YYYY-MM-DD")}"
					ACUERDOS:"${certificados}"
					FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
					ID_USUARIO:${this.state.idUsuarioActual}
				}){ID_SOLICITUD,ID_ACTA}}`
		   }).catch(err=>{
			 console.log(err)
			 alert("Ha ocurrido un error al crear la solicitud.Por favor contacte con el soporte tecnico")
			 return
		   })

		   alert("La solicitud se ha creado correctamente")

			await this.props.cargarActas()
			
			cerrarModal()
			
		}).catch(err=>{
			alert("Ha ocurrido un error al crear la solicitud.Pongase en contacto con el soporte tecnico")
		})

	  }

	} 

	const cambiarPestaña = navbarTabId =>
	  this.setState({ pestaña: navbarTabId });

	const cambiarPestañaImpugnacion = navbarTabId =>
	  this.setState({ pestañaImpugnacion: navbarTabId });

	const escogeFechaPresentacion = nuevaFecha => {
	  this.setState({ fechaPresentacion: nuevaFecha });
	};

	const escogeFechaInscripcion = nuevaFecha => {
	  this.setState({ fechaInscripcion: nuevaFecha });
	};

	const escogeFechaEfecto = nuevaFecha => {
	  this.setState({ fechaEfecto: nuevaFecha });
	};

	const escogeFechaSolicitud = nuevaFecha => {
	  this.setState({ fechaSolicitud: nuevaFecha });
	};

	const jsDateFormatter = {
		// note that the native implementation of Date functions differs between browsers
		formatDate: date => moment(date).format("DD/MM/YYYY"),
		parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
		placeholder: "DD/MM/YYYY"
	};

	const abrirDatosInscripcion =async () => {

	  
	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		alert("No tienes permisos para realizar esta acción")
		return
	  }

	  if(!this.state.acuerdoSeleccionado){
		alert("Debe seleccionar un acuerdo")
		return
	  }

    await this.cargarIncripcion();

	  if(!this.state.acuerdoSeleccionado.INSCRIBIBLE_RM){
		  alert("El acuerdo no es susceptible de inscripción en el registro mercantil")
		  return
	  }

	  //si el acuerdo ya tiene inscripcion
	  if(this.state.acuerdoSeleccionado.inscripcion){

		  let calificacionesAcuerdo=this.state.inscripcionOptions.find(inscripcion=>{return inscripcion.value===this.state.acuerdoSeleccionado.inscripcion.ID_CALIFICACION_ACUERDO})
		  let registro=this.state.registroOptions.find(resgistro=>{return resgistro.value===this.state.acuerdoSeleccionado.inscripcion.ID_REGISTRO})

		  this.setState({ 
			  nuevoActivado:false,
			  editarActivado:false,
			  inscripcionActivado:true,
			  certificacionActivado:false,
			  impugnacionActivado:false,
			  fechaPresentacion:new Date(this.state.acuerdoSeleccionado.inscripcion.FECHA_PRESENTACION),
			  fechaInscripcion:new Date(this.state.acuerdoSeleccionado.inscripcion.FECHA_INSCRIPCION),
			  fechaEfecto:new Date(this.state.acuerdoSeleccionado.inscripcion.FECHA_EFECTO),
			  inscripcionSeleccionada:calificacionesAcuerdo,
			  registroSeleccionada:registro,
			  diario:this.state.acuerdoSeleccionado.inscripcion.DIARIO,
			  asiento:this.state.acuerdoSeleccionado.inscripcion.ASIENTO,
			  tomo:this.state.acuerdoSeleccionado.inscripcion.TOMO,
			  folio:this.state.acuerdoSeleccionado.inscripcion.FOLIO,
			  inscripcion:this.state.acuerdoSeleccionado.inscripcion.INSCRIPCION,
			  hoja:this.state.acuerdoSeleccionado.inscripcion.HOJA,
			  modalInscripcion: true
		   })
	  }else{
		  this.setState({ 
			  nuevoActivado:false,
			  editarActivado:false,
			  inscripcionActivado:true,
			  certificacionActivado:false,
			  impugnacionActivado:false,
			  fechaPresentacion:new Date(),
			  fechaInscripcion:new Date(),
			  fechaEfecto:new Date(),
			  inscripcionSeleccionada:null,
			  registroSeleccionada:null,
			  diario:null,
			  asiento:null,
			  tomo:null,
			  folio:null,
			  inscripcion:null,
			  hoja:null,
			  modalInscripcion: true
		   })
	  } 
	}


	const abrirCertificacionAcuerdos = () => {

	  
	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		alert("No tienes permisos para realizar esta acción")
		return
	  }

	  if(this.props.acta.certificados){

		let acuerdos=this.state.acuerdosCertificados
		let persona=this.state.personas.find(persona=>{return persona.value===this.props.acta.certificados.ID_SOLICITANTE})
		let acuerdosCertificados=null
    if (this.props.acta.certificados.ACUERDOS) {
      acuerdosCertificados=this.props.acta.certificados.ACUERDOS.split(",")
    }
		let sinImpugnar=[]
		//comprobar que no esta impugnado
		// eslint-disable-next-line no-unused-vars
		let index=0
		if (acuerdosCertificados) {
      for(let ac of acuerdosCertificados ){

        let acuerdo=this.state.acuerdos.find(acu=>{return acu.ID_ACUERDO===parseInt(ac)})
        //si esta impugnado, lo sacamos del array
        if(!acuerdo){
        sinImpugnar.push(ac)
        }

        index++
      }
    }

		for(let acuerdo of sinImpugnar){
		  let acertificar=acuerdos.find(ac=>{return ac.ID_ACUERDO===parseInt(acuerdo)})
		  acertificar.certificado=true
		}

		this.setState({
		  personaSeleccionada:persona,
		  fechaSolicitud:new Date(this.props.acta.certificados.FECHA_SOLICITUD),
		})

	  }else{
		  let acuerdos=this.state.acuerdosCertificados
		  for(let acuerdo of acuerdos){
			acuerdo.certificado=false
		  }
		  this.setState({
			personaSeleccionada:null,
			fechaSolicitud:new Date(),
		  })
	  }
	  this.setState({               
		nuevoActivado:false,
		editarActivado:false,
		inscripcionActivado:false,
		certificacionActivado:true,
		impugnacionActivado:false,
		modalCertificacion: true });
	};

	const abrirModalNuevoRegistro = () => {

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert("No tienes permisos para realizar esta acción")
		  return
	  }

	  this.setState({ modalNuevoRegistro: true });
	};

	const abrirDatosImpugnacion = async() => {

	  
	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		alert("No tienes permisos para realizar esta acción")
		return
	  }


	  if(!this.state.acuerdoSeleccionado){
		alert("Debe seleccionar un acuerdo.")
		return
	  }

    await this.cargarDatosImpugnacion();

	  await this.setState({ 
		nuevoActivado:false,
		editarActivado:false,
		inscripcionActivado:false,
		certificacionActivado:false,
		impugnacionActivado:true,
		modalImpugnacion: true,
		pestañaImpugnacion:"datos-impugnacion"
	  });


	};

	const seleccionInscripcion = value => {
	  this.setState({ inscripcionSeleccionada: value });
	};

	const seleccionRegistro = value => {
	  this.setState({ registroSeleccionada: value });
	};

	const cambiarDiario = e => {
	  this.setState({ diario: e.currentTarget.value });
	};

	const cambiarAsiento = e => {
	  this.setState({ asiento: e.currentTarget.value });
	};

	const cambiarTomo = e => {
	  this.setState({ tomo: e.currentTarget.value });
	};

	const cambiarFolio = e => {
	  this.setState({ folio: e.currentTarget.value });
	};

	const cambiarInscripcion = e => {
	  this.setState({ inscripcion: e.currentTarget.value });
	};

	const cambiarHoja = e => {
	  this.setState({ hoja: e.currentTarget.value });
	};

	const seleccionarPersona = value => {
	  this.setState({ personaSeleccionada: value });
	};

	const seleccionarAcuerdo = value => {
	  if (value.length === 0) return;

	  let seleccionada = this.props.acuerdos[value[0].rows[0]];
	  this.setState({
		acuerdoSeleccionado: seleccionada,
		crearActaAcuerdo:value,
		regionSeleccionada:value
	  });
	};

	const cambiarNuevoRegistro=(ev)=>{
	  this.setState({registroNuevo:ev.currentTarget.value})
	}

	const guardarInscripcion=()=>{

	  if(!this.state.inscripcionSeleccionada){return}
	  if(!this.state.registroSeleccionada){return}

	  //si trae datos de inscripcion ejetucamos actualizacion
	  //de lo contrario lo creamos.
	  if(this.state.acuerdoSeleccionado.inscripcion){

		  //creamos la inscripcion
		  client.mutate({
			  mutation:gql`mutation{actualizarActasAcuerdosInscripcionRm(
				  ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO},
				  VALORES:{  
				  FECHA_PRESENTACION:"${moment(this.state.fechaPresentacion).format("YYYY-MM-DD")}",
				  ID_CALIFICACION_ACUERDO:${this.state.inscripcionSeleccionada.value},
				  FECHA_INSCRIPCION:"${moment(this.state.fechaInscripcion).format("YYYY-MM-DD")}",
				  FECHA_EFECTO:"${moment(this.state.fechaEfecto).format("YYYY-MM-DD")}",
				  ID_REGISTRO:${this.state.registroSeleccionada.value},
				  DIARIO:${this.state.diario?'"'+this.state.diario+'"':null},
				  ASIENTO:${this.state.asiento?'"'+this.state.asiento+'"':null},
				  TOMO:${this.state.tomo?'"'+this.state.tomo+'"':null},
				  FOLIO:${this.state.folio?'"'+this.state.folio+'"':null},
				  INSCRIPCION:${this.state.inscripcion?'"'+this.state.inscripcion+'"':null},
				  HOJA:${this.state.hoja?'"'+this.state.hoja+'"':null},
			  }){ID_ACUERDO}}`

		  }).then(async result=>{

			
			client.mutate({
			  mutation:gql`mutation{actualizarActasAcuerdosInscripcionRmH(
				ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO},
				VALORES:{  
				  FECHA_PRESENTACION:"${moment(this.state.fechaPresentacion).format("YYYY-MM-DD")}",
				  ID_CALIFICACION_ACUERDO:${this.state.inscripcionSeleccionada.value},
				  FECHA_INSCRIPCION:"${moment(this.state.fechaInscripcion).format("YYYY-MM-DD")}",
				  FECHA_EFECTO:"${moment(this.state.fechaEfecto).format("YYYY-MM-DD")}",
				  ID_REGISTRO:${this.state.registroSeleccionada.value},
				  DIARIO:${this.state.diario?'"'+this.state.diario+'"':null},
				  ASIENTO:${this.state.asiento?'"'+this.state.asiento+'"':null},
				  TOMO:${this.state.tomo?'"'+this.state.tomo+'"':null},
				  FOLIO:${this.state.folio?'"'+this.state.folio+'"':null},
				  INSCRIPCION:${this.state.inscripcion?'"'+this.state.inscripcion+'"':null},
				  HOJA:${this.state.hoja?'"'+this.state.hoja+'"':null},
				  FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
				  ID_USUARIO:${this.state.idUsuarioActual}
			  }){ID_ACUERDO}}`
			}).catch(err=>{
			  alert("Ha ocurrido un error al crear la inscripción.Pongase en contacto con el soporte tecnico")
			})
			  
			  alert("La inscripción se ha actualizado correctamente")
		  
			  await this.cargarAcuerdos(this.props.acta.ID_ACTA)

			  //reseleccionamos el acuerdo
			  let creado=this.state.acuerdos.find(acuerdo=>{return acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO})
			  this.setState({acuerdoSeleccionado:creado})

			  cerrarModal()
			  
		  }).catch(err=>{
			  console.log(err)
			  alert("Ha ocurrido un error al actualizar la inscripción.Pongase en contacto con el soporte tecnico")
		  })

	  }else{

		  //creamos la inscripcion
		  client.mutate({
			  mutation:gql`mutation{crearActasAcuerdosInscripcionRm(VALORES:{  
				  ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO},        
				  FECHA_PRESENTACION:"${moment(this.state.fechaPresentacion).format("YYYY-MM-DD")}",
				  ID_CALIFICACION_ACUERDO:${this.state.inscripcionSeleccionada.value},
				  FECHA_INSCRIPCION:"${moment(this.state.fechaInscripcion).format("YYYY-MM-DD")}",
				  FECHA_EFECTO:"${moment(this.state.fechaEfecto).format("YYYY-MM-DD")}",
				  ID_REGISTRO:${this.state.registroSeleccionada.value},
				  DIARIO:${this.state.diario?'"'+this.state.diario+'"':null},
				  ASIENTO:${this.state.asiento?'"'+this.state.asiento+'"':null},
				  TOMO:${this.state.tomo?'"'+this.state.tomo+'"':null},
				  FOLIO:${this.state.folio?'"'+this.state.folio+'"':null},
				  INSCRIPCION:${this.state.inscripcion?'"'+this.state.inscripcion+'"':null},
				  HOJA:${this.state.hoja?'"'+this.state.hoja+'"':null},
			  }){ID_ACUERDO}}`

		  }).then(async result=>{
			  

			  client.mutate({
				mutation:gql`mutation{crearActasAcuerdosInscripcionRmH(VALORES:{  
					ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO},        
					FECHA_PRESENTACION:"${moment(this.state.fechaPresentacion).format("YYYY-MM-DD")}",
					ID_CALIFICACION_ACUERDO:${this.state.inscripcionSeleccionada.value},
					FECHA_INSCRIPCION:"${moment(this.state.fechaInscripcion).format("YYYY-MM-DD")}",
					FECHA_EFECTO:"${moment(this.state.fechaEfecto).format("YYYY-MM-DD")}",
					ID_REGISTRO:${this.state.registroSeleccionada.value},
					DIARIO:${this.state.diario?'"'+this.state.diario+'"':null},
					ASIENTO:${this.state.asiento?'"'+this.state.asiento+'"':null},
					TOMO:${this.state.tomo?'"'+this.state.tomo+'"':null},
					FOLIO:${this.state.folio?'"'+this.state.folio+'"':null},
					INSCRIPCION:${this.state.inscripcion?'"'+this.state.inscripcion+'"':null},
					HOJA:${this.state.hoja?'"'+this.state.hoja+'"':null},
					FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
					ID_USUARIO:${this.state.idUsuarioActual}
				}){ID_ACUERDO}}`
			  }).catch(err=>{
				alert("Ha ocurrido un error al crear la inscripción.Pongase en contacto con el soporte tecnico")
			  })

			  alert("La inscripción se ha creado correctamente")
		  
			  await this.cargarAcuerdos(this.props.acta.ID_ACTA)
			  this.state.acuerdos.find((acuerdo,index)=>{
				if(acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO){
				  this.setState({acuerdoSeleccionado:acuerdo,regionSeleccionada:[Regions.row(index)]})
				}
				return acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO
			  })

			  cerrarModal()
			  
		  }).catch(err=>{
			console.log(err)
			  alert("Ha ocurrido un error al crear la inscripción.Pongase en contacto con el soporte tecnico")
		  })
	  }
  }

  const guardarRegistro=()=>{

	  if(!this.state.registroNuevo)return

	  //comprobar que no exista ya un registro 
	  let existe=this.state.registroOptions.find(reg=>{return reg.label===this.state.registroNuevo})
	  if(existe){
		  alert("Ya existe un registro con esta descripción")
		  return
	  }

	  client.mutate({
		  mutation:gql`mutation{crearRegistro(VALORES:{          
			  DESCRIPCION:"${this.state.registroNuevo}"
		  }){ID_REGISTRO,DESCRIPCION}}`
	  }).then(async(result)=>{

		  await this.cargarRegistros()

		  //seleccionamos el registro creado
		  let creado=await this.state.registroOptions.find(reg=>{return reg.value===result.data.crearRegistro.ID_REGISTRO})
		  this.setState({registroSeleccionada:creado})
		  cerrarModal2()

	  }).catch(err=>{
		  alert("Ha ocurrido un error al crear el registro.Pongase en contacto con el soporte técnico.")
		  console.log(err)
	  })

	}

	const cerrarModal2=()=>{
	  this.setState({modalNuevoRegistro: false})
	}

	const guardarImpugnacion=()=>{


	  if(!this.datosImpugnacion.state.textoImpugnacion){
		this.setState({pestañaImpugnacion:"datos-impugnacion"})
		return
	  }
	  if(this.datosAnulacion && this.datosAnulacion.state.fechaFirmeza && !this.datosAnulacion.state.registroSeleccionado){
		this.setState({pestañaImpugnacion:"anulacion"})
		return
	  }
	  if(this.datosAnulacion && !this.datosAnulacion.state.fechaFirmeza && this.datosAnulacion.state.registroSeleccionado){
		this.setState({pestañaImpugnacion:"anulacion"})
		return
	  }

	  //si el acuerdo no tiene datos de impugnacion, creamos una nueva
	  if(!this.state.acuerdoSeleccionado.datosImpugnacion){

		client.mutate({
		  mutation:gql`mutation{crearActaAcuerdoImpugnacion(
			VALORES:{
			  ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}
			  TEXTO:"""${this.datosImpugnacion.state.textoImpugnacion}"""
			  RESOLUCION:${this.datosImpugnacion.state.textoResolucion?'"""'+this.datosImpugnacion.state.textoResolucion+'"""':null}
			  ANULACION:${this.datosImpugnacion.state.textoAnulacion?'"""'+this.datosImpugnacion.state.textoAnulacion+'"""':null}
			  SUBSANACION:${this.datosImpugnacion.state.textoSubsanacion?'"""'+this.datosImpugnacion.state.textoSubsanacion+'"""':null}
			  FECHA_EFECTO:"${moment(this.datosImpugnacion.state.fechaEfectoImpugnacion).format("YYYY-MM-DD")}"
			}
		  ){ID_IMPUGNACION,ID_ACUERDO}}`
  
		}).then(async result=>{
			
			alert("La impugnación se ha creado correctamente")

			client.mutate({
			  mutation:gql`mutation{crearActaAcuerdoImpugnacionH(
				VALORES:{
				  ID_IMPUGNACION:${result.data.crearActaAcuerdoImpugnacion.ID_IMPUGNACION},
				  ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}
				  TEXTO:"""${this.datosImpugnacion.state.textoImpugnacion}"""
				  RESOLUCION:${this.datosImpugnacion.state.textoResolucion?'"""'+this.datosImpugnacion.state.textoResolucion+'"""':null}
				  ANULACION:${this.datosImpugnacion.state.textoAnulacion?'"""'+this.datosImpugnacion.state.textoAnulacion+'"""':null}
				  SUBSANACION:${this.datosImpugnacion.state.textoSubsanacion?'"""'+this.datosImpugnacion.state.textoSubsanacion+'"""':null}
				  FECHA_EFECTO:"${moment(this.datosImpugnacion.state.fechaEfectoImpugnacion).format("YYYY-MM-DD")}"
				  FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
				  ID_USUARIO:${this.state.idUsuarioActual}
				}
			  ){ID_IMPUGNACION,ID_ACUERDO}}`
	  
			}).catch(err=>{
			  console.log(err)
			  alert("Ha ocurrido un error al crear la impugnacion.Pongase en contacto con el soporte tecnico")
			})

			await this.cargarAcuerdos(this.props.acta.ID_ACTA)
			await this.state.acuerdos.find((acuerdo,index)=>{
			  if(acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO){
				this.setState({acuerdoSeleccionado:acuerdo,regionSeleccionada:[Regions.row(index)]})
			  }
			  return acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO
			})
			cerrarModal()
			
		}).catch(err=>{
			alert("Ha ocurrido un error al crear la impugnación.Pongase en contacto con el soporte tecnico")
		})

	  }else{

		client.mutate({
		  mutation:gql`mutation{actualizarActaAcuerdoImpugnacion(
			ID_IMPUGNACION:${this.state.acuerdoSeleccionado.datosImpugnacion.ID_IMPUGNACION},
			VALORES:{
			  TEXTO:"""${this.datosImpugnacion.state.textoImpugnacion}"""
			  RESOLUCION:${this.datosImpugnacion.state.textoResolucion?'"""'+this.datosImpugnacion.state.textoResolucion+'"""':null}
			  ANULACION:${this.datosImpugnacion.state.textoAnulacion?'"""'+this.datosImpugnacion.state.textoAnulacion+'"""':null}
			  SUBSANACION:${this.datosImpugnacion.state.textoSubsanacion?'"""'+this.datosImpugnacion.state.textoSubsanacion+'"""':null}
			  FECHA_EFECTO:"${moment(this.datosImpugnacion.state.fechaEfectoImpugnacion).format("YYYY-MM-DD")}"
			}
		  ){ID_IMPUGNACION,ID_ACUERDO}}`
  
		}).then(async result=>{
			
			alert("La impugnación se ha actualizado correctamente")

			client.mutate({
			  mutation:gql`mutation{actualizarActaAcuerdoImpugnacionH(
				ID_IMPUGNACION:${this.state.acuerdoSeleccionado.datosImpugnacion.ID_IMPUGNACION},
				VALORES:{
				  TEXTO:"""${this.datosImpugnacion.state.textoImpugnacion}"""
				  RESOLUCION:${this.datosImpugnacion.state.textoResolucion?'"""'+this.datosImpugnacion.state.textoResolucion+'"""':null}
				  ANULACION:${this.datosImpugnacion.state.textoAnulacion?'"""'+this.datosImpugnacion.state.textoAnulacion+'"""':null}
				  SUBSANACION:${this.datosImpugnacion.state.textoSubsanacion?'"""'+this.datosImpugnacion.state.textoSubsanacion+'"""':null}
				  FECHA_EFECTO:"${moment(this.datosImpugnacion.state.fechaEfectoImpugnacion).format("YYYY-MM-DD")}"
				  FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}"
				  ID_USUARIO:${this.state.idUsuarioActual}
				}
			  ){ID_IMPUGNACION,ID_ACUERDO}}`
	  
			}).catch(err=>{
			  console.log(err)
			  alert("Ha ocurrido un error al actualizar la impugnacion.Pongase en contacto con el soporte tecnico")
			})
			
			//si se ha seleccionado la fecha firmeza de la anulacion,
			//damos de alta la anulacion
			if(!this.state.acuerdoSeleccionado.datosImpugnacion.datosAnulacion && this.datosAnulacion.state.fechaFirmeza){

			  await client.mutate({
				mutation:gql`mutation{crearActaAcuerdoImpugnacionAnulacion(
				  VALORES:{
					ID_IMPUGNACION:${this.state.acuerdoSeleccionado.datosImpugnacion.ID_IMPUGNACION},
					FECHA_FIRMEZA:"${moment(this.datosAnulacion.state.fechaFirmeza).format("YYYY-MM-DD")}",
					ID_REGISTRO:${this.datosAnulacion.state.registroSeleccionado.value},
					DIARIO:${this.datosAnulacion.state.diario?'"'+this.datosAnulacion.state.diario+'"':null},
					ASIENTO:${this.datosAnulacion.state.asiento?'"'+this.datosAnulacion.state.asiento+'"':null},
					TOMO:${this.datosAnulacion.state.tomo?'"'+this.datosAnulacion.state.tomo+'"':null},
					FOLIO:${this.datosAnulacion.state.folio?'"'+this.datosAnulacion.state.folio+'"':null},
					INSCRIPCION:${this.datosAnulacion.state.inscripcion?'"'+this.datosAnulacion.state.inscripcion+'"':null},
					HOJA:${this.datosAnulacion.state.hoja?'"'+this.datosAnulacion.state.hoja+'"':null},
				  }
				){ID_IMPUGNACION,ID_ANULACION}}`
		
			  }).then(async(result)=>{

				await client.mutate({
				  mutation:gql`mutation{crearActaAcuerdoImpugnacionAnulacionH(
					VALORES:{
					  ID_ANULACION:${result.data.crearActaAcuerdoImpugnacionAnulacion.ID_ANULACION},
					  ID_IMPUGNACION:${this.state.acuerdoSeleccionado.datosImpugnacion.ID_IMPUGNACION},
					  FECHA_FIRMEZA:"${moment(this.datosAnulacion.state.fechaFirmeza).format("YYYY-MM-DD")}",
					  ID_REGISTRO:${this.datosAnulacion.state.registroSeleccionado.value},
					  DIARIO:${this.datosAnulacion.state.diario?'"'+this.datosAnulacion.state.diario+'"':null},
					  ASIENTO:${this.datosAnulacion.state.asiento?'"'+this.datosAnulacion.state.asiento+'"':null},
					  TOMO:${this.datosAnulacion.state.tomo?'"'+this.datosAnulacion.state.tomo+'"':null},
					  FOLIO:${this.datosAnulacion.state.folio?'"'+this.datosAnulacion.state.folio+'"':null},
					  INSCRIPCION:${this.datosAnulacion.state.inscripcion?'"'+this.datosAnulacion.state.inscripcion+'"':null},
					  HOJA:${this.datosAnulacion.state.hoja?'"'+this.datosAnulacion.state.hoja+'"':null},
					  FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
					  ID_USUARIO:${this.state.idUsuarioActual}
					}
				  ){ID_IMPUGNACION,ID_ANULACION}}`
		  
				})

				this.state.acuerdos.find((acuerdo,index)=>{
				  if(acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO){
					this.setState({acuerdoSeleccionado:acuerdo,regionSeleccionada:[Regions.row(index)]})
				  }
				  return acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO
				})
			  })
			  
			}else if(this.state.acuerdoSeleccionado.datosImpugnacion.datosAnulacion && this.datosAnulacion.state.fechaFirmeza){

			  await client.mutate({
				mutation:gql`mutation{actualizarActaAcuerdoImpugnacionAnulacion(
				  ID_ANULACION:${this.state.acuerdoSeleccionado.datosImpugnacion.datosAnulacion.ID_ANULACION},
				  VALORES:{
					FECHA_FIRMEZA:"${moment(this.datosAnulacion.state.fechaFirmeza).format("YYYY-MM-DD")}",
					ID_REGISTRO:${this.datosAnulacion.state.registroSeleccionado.value},
					DIARIO:${this.datosAnulacion.state.diario?'"'+this.datosAnulacion.state.diario+'"':null},
					ASIENTO:${this.datosAnulacion.state.asiento?'"'+this.datosAnulacion.state.asiento+'"':null},
					TOMO:${this.datosAnulacion.state.tomo?'"'+this.datosAnulacion.state.tomo+'"':null},
					FOLIO:${this.datosAnulacion.state.folio?'"'+this.datosAnulacion.state.folio+'"':null},
					INSCRIPCION:${this.datosAnulacion.state.inscripcion?'"'+this.datosAnulacion.state.inscripcion+'"':null},
					HOJA:${this.datosAnulacion.state.hoja?'"'+this.datosAnulacion.state.hoja+'"':null},
				  }
				){ID_IMPUGNACION,ID_ANULACION}}`
		
			  }).then(async()=>{

				await client.mutate({
				  mutation:gql`mutation{actualizarActaAcuerdoImpugnacionAnulacionH(
					ID_ANULACION:${this.state.acuerdoSeleccionado.datosImpugnacion.datosAnulacion.ID_ANULACION},
					VALORES:{
					  FECHA_FIRMEZA:"${moment(this.datosAnulacion.state.fechaFirmeza).format("YYYY-MM-DD")}",
					  ID_REGISTRO:${this.datosAnulacion.state.registroSeleccionado.value},
					  DIARIO:${this.datosAnulacion.state.diario?'"'+this.datosAnulacion.state.diario+'"':null},
					  ASIENTO:${this.datosAnulacion.state.asiento?'"'+this.datosAnulacion.state.asiento+'"':null},
					  TOMO:${this.datosAnulacion.state.tomo?'"'+this.datosAnulacion.state.tomo+'"':null},
					  FOLIO:${this.datosAnulacion.state.folio?'"'+this.datosAnulacion.state.folio+'"':null},
					  INSCRIPCION:${this.datosAnulacion.state.inscripcion?'"'+this.datosAnulacion.state.inscripcion+'"':null},
					  HOJA:${this.datosAnulacion.state.hoja?'"'+this.datosAnulacion.state.hoja+'"':null},
					  FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
					  ID_USUARIO:${this.state.idUsuarioActual}
					}
				  ){ID_IMPUGNACION,ID_ANULACION}}`
		  
				})

				this.state.acuerdos.find((acuerdo,index)=>{
				  if(acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO){
					this.setState({acuerdoSeleccionado:acuerdo,regionSeleccionada:[Regions.row(index)]})
				  }
				  return acuerdo.ID_ACUERDO===this.state.acuerdoSeleccionado.ID_ACUERDO
				})
			  })

			}

			await this.cargarAcuerdos(this.props.acta.ID_ACTA)
			cerrarModal()
			
		}).catch(err=>{
			console.log(err)
			alert("Ha ocurrido un error al actualizar la impugnación.Pongase en contacto con el soporte tecnico")
		})

	  }

	}


  const cerrarModalImprimir= () => {
      this.setState({
          modalImprimir:false,
      })
  }
  
	
	return (
	  <>
		<div style={{display:"none"}}>
		  <this.certificacionAcuerdo/>
		</div>
    <Dialog 
      transitionDuration={400}
      autoFocus={true}
      enforceFocus={true}
      icon="print"
      onClose={cerrarModalImprimir}
      title="Imprimir certificado de acuerdo"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isCloseButtonShown={true}
      usePortal={true}
      isOpen={this.state.modalImprimir}
      style={styles.dialogL}
    >
        {!this.state.cargandoImprimir?
                <div style={{ width: '100%', height: '500px'}} id='generar-pdf'>
                </div>
                :
                    <Spinner />
            }

    </Dialog>
		<Dialog
		  transitionDuration={400}
		  autoFocus={true}
		  enforceFocus={true}
		  icon="warning-sign"
		  onClose={cerrarSoloEsteModal}
		  title="LexControl"
		  canEscapeKeyClose={true}
		  canOutsideClickClose={true}a
		  isCloseButtonShown={true}
		  usePortal={true}
		  isOpen={this.state.dialogTextoAcuerdo}
		>
		  <div className={Classes.DIALOG_BODY}>
			<TextArea fill={true} disabled value={this.state.textoAcuerdo} />
		  </div>
		  <div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			  <AnchorButton
				intent={Intent.PRIMARY}
				onClick={cerrarSoloEsteModal}
			  >
				CERRAR
			  </AnchorButton>
			</div>
		  </div>
		</Dialog>

		<Dialog
		  transitionDuration={400}
		  autoFocus={true}
		  enforceFocus={true}
		  icon="warning-sign"
		  onClose={cerrarModal}
		  title="Datos de la inscripción en el registro mercantil"
		  canEscapeKeyClose={true}
		  canOutsideClickClose={true}
		  isCloseButtonShown={true}
		  usePortal={true}
		  onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.props.recargar()}}
		  isOpen={this.state.modalInscripcion}
		  className="dialog-ancho"
		>
		  <div className={Classes.DIALOG_BODY}>
			<Card
			  interactive={true}
			  elevation={Elevation.TWO}
			  className="pixelAlrededorGrande "
			  id="dialogo-inscripcion"
			>
			  <FormGroup label="fecha de presentación:" inline={true}>
				<DateInput minDate={new Date(1900, 1 ,1)}
				  {...jsDateFormatter}
				  onChange={escogeFechaPresentacion}
				  value={this.state.fechaPresentacion}
				/>
			  </FormGroup>
			  <FormGroup label="fecha de inscripción:" inline={true}>
				<DateInput minDate={new Date(1900, 1 ,1)}
				  {...jsDateFormatter}
				  onChange={escogeFechaInscripcion}
				  value={this.state.fechaInscripcion}
				/>
			  </FormGroup>
			  <FormGroup label="fecha de efecto:" inline={true}>
				<DateInput minDate={new Date(1900, 1 ,1)}
				  {...jsDateFormatter}
				  onChange={escogeFechaEfecto}
				  value={this.state.fechaEfecto}
				/>
			  </FormGroup>
			  <FormGroup label="Calificación de la inscripción:">
				<div style={!this.state.inscripcionSeleccionada?{border:"1px solid red"}:{}}>
				  <Select
					options={this.state.inscripcionOptions}
					className="pixelAlrededor"
					onChange={seleccionInscripcion}
					backspaceRemovesValue={true}
					isClearable={true}
					value={this.state.inscripcionSeleccionada}
				  />
				</div>
			  </FormGroup>
			  <FormGroup label="Registro:">
				<div style={!this.state.registroSeleccionada?{border:"1px solid red"}:{}}>
				  <Select
					options={this.state.registroOptions}
					className="pixelAlrededor"
					onChange={seleccionRegistro}
					backspaceRemovesValue={true}
					isClearable={true}
					value={this.state.registroSeleccionada}
				  />
				</div>
				<Tooltip content="Añadir nuevo registro">
				  <Button icon="add" onClick={abrirModalNuevoRegistro} />
				</Tooltip>
			  </FormGroup>
			  <FormGroup label="Diario:">
				<InputGroup
				  value={this.state.diario}
				  onChange={cambiarDiario}
				  intent={"primary"}
				  fill={true}
				/>
			  </FormGroup>
			  <FormGroup label="Asiento:">
				<InputGroup
				  value={this.state.asiento}
				  onChange={cambiarAsiento}
				  intent={"primary"}
				  fill={true}
				/>
			  </FormGroup>
			  <FormGroup label="Tomo:">
				<InputGroup
				  value={this.state.tomo}
				  onChange={cambiarTomo}
				  intent={"primary"}
				  fill={true}
				/>
			  </FormGroup>
			  <FormGroup label="Folio:">
				<InputGroup
				  value={this.state.folio}
				  onChange={cambiarFolio}
				  intent={"primary"}
				  fill={true}
				/>
			  </FormGroup>
			  <FormGroup label="Inscripcion:">
				<InputGroup
				  value={this.state.inscripcion}
				  onChange={cambiarInscripcion}
				  intent={"primary"}
				  fill={true}
				/>
			  </FormGroup>
			  <FormGroup label="Hoja:">
				<InputGroup
				  value={this.state.hoja}
				  onChange={cambiarHoja}
				  intent={"primary"}
				  fill={true}
				/>
			  </FormGroup>
			</Card>
		  </div>
		  <div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			  <AnchorButton intent={Intent.PRIMARY} onClick={guardarInscripcion}>
				Aceptar
			  </AnchorButton>
			  <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
				Cancelar
			  </AnchorButton>
			</div>
		  </div>
		</Dialog>

		<Dialog
		  transitionDuration={400}
		  autoFocus={true}
		  enforceFocus={true}
		  icon="warning-sign"
		  onClose={cerrarModal2}
		  title="Nuevo registro"
		  canEscapeKeyClose={true}
		  canOutsideClickClose={true}
		  isCloseButtonShown={true}
		  usePortal={true}
		  isOpen={this.state.modalNuevoRegistro}
		>
		  <div className={Classes.DIALOG_BODY}>
			<FormGroup label="Introduzca el nuevo registro:" inline={true}>
			  <InputGroup
				value={this.state.registroNuevo}
				onChange={cambiarNuevoRegistro}
				intent={this.state.registroNuevo ? "primary" : "danger"}
			  />
			</FormGroup>
		  </div>
		  <div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			  <AnchorButton intent={Intent.PRIMARY} onClick={guardarRegistro}>
				Aceptar
			  </AnchorButton>
			  <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal2}>
				Cancelar
			  </AnchorButton>
			</div>
		  </div>
		</Dialog>

		<Dialog
		  transitionDuration={400}
		  autoFocus={true}
		  enforceFocus={true}
		  icon="warning-sign"
		  onClose={cerrarModal}
		  title="Certificación de acuerdos"
		  canEscapeKeyClose={true}
		  canOutsideClickClose={true}
		  isCloseButtonShown={true}
		  usePortal={true}
		  onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.props.recargar()}}
		  isOpen={this.state.modalCertificacion}
		  className="dialog-ancho"
		>
		  <div className={Classes.DIALOG_BODY}>
			<Card interactive={true} elevation={Elevation.TWO}>
			  <FormGroup label="Solicitante:">
				<div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
				  <Select
					options={this.state.personas}
					className="pixelAlrededor"
					backspaceRemovesValue={true}
					onChange={seleccionarPersona}
					isClearable={true}
					value={this.state.personaSeleccionada}
				  />
				</div>
			  </FormGroup>
			  <FormGroup label="fecha de la sollicitud:" inline={true}>
				<DateInput minDate={new Date(1900, 1 ,1)}
				  {...jsDateFormatter}
				  onChange={escogeFechaSolicitud}
				  value={this.state.fechaSolicitud}
				/>
			  </FormGroup>
			  <FormGroup label="Acuerdos certificados:">
				<div id="tabla-certificados">
				  <Table
					enableRowHeader={false}
					defaultRowHeight={40}
					numRows={this.state.acuerdosCertificados.length}
					selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]}
          ref={input => {this.tablaCert = input;}}
          columnWidths={this.state.columnWidthsC} onCompleteRender={() => {
            if (!this.state.resizedC) {
                this.resizeColumnsC();
            }
          }}
				  >
					<Column
					  name="Número"
					  cellRenderer={cellRendererNumeroCertificar}
					/>
					<Column
					  name="¿Certificado?"
					  cellRenderer={cellRendererCertificadoSwitch}
					/>
					<Column
					  name="Texto (Doble click para expandir)"
					  cellRenderer={cellRendererTextoAcuerdo}
					/>
				  </Table>
				</div>

			  </FormGroup>

			  <Collapse isOpen={this.state.abierto1}>
				<Card>
				  <TextArea
					value={
					  this.state.acuerdoSeleccionado
						? this.state.acuerdoSeleccionado.texto
						: ""
					}
				  />
				</Card>
			  </Collapse>
			</Card>
		  </div>
		  <div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			  <AnchorButton intent={Intent.PRIMARY} onClick={guardarCertificacion}>
				Aceptar
			  </AnchorButton>
			  <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
				Cancelar
			  </AnchorButton>
			</div>
		  </div>
		</Dialog>

		<Dialog
		  transitionDuration={400}
		  autoFocus={true}
		  enforceFocus={true}
		  icon="warning-sign"
		  onClose={cerrarModal}
		  title="Datos de impugnación del acuerdo"
		  canEscapeKeyClose={true}
		  canOutsideClickClose={true}
		  isCloseButtonShown={true}
		  usePortal={true}
		  onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.props.recargar()}}
		  isOpen={this.state.modalImpugnacion}
		  className="dialog-ancho"
		>
		  <div id="dialogoImpugnacion" className={Classes.DIALOG_BODY}>
			<Tabs 
				id="datos-impugnaion"
				animate={this.animacion} 
				onChange={cambiarPestañaImpugnacion} 
				selectedTabId={this.state.pestañaImpugnacion}
				//renderActiveTabPanelOnly={true}
			>
				<Tab id="datos-impugnacion" title="Datos del acta" panel={
					<DatosImpugnacion
					datosImpugnacion={this.state.acuerdoSeleccionado?(this.state.acuerdoSeleccionado.datosImpugnacion?this.state.acuerdoSeleccionado.datosImpugnacion:null):null}
					ref={(input) => { this.datosImpugnacion= input; }} />
				} />

				  {this.state.acuerdoSeleccionado?this.state.acuerdoSeleccionado.datosImpugnacion?
					<Tab id="anulacion"  title="Anulación"  panel={<Anulacion datosAnulacion={this.state.acuerdoSeleccionado?(this.state.acuerdoSeleccionado.datosImpugnacion?this.state.acuerdoSeleccionado.datosImpugnacion.datosAnulacion:null):null} editable={this.props.editable} ref={(input) => { this.datosAnulacion= input; }}  />} />
				  :<></>:<></>}
				  {this.state.acuerdoSeleccionado?this.state.acuerdoSeleccionado.datosImpugnacion?
					<Tab id="adjuntos" title="Archivos adjuntos"  panel={<ArchivosAdjuntosImpugnaciones editable={this.props.editable} acuerdo={this.state.acuerdoSeleccionado?this.state.acuerdoSeleccionado:null} />} />   
				  :<></>:<></>}
		 
			</Tabs>
		  </div>
		  <div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			  <AnchorButton intent={Intent.PRIMARY} onClick={guardarImpugnacion}>
				Aceptar
			  </AnchorButton>
			  <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModal}>
				Cancelar
			  </AnchorButton>
			</div>
		  </div>
		</Dialog>

		<Dialog
		  autoFocus={true}
		  enforceFocus={true}
		  icon="info-sign"
		  onClose={cerrarModal}
		  title="Propiedades del acuerdo."
		  canEscapeKeyClose={true}
		  canOutsideClickClose={true}
		  isCloseButtonShown={true}
		  usePortal={true}
		  isOpen={this.state.modalNuevoAbierto}
		  onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.props.recargar()}}
		  className="dialog-extra-ancho"
		>
		  <Tabs
			id="propiedades-acta"
			animate={this.animacion}
			onChange={cambiarPestaña}
			selectedTabId={this.state.pestaña}
			//renderActiveTabPanelOnly={true}
		  >
			<Tab
			  id="datos-acuerdo"
			  title="Datos del acuerdo"
			  panel={
				<DatosAcuerdo
				  acta={this.props.acta}
				  votos={this.Votos ? this.Votos.state.votos : null}
				  editable={this.props.editable}
				  numeroAcuerdo={this.props.acuerdos.length + 1}
				  acuerdo={this.state.acuerdoSeleccionado}
				  ref={input => {
					this.datosAcuerdos = input;
				  }}
				/>
			  }
			/>
			<Tab
			  id="texto"
			  title="Texto"
			  panel={
				<TextoActas
				  acta={this.props.acta}
				  editable={this.props.editable}
				  ref={input => {
					this.textoActas = input;
				  }}
				  acuerdo={this.state.acuerdoSeleccionado}
				/>
			  }
			/>
			<Tab
			  id="votos"
			  title="Votos"
			  panel={
				<Votos
				  acta={this.props.acta}
				  editable={this.props.editable}
				  DatosAcuerdo={this.state.votacion}
				  ref={input => {
					this.Votos = input;
				  }}
				  acuerdo={this.state.acuerdoSeleccionado}
				/>
			  }
			/>
      <Tab
			  id="control_seguimiento"
			  title="Control y Seguimiento"
			  panel={
				<ControlAcuerdo
				  acta={this.props.acta}
				  editable={this.props.editable}
				  ref={input => {
					this.ControlAcuerdo = input;
				  }}
				  acuerdo={this.state.acuerdoSeleccionado}
				/>
			  }
			/>
			{this.state.acuerdoSeleccionado ? (
			  <Tab
				id="adjuntos"
				title="Archivos adjuntos"
				panel={<ArchivosAdjuntosAcuerdos editable={this.props.editable} acuerdo={this.state.acuerdoSeleccionado?this.state.acuerdoSeleccionado:null} />}
			  />
			) : null}
		  </Tabs>
		  <div className={Classes.DIALOG_FOOTER}>
			<div className={Classes.DIALOG_FOOTER_ACTIONS}>
			  <Tooltip content="Cerrar y volver." position="top">
				<Button onClick={cerrarModal}>Cerrar</Button>
			  </Tooltip>
			  <Tooltip content="Guardar acuerdo." position="top">
				<AnchorButton
				  id="guardar-cambios-acuerdos"
				  intent={Intent.PRIMARY}
				  onClick={
					this.state.nuevoActivado ? añadirNuevo : actualizarAcuerdo
				  }
				>
				  Guardar acuerdo
				</AnchorButton>
			  </Tooltip>
			</div>
		  </div>
		</Dialog>
    {this.state.cargandoAcuerdos?
      <Cargando/>
    :
		<Card id="acuerdos-actas" interactive={true} elevation={Elevation.TWO}>
		  <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
			Acuerdos adoptados en el acta
		  </Callout>
		  <Navbar>
			<Navbar.Group>
			  <ButtonGroup align={Alignment.LEFT}>
				<Button
				  className="bp3-minimal"
				  icon="add"
				  text="Nuevo"
				  id="nuevo-acuerdo"
				  disabled={!this.props.acta && localStorage.getItem("ayuda")==="false"}
				  active={this.state.nuevoActivado}
				  onClick={abrirModalNuevo}
				/>
				<Button
				  className="bp3-minimal"
				  icon="edit"
				  id="editar-acuerdo"
				  text="Editar"
				  disabled={!this.props.acta && localStorage.getItem("ayuda")==="false"}
				  active={this.state.editarActivado}
				  onClick={abrirModalEditar}
				/>
				<NavbarDivider />
				<Tooltip content="Datos de inscripción en registro mercantil">
				  <Button
					className="bp3-minimal"
					onClick={abrirDatosInscripcion}
					id="inscripcion-mercantil"
					disabled={!this.props.acta && localStorage.getItem("ayuda")==="false"}
					icon="label"
					text="inscripción"
					active={this.state.inscripcionActivado}
				  />
				</Tooltip>
				<Button
				  className="bp3-minimal"
				  onClick={abrirCertificacionAcuerdos}
				  id="certificacion-acuerdos"
				  disabled={!this.props.acta && localStorage.getItem("ayuda")==="false"}
				  icon="saved"
				  text="Certificación"
				  active={this.state.certificacionActivado}
				/>
				<Tooltip content="Ver datos de impugnación de acuerdo">
				  <Button
					className="bp3-minimal"
					onClick={abrirDatosImpugnacion}
					disabled={!this.props.acta && localStorage.getItem("ayuda")==="false"}
					icon="ban-circle"
					id="impugnacion-acuerdos"
					text="Impugnación"
					active={this.state.impugnacionActivado}
				  />
				</Tooltip>
				<Tooltip content="Imprimir documento de certificación de acuerdo">
				{!this.state.cargando && this.props.acta && this.state.acuerdoSeleccionado
				  ?
          <Button 
            className="bp3-minimal" 
            icon="print" 
            text="Imprimir Certificado de acuerdo" onClick={()=>this.generarImprimir()}
          />
				  
				  :
					  <Button
					  className="bp3-minimal"
					  icon="print"
					  disabled={true}
					  text="Imprimir Certificado de acuerdo"
					   />
				  }
				
				</Tooltip>
			  </ButtonGroup>
			</Navbar.Group>
		  </Navbar>
		  <Table
			onSelection={seleccionarAcuerdo}
			numRows={this.props.acuerdos.length}
			selectedRegions={this.state.regionSeleccionada}
			selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]}
			ref={input => {this.tablaAcuerdos = input;}}
      columnWidths={this.state.columnWidths} onCompleteRender={() => {
        if (!this.state.resized) {
            this.resizeColumns();
        }
      }}
		  >
			<Column name="Número" cellRenderer={cellRendererNumero} />
			<Column name="texto" cellRenderer={cellRendererTexto} />
			<Column name="Certificado" cellRenderer={cellRendererCertificado} />
			<Column name="Impugnado" cellRenderer={cellRendererImpugnado} />
		  <Column name="Plazo" cellRenderer={cellRendererPlazo} />
		  <Column name="Cumplimiento" cellRenderer={cellRendererCumplimiento} />
      <Column name="Responsable" cellRenderer={cellRendererResponsable} />
		  </Table>
		</Card>
    }
	  </>
	);
  }
}
