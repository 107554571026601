/* eslint-disable array-callback-return */
import React from "react"
import {Spinner, AnchorButton, Card, Navbar, ButtonGroup, Alignment, Button, Dialog, Classes, Tooltip, Intent, FormGroup, Elevation, Collapse, TextArea, Callout } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { Table, Column, Cell ,RegionCardinality,Regions} from "@blueprintjs/table"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import moment from 'moment';
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"

import styles from '../stylesPrint';

class PactosReservados extends React.Component {

    
    state = {
        nuevoActivado: false,
        pactos: [
        ],
        abierto1: false,
        abierto2: false,
        personaSeleccionada: null,
        listaPersonas: [],
        textoPacto: "",
        fecha:new Date(),
        personasSeleccionadas:[],
        editarActivado:false,
        borrarActivado:false,
        regionSeleccionada:null,
        pactoSeleccionado:null,
        cargando:false,
        cargandoImprimir:false,
        cargandoImprimirPR:false,
        modalImprimir:false,
        modalImprimirPR:false
    }

    obtenerNombreMiembros=async(persona)=>{
        let nombre=''
        if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===persona.ID_PERSONA){
                    nombre= `${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                }
            })    
           
         //Si es persona juridica
         }else if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(miembro=>{
                 if(miembro.ID_PERSONA===persona.ID_PERSONA){
                     nombre= miembro.RAZON_SOCIAL
                 }
             })
            //  await this.props.client
            //  .query({
            //      query: gql`
            //          {personasJuridicas(FILTROS:{ID_PERSONA:${persona.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
            //      `,
            //      fetchPolicy:'network-only'

            //  }).then(result=>{ 
 
            //     persona.nombre=result.data.personasJuridicas[0].RAZON_SOCIAL

            //  })
 
         }
         persona.nombre=nombre
    }

    cargarCapital=async()=>{

        let capital=this.props.capSocialInicialDistribucion
        // await this.props.client.query({
        //   query:gql`{capSocialInicialDistribucion{
        //     ID_PERSONA,
        //     PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
        //   }}`,fetchPolicy:'network-only'
        // }).then(async(result)=>{
        //     return JSON.parse(JSON.stringify(result.data.capSocialInicialDistribucion))
        // })

        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })

        let cap_new=[];
        for(let cap of capital){
          //buscamos el nombre de la persona
          let nombre=""
          if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
              this.props.personasFisicas.find(persona=>{
                  if(persona.ID_PERSONA===cap.ID_PERSONA){
                      nombre= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}` 
                  }
              })
    
          //Si es persona juridica
          }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
              this.props.personasJuridicas.find(persona=>{
                  if(persona.ID_PERSONA===cap.ID_PERSONA){
                      nombre= persona.RAZON_SOCIAL
                  }
              })
    
            }
            cap_new.push({
                "label":nombre,
                "value":cap.ID_PERSONA
            })
            cap.label=nombre
            cap.value=cap.ID_PERSONA

      }

    
      for (let transmision of transmisiones){
        let nombre=''
        if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                    nombre= `${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                }
            })    
           
         //Si es persona juridica
         }else if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(miembro=>{
                 if(miembro.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                     nombre= miembro.RAZON_SOCIAL
                 }
             })
        }
        transmision.FK_DESTINO.nombre=nombre     

        //   await this.obtenerNombreMiembros(transmision.FK_DESTINO)
          cap_new.push({
              "label":transmision.FK_DESTINO.nombre,
              "value":transmision.FK_DESTINO.ID_PERSONA
          })
      }

      this.setState({listaPersonas:cap_new})

    }

    cargarPactos=async()=>{

        let pactos=await this.props.client.query({
            query:gql`{sociosPactosReservados{
                ID_PACTO,
                FECHA,
                TEXTO,
                SOCIOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.sociosPactosReservados))
        })
        console.log(pactos)
        this.setState({pactos:pactos})
    }

    componentDidMount=()=>{
        this.setState({
            cargando:true
        }, async() =>{
          await  this.cargarCapital()
          await this.cargarPactos()
          this.setState({cargando:false})
        })        
    }

    generarImprimirPR= async() =>{
        console.log(this.state.personasSeleccionadas)
        this.setState({
            cargandoImprimirPR:true,
            modalImprimirPR:true
        }, async () =>{
            this.setState({
                cargandoImprimirPR:false
            }, () =>{
                 ReactDOM.render(this.ImprimirPR(), document.getElementById('generarPR-pdf'))
            })
        })
    }

    generarImprimirGral=async() =>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () =>{
            this.setState({
                cargandoImprimir:false
            }, () =>{
                ReactDOM.render(this.ImprimirGral(), document.getElementById('generar-pdf'))
            })
        })
    }

    ImprimirGral=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>PACTOS RESERVADOS</Text>
                </View>
                    {this.state.pactos?this.state.pactos.map((pacto, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column' , fontSize:10, fontFamily: 'Open Sans Bold' , width:'100%'}}>PACTOS</Text>
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans',width:'100%'}}>PACTO RESERVADO CON FECHA: {moment(pacto.FECHA).format("DD-MM-YYYY")}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    ImprimirPR= () =>(
    <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>PACTOS RESERVADOS</Text>
                </View>
                    {this.state.pactoSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>FECHA DEL PACTO:</Text>
                                    <Text style={styles.textData}>{moment(this.state.pactoSeleccionado.FECHA).format("DD-MM-YYYY")}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>SOCIOS INTERVINIENTES:</Text>
                                    <Text style={styles.textData}>{this.state.pactoSeleccionado.SOCIOS}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>TEXTO DEL PACTO:</Text>
                                    <Text style={styles.textData}>{this.state.pactoSeleccionado.TEXTO}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    
 

    render(){

        document.title ="LexControl | Pactos reservados"
        
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalNuevoAbierto: true,
                regionSeleccionada:null,
                pactoSeleccionado:null,
                textoPacto:null,
                personasSeleccionadas:[],
                fecha:new Date()
            },()=>{
                this.tablaPactos.clearSelection()  
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.pactoSeleccionado){
                alert("Debes seleccionar un pacto")
                return
            }

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            let personas=[]
            let sociosPacto=this.state.pactoSeleccionado.SOCIOS.split(",")
            for(let socio of sociosPacto){
                let personasSel=this.state.listaPersonas.find(persona=>{return persona.value===parseInt(socio)})
                personas.push(personasSel)
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalNuevoAbierto: true,
                fecha:new Date(this.state.pactoSeleccionado.FECHA),
                textoPacto:this.state.pactoSeleccionado.TEXTO,
                personasSeleccionadas:personas
            })
        }

        const abrirModalBorrar= () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.pactoSeleccionado){
                alert("Debes seleccionar un pacto")
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true,
            })
            
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const añadirNuevo = () => {

            if(!this.state.fecha)return
            if(!this.state.personasSeleccionadas.length>0)return
            if(!this.state.textoPacto)return

            this.props.client.mutate({
                mutation:gql`mutation{crearSocioPactoReservado(VALORES:{
                    FECHA:"${moment(this.state.fecha).format("YYYY-MM-DD")}"
                    TEXTO:"""${this.state.textoPacto}"""
                    SOCIOS:"${this.state.personasSeleccionadas.map(persona=>{return persona.value}).join(",")}"
                }){ID_PACTO}}`
            }).then(async result=>{
            
                alert("El pacto se ha creado correctamente.")
                cerrarModal()
                await this.cargarPactos()

                let filaCreada=this.state.pactos.find((pacto,index)=>{

                    if(pacto.ID_PACTO===result.data.crearSocioPactoReservado.ID_PACTO){
                        this.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                    }
                    return pacto.ID_PACTO===result.data.crearSocioPactoReservado.ID_PACTO

                })
                this.setState({
                    pactoSeleccionado:filaCreada
                })

            }).catch(err=>{
                alert("Ha ocurrido un error al crear el pacto.Pongase en contacto con el soporte tecnico")
            })
        }

        const editarPacto=()=>{

            if(!this.state.fecha)return
            if(!this.state.personasSeleccionadas.length>0)return
            if(!this.state.textoPacto)return

            this.props.client.mutate({
                mutation:gql`mutation{actualizarSocioPactoReservado(
                    ID_PACTO:${this.state.pactoSeleccionado.ID_PACTO},
                    VALORES:{
                    FECHA:"${moment(this.state.fecha).format("YYYY-MM-DD")}"
                    TEXTO:"""${this.state.textoPacto}"""
                    SOCIOS:"${this.state.personasSeleccionadas.map(persona=>{return persona.value}).join(",")}"
                }){ID_PACTO}}`
            }).then(async result=>{
            
                alert("El pacto se ha actualizado correctamente.")
                cerrarModal()
                await this.cargarPactos()

                let filaCreada=this.state.pactos.find((pacto,index)=>{

                    if(pacto.ID_PACTO===this.state.pactoSeleccionado.ID_PACTO){
                        this.setState({regionSeleccionada:[Regions.row(index)]})//seleccionamos en la tabla la nueva fila creada.
                    }
                    return pacto.ID_PACTO===this.state.pactoSeleccionado.ID_PACTO


                })
                this.setState({
                    pactoSeleccionado:filaCreada
                })


            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar el pacto.Pongase en contacto con el soporte tecnico")
            })

        }

        const eliminarPacto=()=>{

            this.props.client.mutate({
                mutation:gql`mutation{eliminarSocioPactoReservado(
                    ID_PACTO:${this.state.pactoSeleccionado.ID_PACTO}
                ){ID_PACTO}}`
            }).then(async result=>{
            
                alert("El pacto se ha eliminado correctamente.")
                cerrarModal()
                await this.cargarPactos()

                this.setState({
                    pactoSeleccionado:null,
                    regionSeleccionada:null
                },()=>{this.tablaPactos.clearSelection()})


            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar el pacto.Pongase en contacto con el soporte tecnico")
            })

        }

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
            })
        }

        const escogeFecha = (nuevaFecha) => {
            this.setState({fecha: nuevaFecha})
        }

        const cellRendererPacto = (rowIndex) => {
            return <Cell>{"Pacto reservado con fecha: " + moment(this.state.pactos[rowIndex].FECHA).format("DD-MM-YYYY")}</Cell>
        }

        
        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1, abierto2: false });
        }

        const onSeleccionarPersona=(value)=>{
            this.setState({personasSeleccionadas:value})
        }

        const handleCambiarTexto = (ev) => {
            this.setState({textoPacto:ev.currentTarget.value})
        }

        const handleSeleccionarPacto = (value) => {

            if(value.length===0)return

            let seleccionada=this.state.pactos[value[0].rows[0]]            
            this.setState({
                pactoSeleccionado:seleccionada,
                regionSeleccionada:value
            })
        }

        const cerrarModalImprimir= () =>{
            this.setState({
                modalImprimir:false,
                modalImprimirPR:false
            })
        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/> 
                :
                <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir pacto reservado seleccionado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirPR}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirPR?
                            <div style={{ width: '100%', height: '600px'}} id='generarPR-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe de pactos reservados"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generar-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>

                <div className="tablaSesenta">
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevoActivado?"Añadir pacto":"Editar pacto"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                    className="dialog-ancho"
                >
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup
                            label="Fecha:"
                            labelFor="fecha"
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                value={this.state.fecha}
                            />
                        </FormGroup>
                        <br />
                        <FormGroup
                                label="Seleccionar persona:"
                                labelFor="selecionar-persona"
                                intent={!this.state.personasSeleccionadas.length>0?"danger":""}
                                helperText={!this.state.personasSeleccionadas.length>0?"Debe seleccionar la persona":""}
                        >  
                        <div style={!this.state.personasSeleccionadas.length>0?{border:"1px solid red"}:{}}>
                            <Select 
                                options={this.state.listaPersonas}
                                onChange={onSeleccionarPersona}
                                isMulti={true}
                                value={this.state.personasSeleccionadas}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup
                                label="Texto del pacto:"
                                labelFor="selecionar-persona"
                                intent="danger"
                                helperText={!this.state.textoPacto?"Debe introducir un texto":""}
                        >
                            <div style={!this.state.textoPacto?{border:"1px solid red"}:{}}>
                                <TextArea fill={true} value={this.state.textoPacto} onChange={handleCambiarTexto} />
                            </div>
                        </FormGroup>
                    </Card>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content="Añadir pacto." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:editarPacto}
                            >
                                {this.state.nuevoActivado?"Añadir pacto":"Editar pacto"}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title="Borrar un pacto"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>¿Desea eliminar el pacto seleccionado?</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content="Eliminar pacto." position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarPacto}
                                >
                                    Si
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content="Cerrar y volver." position="top">
                                <Button onClick={cerrarModal}>No</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Card>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar}/>
                                <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                <Tooltip content="Desea imprimir el pacto seleccionado" position="right" >
                                    <Button className="bp3-minimal" icon="print" disabled={this.state.pactoSeleccionado?false:true}  text="Imprimir Pacto" onClick={()=>this.generarImprimirPR()}/>
                                </Tooltip>
                                <Tooltip content="Desea imprimir todas los pactos reservados" position="right" >
                                    <Button className="bp3-minimal" icon="print" text="Imprimir General" onClick={()=>this.generarImprimirGral()}/>
                                </Tooltip>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <Table enableMultipleSelection={false} selectedRegions={this.state.regionSeleccionada} selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} ref={(input) => { this.tablaPactos= input; }} onSelection={handleSeleccionarPacto} defaultColumnWidth={820} numRows={this.state.pactos.length}>
                        <Column name="Pactos" cellRenderer={cellRendererPacto}/>   
                    </Table>
                </Card>
                </div>
                <div className="tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">LEY 1/2018 LEY 1/2010 DE SOCIEDADES DE CAPITAL</Callout>
                    <br />
                    <div className="tablaMitad">
                        <Button icon="git-repo" onClick={handleClick1}>
                            Artículo 29. Pactos reservados. 
                        </Button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                        <Collapse isOpen={this.state.abierto1}>
                            <Card> 
                            <b>Artículo 29. Pactos reservados.</b>
                            <p>Los pactos que se mantengan reservados entre los socios no serán oponibles a la sociedad. </p>
                            </Card>
                        </Collapse>
                </Card>
                </div>
            </>
            }
           </> 
        )
    }
}export default withApollo(PactosReservados)