import React, { Component } from 'react';
import { Card, Tab, Elevation, Navbar, ButtonGroup, Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, AnchorButton, Intent, Tabs } from "@blueprintjs/core"
import Select from "react-select"
import VistaListadoActas from "./VistaListadoActas"
import InformacionActas from "./InformacionActas"
import CalendarioActasDerecha from "./Elementos/CalendarioActasDerecha"
import gql from "graphql-tag";
import DatosActa from "./Elementos/DatosActa"
import Asistentes from "./Elementos/Asistentes"
import Resumen from "./Elementos/Resumen"
import AdjuntosActa from "./Elementos/AdjuntosActa"
import Informacion from "./Elementos/Informacion"
import { withApollo } from 'react-apollo';
import moment from 'moment';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { 
    Document, Page ,pdfjs
} from 'react-pdf';
import axios from 'axios';
import ReactToPrint from "react-to-print";
import { Cargando } from './Cargando';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
/* eslint-disable */
class CalendarioActas extends Component {

    state={
        modalNuevoAbierto: false,
        modalEditarActivado: false,
        modalBorrarActivado: false,
        modalLibroActas: false,
        fechaSeleccionada1: null,
        fechaSeleccionada2: null,
        horaPrimera: null,
        segundaHoraDesactivada: true,
        tiposReuniones: [
            { value: 'Cualquiera', label: 'Cualquiera' },
            { value: 'Asamblea Universal', label: 'Asamblea Universal' },
            { value: 'Comisión', label: 'Comisión' },
            { value: 'Junta General Extraordinaria', label: 'Junta General Extraordinaria' },
            { value: 'Junta General Ordinaria', label: 'Junta General Ordinaria' },
            { value: 'Organo de Administración', label: 'Organo de Administración' },
            { value: 'Organo de Compliance', label: 'Organo de Compliance' },
            { value: 'Organo de Instrucción', label: 'Organo de Instrucción' },
            { value: 'Organo de Resolución', label: 'Organo de Resolución' },
            { value: 'Comité Consultivo', label: 'Comité Consultivo' },
        ],
        actas: [
        ],
        convocantes: [
        ],
        convocados: [
        ],
        modoacta: [
        ],
        textoGenerado: "",
        ordenDia: [
        ],
        pestañaDialog: "datos-acta",
        numeroAsistentes:null,
        actaSeleccionada:null,
        organoSeleccionado: null,
        organos:[
        ],
        asistentes:[],
        pasoGuia:0,
        steps: [

            {
                target: '#vista-calendario-actas',
                content: "En la siguiente pantalla gestionaremos las reuniones de actas, dando de alta la fecha de la reunión, los asistentes, los votos, documentos e impugnaciones.",
                selector: '#vista-calendario-actas',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 0,
            },
            {
                target: '#btn-nueva-acta',
                content: "Para crear una nueva acta hacemos clic en 'Nuevo'.",
                selector: '#btn-nueva-acta',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 1,
            },
            {
                target: '#dialogo-actas',
                content: "Y se abrirá el siguiente diálogo con la siguiente información, la cuál debemos rellenar.",
                selector: '#dialogo-actas',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 2,
            },
            {
                target: '#bp3-tab-title_propiedades-acta_asistentes',
                content: "Al seleccionar el tipo de reunión se cargarán automáticamente los miembros correspondientes al órgano o junta.",
                selector: '#bp3-tab-title_propiedades-acta_asistentes',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 3,
            },
            {
                target: '#datos-votacion',
                content: "Aquí se mustran los datos de la votacion de los asistentes del acta.Si se han cargado automáticamente los miembros de una junta u órgano, estos tendrán derecho a voto y acudirán personalmente (Esta información puede modificarse en la pestaña 'Asistentes').Cuando hagamos alguna modificación en la pestaña 'Asistentes' esta votación se actualizará acorde a la información de esta pestaña.",
                selector: '#datos-votacion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 4,
            },
            {
                target: '#tabla-asistentes',
                content: "En está tabla se muestran los asistentes del acta. Al seleccionar el tipo de reunión se cargarán los miembros del órgano automáticamente.",
                selector: '#tabla-asistentes',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 5,
            },
            {
                target: '#botonera-asistentes',
                content: "Estos botones nos permitirán crear, editar o eliminar los asistenteas al acta.",
                selector: '#botonera-asistentes',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 6,
            },

            {
                target: '#datos-votacion',
                content: "Cada vez que hagamos una modificación de los asistentes, se actualizará el porcentaje de los mismos en la pestaña 'Datos del acta'.",
                selector: '#datos-votacion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 7,
            },
        
            {
                target: '#textarea-resumen',
                content: "Aquí podremos escribir un resumen del tema a tratar en el acta.",
                selector: '#textarea-resumen',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 8,
            }, 
            {
                target: '#card-informacion',
                content: "Información relevante sobre las actas.",
                selector: '#card-informacion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 9,
            }, 
            {
                target: '#btn-editar-acta',
                content: "Para editar un acta, debemos seleccionar un acta de la tabla y hacer clic.",
                selector: '#btn-editar-acta',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 10,
            }, 
            {
                target: '#bp3-tab-title_propiedades-acta_adjuntos',
                content: "Al editar el acta, podemos observar como aparece una nueva pestaña 'Archivos adjuntos', en la cuál podemos adjuntar el documento del acta.",
                selector: '#bp3-tab-title_propiedades-acta_adjuntos',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 11,
            }, 
            {
                target: '#nuevo-acuerdo',
                content: "Para crear los acuerdos del acta seleccionaremos un acta de la tabla Y hacemos clic en 'Nuevo'.Se abrirá un diálogo con los siguientes datos:",
                selector: '#nuevo-acuerdo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 12,
            }, 
            {
                target: '#numero-acuerdo',
                content: "Número de acuerdo del acta.Este número será generado automáticamente.",
                selector: '#numero-acuerdo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 13,
            }, 
            {
                target: '#boleano-registro-mercantil',
                content: "Si el acuerdo debe ser inscrito en el registro mercantil.",
                selector: '#boleano-registro-mercantil',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 14,
            }, 
            {
                target: '#votacion-acuerdo',
                content: "El resultado de los votos del acuerdo.Por defecto todos los votos de los asistentes estarán, por defecto, en estado de 'Abstencion'.",
                selector: '#votacion-acuerdo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 15,
            }, 
            {
                target: '#bp3-tab-title_propiedades-acta_votos',
                content: "Accediendo a la pestaña 'Votos', podemos modificar el estado de los votos.",
                selector: '#bp3-tab-title_propiedades-acta_votos',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 16,
            }, 
            {
                target: '#votacion-acuerdo',
                content: "Una vez actualizado el voto, si volvemos a la pestaña 'Datos del acuerdo', veremos como se ha actualizado el porcentaje de los votos.",
                selector: '#votacion-acuerdo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:17,
            }, 
            {
                target: '#texto-actas',
                content: "Aquí debemos insertar una descripción del acuerdo.",
                selector: '#texto-actas',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 18,
            }, 
            {
                target: '#inscripcion-mercantil',
                content: "Si previamente hemos inscrito el acuerdo en el registro mercantil, hacemos clic.",
                selector: '#inscripcion-mercantil',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 19,
            }, 
            {
                target: '#dialogo-inscripcion',
                content: "Se mostrará el siguiente diálogo y rellenaremos los datos de la inscripción.",
                selector: '#dialogo-inscripcion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 20,
            }, 
            {
                target: '#certificacion-acuerdos',
                content: "Para certificar los acuerdos del acta, haremos clic en el botón 'Certificación'.",
                selector: '#certificacion-acuerdos',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 21,
            }, 
            {
                target: '#tabla-certificados',
                content: "A continuación indicamos que acuerdos están certificados.En esta tabla sólo se mostrarán los acuerdos que no han sido impugnados.",
                selector: '#tabla-certificados',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 22,
            }, 
            {
                target: '#impugnacion-acuerdos',
                content: "Si deseamos impugnar un acuerdo, debemos seleccionar el acuerdo de la tabla y hacer clic en 'Impugnación'.",
                selector: '#impugnacion-acuerdos',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 23,
            }, 
            {
                target: '#dialogoImpugnacion',
                content: "Y finalmente debemos rellenar los datos de la impugnación.",
                selector: '#dialogoImpugnacion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 24,
            }, 
            {
                target: '#editar-acuerdo',
                content: "Para editar un acuerdo, debemos seleccionar el acuerdo de la tabla y hacer clic en 'Editar'.",
                selector: '#editar-acuerdo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 25,
            }, 
            {
                target: '#tabla-adjuntos-acuerdo',
                content: "Al editar un acuerdo, podremos adjuntar documentos al mismo.",
                selector: '#tabla-adjuntos-acuerdo',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 26,
            }, 
            {
                target: '#impugnacion-acuerdos',
                content: "Si el acuerdo ya ha sido impugnado, y editamos su impugnación, volviendo hacer clic sobre el botón 'Impugnación', podremos ver 2 nuevas pestañas:",
                selector: '#impugnacion-acuerdos',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 27,
            }, 
            {
                target: '#bp3-tab-title_datos-impugnaion_anulacion',
                content: "Anulacion: En la cuál podemos anular el acuerdo impugnado.",
                selector: '#bp3-tab-title_datos-impugnaion_anulacion',
                position: 'left',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 28
            }, 
            {
                target: '#bp3-tab-title_datos-impugnaion_adjuntos',
                content: "Archivos adjuntos: En la cuál podemos adjuntar los documentos de la anulación.",
                selector: '#bp3-tab-title_datos-impugnaion_adjuntos',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex: 29
            }, 
           
           
        ],
        numPages: [],
        pageNumber: 1,
        cargando:false,
        libros:[],
        cargados:[],
        cargandoAsistente:false,
        acuerdos: [
        ],
    }

    setConvocados=(convocados)=>{
        this.setState({convocados:convocados})
    }

    setNumeroAsistentes=(numero)=>{
        this.setState({numeroAsistentes:numero})
    }

    setActaSeleccionada=(acta)=>{
      this.setState({
        cargandoAsistente:true
      },async()=>{
        await this.cargarAsistente(acta);
        await this.cargarAcuerdos(acta.ID_ACTA);
        this.setState({cargandoAsistente:false})
      })
      
      
    }

    setAsistentes=(asistentes)=>{
        this.setState({asistentes:asistentes})
    }

    obtenerNombreMiembros=async(miembro)=>{
        let personasFisicas=  await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasFisicas
        })
        let personasJuridicas= await this.props.client.query({
            query:gql`{personasJuridicas{RAZON_SOCIAL}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return result.data.personasJuridicas
        })        

        if(miembro.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            //obtenemos el nombre
            // eslint-disable-next-line
            personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.label=` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`
                }
            })
        

        }else if(miembro.TIPO_PERSONA.ID_TIPO_PERSONA===2){
            // eslint-disable-next-line
            personasJuridicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    miembro.label=persona.RAZON_SOCIAL

                }
            })

        }
        
    }

    cargarAcuerdos=async(idActa)=>{

      let acuerdos=await this.props.client.query({
        query: gql`
          {
          actasAcuerdos(FILTROS:{ID_ACTA:${idActa}}){
            ID_ACUERDO
            ID_ACTA,
            NUMERO,
            TEXTO,
            INSCRIBIBLE_RM,
            VOTOS_F,
            VOTOS_C,
            VOTOS_A,
            CERTIFICADO,
            PLAZO,
            CUMPLIMIENTO,
            RESPONSABLE,
            OBSERVACIONES
          }
          }
        `,fetchPolicy:'network-only'
      }).then(async result =>{
    
        let acuerdos=JSON.parse(JSON.stringify(result.data.actasAcuerdos))
        for(let acuerdo of acuerdos){
          /*
          let votos=[]
          //obtenemos los votos del acuerdo.
          await client.query({
            query: gql`
              {
              actasAcuerdosVotos(FILTROS:{ID_ACUERDO:${acuerdo.ID_ACUERDO}}){
                ID_ACUERDO,
                ID_PERSONA,
                PERSONA{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                VOTO,
              }
              }
            `,fetchPolicy:'network-only'
          }).then(async result =>{
            let votosAcuerdo=JSON.parse(JSON.stringify(result.data.actasAcuerdosVotos))
    
            //por cada voto...
            for(let voto of votosAcuerdo){
    
              //obtenemos el nombre de la persona del voto
              await this.obtenerNombreMiembros(voto)
              votos.push({
                idPersona:voto.ID_PERSONA,
                nombre:voto.label,
                voto:voto.VOTO
              })
            }
    
          })
          acuerdo.votos=votos
    
          //obtenemos la inscripcion de los acuerdos
          await client
            query: gql`
              {
              actasAcuerdosInscripcionRm(FILTROS:{ID_ACUERDO:${acuerdo.ID_ACUERDO}}){
                ID_ACUERDO,
                FECHA_PRESENTACION,
                ID_CALIFICACION_ACUERDO,
                FECHA_INSCRIPCION,
                FECHA_EFECTO,
                ID_REGISTRO,
                DIARIO,
                ASIENTO,
                TOMO,
                FOLIO,
                INSCRIPCION,
                HOJA,
              }
              }
            `,fetchPolicy:'network-only'
          }).then(async result =>{
            if(result.data.actasAcuerdosInscripcionRm.length>0){
              acuerdo.inscripcion=JSON.parse(JSON.stringify(result.data.actasAcuerdosInscripcionRm[0]))
            }
          })*/
    
          //comprobamos si el acuerdo esta esta certificado.
          await this.props.client.query({
            query: gql`
            {
              estaCertificado(ID_ACUERDO:${acuerdo.ID_ACUERDO})
            }
            `,fetchPolicy:'network-only'
          }).then(async result =>{
          
            if(result.data.estaCertificado){
              acuerdo.certificado=true
            }else{
            acuerdo.certificado=false
            }
          })
          /*
          //comprobamos si el acuerdo esta esta impugnado.
          await client.query({
          query: gql`
            {
            actasAcuerdosImpugnaciones(FILTROS:{ID_ACUERDO:${acuerdo.ID_ACUERDO}}){
              ID_IMPUGNACION,
              ID_ACUERDO,
              TEXTO,
              RESOLUCION,
              ANULACION,
              SUBSANACION,
              FECHA_EFECTO}
            }
          `,fetchPolicy:'network-only'
          }).then(async result =>{
            if(result.data.actasAcuerdosImpugnaciones.length>0){
            acuerdo.datosImpugnacion=result.data.actasAcuerdosImpugnaciones[0]
    
            //si tiene impgugnacion comprobamos si tiene anulacion 
            await client.query({
              query: gql`
              {
                actasAcuerdosImpugnacionesAnulaciones(FILTROS:{ID_IMPUGNACION:${result.data.actasAcuerdosImpugnaciones[0].ID_IMPUGNACION}}){
                ID_ANULACION,
                ID_IMPUGNACION,
                FECHA_FIRMEZA,
                ID_REGISTRO,
                DIARIO,
                ASIENTO,
                TOMO,
                FOLIO,
                INSCRIPCION,
                HOJA
                }
              }
              `,fetchPolicy:'network-only'
            }).then(async result =>{
              if(result.data.actasAcuerdosImpugnacionesAnulaciones.length>0){
              acuerdo.datosImpugnacion.datosAnulacion=result.data.actasAcuerdosImpugnacionesAnulaciones[0]
              }
            })
            }
          })
          */
        }
        return acuerdos
      });
    
      this.setState({acuerdosCertificados:acuerdos.filter(acuerdo=>{return !acuerdo.datosImpugnacion})})
      this.setState({acuerdos:acuerdos})
      }

    cargarAsistente=async (acta)=>{
      this.props.client.query({
        query:gql`{actasAsistentes(FILTROS:{ID_ACTA:${acta.ID_ACTA}}){
            ID_ACTA,
            ID_ASISTENTE,
            ASISTENTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            DERECHO_VOTO,
            REPRESENTA_A
            REPRESENTANTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
        }}`,
        fetchPolicy:'network-only'
      }).then(async(result)=>{
          let asistentes=[]
          console.log(result.data.actasAsistentes)
          for(let asistente of JSON.parse(JSON.stringify(result.data.actasAsistentes))){
              await this.obtenerNombreMiembros(asistente.ASISTENTE)
              if(asistente.REPRESENTANTE)await this.obtenerNombreMiembros(asistente.REPRESENTANTE)
              await asistentes.push({
                  value:asistente.ID_ASISTENTE,
                  label:asistente.ASISTENTE.label,
                  vota:asistente.DERECHO_VOTO,
                  idRepresenta:asistente.REPRESENTA_A,
                  representa:asistente.REPRESENTANTE?asistente.REPRESENTANTE.label:null
              })
          }
          acta.asistentes=asistentes
          this.setState({actaSeleccionada:acta})
      })
    }

    cargarActas=async()=>{

        //carga de filtro tipo convocatoria
        let actas=await this.props.client.query({
            query:gql`{actas{
                ID_ACTA,
                NUMERO,
                ID_CONVOCATORIA,
                CONVOCATORIA{FECHA},
                FECHA_REUNION,
                LUGAR,
                ID_TIPO_CONVOCATORIA,
                TIPO_CONVOCATORIA{DESCRIPCION},
                SOCIOS_VOTO_PERSONAL,
                SOCIOS_VOTO_PERSONAL_PORCENTAJE,
                SOCIOS_VOTO_REPRESENTADOS,
                SOCIOS_VOTO_REPRESENTADOS_PORCENTAJE,
                RESUMEN,
                APROBADA,
                FECHA_APROBACION,
                SISTEMA_APROBACION,
                FIRMADA,
                ID_COMISION,
            }}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.actas))
        }) 
    //     let actaAsistentes = await this.props.client.query({
    //         query:gql`{actasAsistentes{
    //             ID_ACTA,
    //             ID_ASISTENTE,
    //             ASISTENTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //             DERECHO_VOTO,
    //             REPRESENTA_A
    //             REPRESENTANTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //         }}`,
    //         fetchPolicy:'network-only'
    //     }).then(async(result)=>{
    //         return   JSON.parse(JSON.stringify(result.data.actasAsistentes))
    //     })
        let actasAcuerdos= await this.props.client.query({
            query:gql`{actasAcuerdosSolicitudCertificaciones{
                ID_SOLICITUD,
                ID_ACTA,
                ID_SOLICITANTE,
                FECHA_SOLICITUD,
                ACUERDOS
            }}`,fetchPolicy:'network-only'
            }).then(async(result)=>{
                return result.data.actasAcuerdosSolicitudCertificaciones
            })  
            let actaAdjuntos=await this.props.client.query({
                query:gql`{actasPdf{
                        ID_ACTA,
                        RUTA_ARCHIVO
                    }}`,fetchPolicy:'network-only'
                }).then(async(result)=>{
                    return result.data.actasPdf    
                })  
            for(let acta of actas){
                 //obtencion de los asistenteas a las actas
                //carga de filtro tipo convocatoria
                

                //obtener los acuerdos certificados del acta.
                     let acuerdos=[]
                     // eslint-disable-next-line
                     actasAcuerdos.find(acuerdo=>{
                         if(acuerdo.ID_ACTA===acta.ID_ACTA){
                             acuerdos.push(acuerdo)
                         }
                     })    
                    if(acuerdos.length>0){
                        acta.certificados=acuerdos
                    }
                

                
                 //obtenemos el adjunto del acta.
                let adjuntos=[]
                // eslint-disable-next-line
                actaAdjuntos.find(adjunto=>{
                    if(adjunto.ID_ACTA===acta.ID_ACTA){
                        adjuntos.push(adjunto)
                    }
                })
                if(adjuntos.length > 0){
                    acta.adjunto= adjuntos
                }

            }

            this.setState({actas:actas}) 

               
            if(this.state.actaSeleccionada){

                let actaSel=await this.state.actas.find(acta=>{return acta.ID_ACTA===this.state.actaSeleccionada.ID_ACTA})
                await this.setState({actaSeleccionada:actaSel})

            }
        
    }
  
    // cargarActas=async()=>{

    //     //carga de filtro tipo convocatoria
    //     await this.props.client.query({
    //         query:gql`{actas{
    //             ID_ACTA,
    //             NUMERO,
    //             ID_CONVOCATORIA,
    //             CONVOCATORIA{FECHA},
    //             FECHA_REUNION,
    //             LUGAR,
    //             ID_TIPO_CONVOCATORIA,
    //             TIPO_CONVOCATORIA{DESCRIPCION},
    //             SOCIOS_VOTO_PERSONAL,
    //             SOCIOS_VOTO_PERSONAL_PORCENTAJE,
    //             SOCIOS_VOTO_REPRESENTADOS,
    //             SOCIOS_VOTO_REPRESENTADOS_PORCENTAJE,
    //             RESUMEN,
    //             APROBADA,
    //             FECHA_APROBACION,
    //             SISTEMA_APROBACION,
    //             FIRMADA,
    //             ID_COMISION,
    //         }}`,
    //         fetchPolicy:'network-only'
    //     }).then(async(result)=>{

    //         let actas=JSON.parse(JSON.stringify(result.data.actas))
    //         for(let acta of actas){
    //             //obtencion de los asistenteas a las actas
    //             //carga de filtro tipo convocatoria
    //             this.props.client.query({
    //                 query:gql`{actasAsistentes(FILTROS:{ID_ACTA:${acta.ID_ACTA}}){
    //                     ID_ACTA,
    //                     ID_ASISTENTE,
    //                     ASISTENTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //                     DERECHO_VOTO,
    //                     REPRESENTA_A
    //                     REPRESENTANTE{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //                 }}`,
    //                 fetchPolicy:'network-only'
    //             }).then(async(result)=>{
    //                 let asistentes=[]
    //                 for(let asistente of JSON.parse(JSON.stringify(result.data.actasAsistentes))){
    //                     await this.obtenerNombreMiembros(asistente.ASISTENTE)
    //                     if(asistente.REPRESENTANTE)await this.obtenerNombreMiembros(asistente.REPRESENTANTE)
    //                     await asistentes.push({
    //                         value:asistente.ID_ASISTENTE,
    //                         label:asistente.ASISTENTE.label,
    //                         vota:asistente.DERECHO_VOTO,
    //                         idRepresenta:asistente.REPRESENTA_A,
    //                         representa:asistente.REPRESENTANTE?asistente.REPRESENTANTE.label:null
    //                     })
    //                 }
    //                 acta.asistentes=asistentes
                    

    //             })
    //             //obtener los acuerdos certificados del acta.
    //             await this.props.client.query({
    //             query:gql`{actasAcuerdosSolicitudCertificaciones(FILTROS:{ID_ACTA:${acta.ID_ACTA}}){
    //                 ID_SOLICITUD,
    //                 ID_ACTA,
    //                 ID_SOLICITANTE,
    //                 FECHA_SOLICITUD,
    //                 ACUERDOS
    //             }}`,fetchPolicy:'network-only'
    //             }).then(async(result)=>{
    //                 if(result.data.actasAcuerdosSolicitudCertificaciones.length>0){
    //                     acta.certificados=result.data.actasAcuerdosSolicitudCertificaciones[0]
    //                 }
    //             })

                
    //             //obtenemos el adjunto del acta.
    //             await this.props.client.query({
    //             query:gql`{actasPdf(FILTROS:{ID_ACTA:${acta.ID_ACTA}}){
    //                     RUTA_ARCHIVO
    //                 }}`,fetchPolicy:'network-only'
    //             }).then(async(result)=>{
    //                 if(result.data.actasPdf.length>0){
    //                     acta.adjunto=result.data.actasPdf[0]
    //                 }
    //             })
    //         }

    //         console.log(actas)
    //         await this.setState({actas:actas}) 

               
    //         if(this.state.actaSeleccionada){

    //             let actaSel=await this.state.actas.find(acta=>{return acta.ID_ACTA===this.state.actaSeleccionada.ID_ACTA})
    //             await this.setState({actaSeleccionada:actaSel})

    //         }
    //     })

    // }

    componentDidMount=async()=>{
        this.setState({cargando:true}, async () => {
            let usuarioActual=await this.props.client
        .query({
            query: gql`
            {obtenerUsuarioActual}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.obtenerUsuarioActual
        })
        await this.setState({ idUsuarioActual:usuarioActual})

        await this.props.client.query({
            query:gql`{tipoConvocatorias{ID_TIPO_CONVOCATORIA,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            let tipoConvocatorias=[]
            for(let tipo of result.data.tipoConvocatorias){
                tipoConvocatorias.push({
                    label: tipo.DESCRIPCION,
                    value: tipo.ID_TIPO_CONVOCATORIA,
                })
            }
            this.setState({organos:tipoConvocatorias})
           

        })



        await this.cargarActas()
        this.setState({cargando:false})
        })
        
    }

    recargar=()=>{
        this.setState(this.state)
    }

    onDocumentLoad = async({numPages},index) => {
        let nPages=this.state.numPages
        nPages[index]=(numPages)
        let cargados=this.state.cargados
        cargados.push(true)
        if(cargados.length===this.state.libros.length)this.setState({cargando:false})
        await this.setState({ numPages:nPages,cargados:cargados});

    }


    LibroActas=(props)=>{
        
        let libros=[]
    
        for( let libro of this.state.libros){
            let obj=(libro.documento)
            libros.push(
                <>
                    <Document
                        file={`data:application/pdf;base64,${obj}`}
                        onLoadSuccess={(e) => {this.onDocumentLoad(e,libro.index)}}
                    >
                    <h1 className="cabecera-pdf-actas">{`Acta No. ${libro.numero}`}</h1>
                    <br />
                    { this.mostrarPaginas(props.numPages[libro.index])}
                    <br />
                    </Document>
                </>
            )
        }
        
        return (<div ref={(input) => { this.libros = input; }}>{libros}</div>)

    }
    mostrarPaginas=(numPages)=>{

        let Pages=[]
        for(let i=1;i<=numPages;i++){
            Pages.push(<Page pageNumber={i} width={960}/>)
        }
        return Pages
    }

    cargarLibros=async()=>{


        let pdfs=[]
        let index=0
        this.setState({ libros: [], cargados: [], cargando: true })
        await this.props.client.query({
            query:gql`{actas(ORDEN:"NUMERO,ASC",CAMPOS:"ID_ACTA,ID_TIPO_CONVOCATORIA,NUMERO",FILTROS:{ID_TIPO_CONVOCATORIA:${this.state.organoSeleccionado.value}}){ID_ACTA,NUMERO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            for(let acta of result.data.actas){
                await this.props.client.query({
                    query:gql`{actasPdf(FILTROS:{ID_ACTA:${acta.ID_ACTA}}){RUTA_ARCHIVO}}`,
                    fetchPolicy:'network-only'
                }).then(async(result)=>{
                    if(result.data.actasPdf.length>0){

                        const api = axios.create({
                            withCredentials: true
                        });
    
                        await api.get("https://localhost:4000"+result.data.actasPdf[0].RUTA_ARCHIVO.substring(1,result.data.actasPdf[0].RUTA_ARCHIVO.length), {responseType: 'arraybuffer'}).then(async(response)=> {
                            pdfs.push({
                                documento:new Buffer(response.data, 'binary').toString('base64'),
                                numero:acta.NUMERO,
                                index: index
                            })
    
                        })

                    }

                  
                })
                index++
            }

        })
        if(pdfs.length===0){
            alert("Este tipo de reunión no tiene ningún acta con documentos.")
            this.setState({cargando:false})
            return
        }


        await this.setState({libros:pdfs})
    }



    render() {

        const { steps,pageNumber, numPages } = this.state;

        document.title ="LexControl | Actas"

        const abrirModalNuevo = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
        
            this.setState({
                nuevaActa:true,
                editarActa:false,
                pestañaDialog:"datos-acta",
                modalNuevoAbierto: true,
                actaSeleccionada:null,
            })

            this.setConvocados([])
            this.setAsistentes([])
            this.setNumeroAsistentes(0)
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            if(!this.state.actaSeleccionada){
                alert("Debe seleccionar un acta")
                return
            }

            this.setState({
                nuevaActa:false,
                pestañaDialog:"datos-acta",
                editarActa:true,
                modalNuevoAbierto: true
            })

            this.setConvocados(this.state.actaSeleccionada.asistentes?this.state.actaSeleccionada.asistentes:null)
            this.setAsistentes(this.state.actaSeleccionada.asistentes?this.state.actaSeleccionada.asistentes:null)
            this.setNumeroAsistentes(this.state.actaSeleccionada.asistentes?this.state.actaSeleccionada.asistentes.length:0)


        }

        /*const abrirModalImpresion = () => {
            this.setState({
                nuevaActa: false,
                editarActa: false,
                modalNuevoAbierto: false,
                modalLibroActas: true,
                organoSeleccionado:null
            })
        }*/

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarActivado: false,
                modalBorrarActivado: false,
                modalLibroActas: false,
            })
        }

        const seleccionarOrgano = async(value) => {
            await this.setState({organoSeleccionado: value})
            await this.cargarLibros()
        }

        const cambiarPestaña = (navbarTabId) => this.setState({ pestañaDialog: navbarTabId });

        const anadirActa = async() => {
            if(!this.DatosActa.state.tipoReunionSeleccionada){
                this.setState({pestañaDialog:"datos-acta"})
                return
            }
            if(!this.DatosActa.state.lugarReunion){
                this.setState({pestañaDialog:"datos-acta"})
                return
            }

            //comprobar que no existe ya el acta con ese tipo de reunión y número.
            let existe=this.state.actas.find(acta=>{return acta.NUMERO===this.DatosActa.state.numeroActa && acta.ID_TIPO_CONVOCATORIA===this.DatosActa.state.tipoReunionSeleccionada.value})
            if(existe){
                alert("Ya existe un acta con este número")
                return
            }

            if(this.state.asistentes.length===0){
                alert("El acta debe tener almenos un asistente")
                return
            }
             
            //creamos el acta.
            await this.props.client.mutate({
                mutation: gql`
                    mutation{crearActa(VALORES:{
                        NUMERO:${this.DatosActa.state.numeroActa}
                        ID_CONVOCATORIA:${this.DatosActa.state.convocatoriaSeleccionada?this.DatosActa.state.convocatoriaSeleccionada.value:null}
                        FECHA_REUNION:"${moment(this.DatosActa.state.fechaReunion).format("YYYY-MM-DD HH:mm:ss")}"
                        LUGAR:"${this.DatosActa.state.lugarReunion}"
                        ID_TIPO_CONVOCATORIA:${this.DatosActa.state.tipoReunionSeleccionada.value}
                        SOCIOS_VOTO_PERSONAL:${this.DatosActa.state.asistentesDirectos}
                        SOCIOS_VOTO_PERSONAL_PORCENTAJE:${this.DatosActa.state.porcentaje1}
                        SOCIOS_VOTO_REPRESENTADOS:${this.DatosActa.state.asistentesRepresentados}
                        SOCIOS_VOTO_REPRESENTADOS_PORCENTAJE:${this.DatosActa.state.porcentaje2}
                        RESUMEN:${this.DatosResumen.state.resumen?'"""'+this.DatosResumen.state.resumen+'"""':null}
                        APROBADA:${this.DatosActa.state.actaAprobada}
                        FECHA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+moment(this.DatosActa.state.fechaAprobacion).format("YYYY-MM-DD")+'"':null}
                        SISTEMA_APROBACION:${this.DatosActa.state.actaAprobada && this.DatosActa.state.sistemaAprobacion?'"'+this.DatosActa.state.sistemaAprobacion+'"':null}
                        FIRMADA:${this.DatosActa.state.actaFirmada}
                        ID_COMISION:${this.DatosActa.state.comisionSeleccionada?this.DatosActa.state.comisionSeleccionada.ID_COMISION:null}
                    
                    }){ID_ACTA}}
                `
            }).then(async result=>{
            
                
                await this.cargarActas()
                if (this.DatosAdjuntosActa.state.fileAdjunto) {
                  let archivo=this.DatosAdjuntosActa.state.fileAdjunto
                  //insertamos el archivo

                  const api = axios.create({
                      withCredentials: true
                  });

                  var formData = new FormData();
                  formData.append("files",archivo);

                  //si no tenemos adjunto
                  
                  await api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearActaPdf(VALORES:{ID_ACTA:${result.data.crearActa.ID_ACTA}}){ID_ACTA,RUTA_ARCHIVO}}`, formData, {
                      headers: {
                      'Content-Type': 'multipart/form-data'
                      }
                  }).then(async(response)=> {
                      api.post(`${process.env.REACT_APP_DOMINIO}/api?query=mutation{crearActaPdfH(VALORES:{ID_ACTA:${result.data.crearActa.ID_ACTA},RUTA_ARCHIVO:"${response.data.data.crearActaPdf.RUTA_ARCHIVO}",FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",ID_USUARIO:${this.state.idUsuarioActual}}){ID_ACTA}}`).catch(err=>{
                          alert("Ha ocurrido un problema al crear el adjunto.Pongase en contacto con el soporte técnico")
                          console.log(err)
                      })
                  
                  }).catch(err=>{
                      alert("Ha ocurrido un problema al crear el adjunto.Pongase en contacto con el soporte técnico")
                      console.log(err)
                  })
                }
                alert("El acta ha sido creada correctamente")

                //insertamos en el historico
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{crearActaH(VALORES:{
                            ID_ACTA:${result.data.crearActa.ID_ACTA},
                            NUMERO:${this.DatosActa.state.numeroActa}
                            ID_CONVOCATORIA:${this.DatosActa.state.convocatoriaSeleccionada?this.DatosActa.state.convocatoriaSeleccionada.value:null}
                            FECHA_REUNION:"${moment(this.DatosActa.state.fechaReunion).format("YYYY-MM-DD HH:mm:ss")}"
                            LUGAR:"${this.DatosActa.state.lugarReunion}"
                            ID_TIPO_CONVOCATORIA:${this.DatosActa.state.tipoReunionSeleccionada.value}
                            SOCIOS_VOTO_PERSONAL:${this.DatosActa.state.asistentesDirectos}
                            SOCIOS_VOTO_PERSONAL_PORCENTAJE:${this.DatosActa.state.porcentaje1}
                            SOCIOS_VOTO_REPRESENTADOS:${this.DatosActa.state.asistentesRepresentados}
                            SOCIOS_VOTO_REPRESENTADOS_PORCENTAJE:${this.DatosActa.state.porcentaje2}
                            RESUMEN:${this.DatosResumen.state.resumen?'"""'+this.DatosResumen.state.resumen+'"""':null}
                            APROBADA:${this.DatosActa.state.actaAprobada}
                            FECHA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+moment(this.DatosActa.state.fechaAprobacion).format("YYYY-MM-DD")+'"':null}
                            SISTEMA_APROBACION:${this.DatosActa.state.actaAprobada && this.DatosActa.state.sistemaAprobacion?'"'+this.DatosActa.state.sistemaAprobacion+'"':null}
                            FIRMADA:${this.DatosActa.state.actaFirmada}
                            ID_COMISION:${this.DatosActa.state.comisionSeleccionada?this.DatosActa.state.comisionSeleccionada.ID_COMISION:null},
                            FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
                            ID_USUARIO:${this.state.idUsuarioActual}
                            
                        }){ID_ACTA}}
                    `
                }).catch(err=>{
                    alert("Ha ocurrido un error al crear el acta.Pongase en contacto con el soporte tecnico")
                    console.log(err)
                })

                //si tenemos asistentes los damos de alta.
                if(this.DatosAsistentes.state.asistentes.length>0){
                    for(let asistente of this.DatosAsistentes.state.asistentes){
                        //creamos el asistente
                        this.props.client.mutate({
                            mutation: gql`
                                mutation{crearActaAsistente(VALORES:{
                                    ID_ACTA:${result.data.crearActa.ID_ACTA}
                                    ID_ASISTENTE:${asistente.value}
                                    DERECHO_VOTO:${asistente.vota}
                                    REPRESENTA_A:${asistente.idRepresenta?asistente.idRepresenta:null}
                                }){ID_ACTA,ID_ASISTENTE,DERECHO_VOTO,REPRESENTA_A}}
                            `
                        })

                    }
                }
             
                await cerrarModal()
                
                let actaCreada=await this.state.actas.find(acta=>{return acta.ID_ACTA===result.data.crearActa.ID_ACTA})
                this.setState({actaSeleccionada:actaCreada})
                window.location.reload();
            }).catch(err=>{
                console.log(err)
                alert("Ha ocurrido un error al crear la actividad.Pongase en contacto con el servicio tecnico")
            })

            
        }

        const actualizarActa=()=>{
           
            if(!this.DatosActa.state.tipoReunionSeleccionada)return
            if(!this.DatosActa.state.lugarReunion)return

            //comprobar que no existe ya el acta con ese tipo de reunión y número.
            let existe=this.state.actas.find(acta=>{return acta.NUMERO===this.DatosActa.state.numeroActa && acta.ID_TIPO_CONVOCATORIA===this.DatosActa.state.tipoReunionSeleccionada.value && acta.ID_ACTA!==this.state.actaSeleccionada.ID_ACTA})
            if(existe){
                alert("Ya existe un acta con este número")
                return
            }
            //creamos el acta.
            this.props.client.mutate({
                mutation: gql`
                    mutation{actualizarActa(
                        ID_ACTA:${this.state.actaSeleccionada.ID_ACTA},
                        VALORES:{
                            NUMERO:${this.DatosActa.state.numeroActa}
                            ID_CONVOCATORIA:${this.DatosActa.state.convocatoriaSeleccionada?this.DatosActa.state.convocatoriaSeleccionada.value:null}
                            FECHA_REUNION:"${moment(this.DatosActa.state.fechaReunion).format("YYYY-MM-DD HH:mm:ss")}"
                            LUGAR:"${this.DatosActa.state.lugarReunion}"
                            ID_TIPO_CONVOCATORIA:${this.DatosActa.state.tipoReunionSeleccionada.value}
                            SOCIOS_VOTO_PERSONAL:${this.DatosActa.state.asistentesDirectos}
                            SOCIOS_VOTO_PERSONAL_PORCENTAJE:${this.DatosActa.state.porcentaje1}
                            SOCIOS_VOTO_REPRESENTADOS:${this.DatosActa.state.asistentesRepresentados}
                            SOCIOS_VOTO_REPRESENTADOS_PORCENTAJE:${this.DatosActa.state.porcentaje2}
                            RESUMEN:${this.DatosResumen.state.resumen?'"""'+this.DatosResumen.state.resumen+'"""':null}
                            APROBADA:${this.DatosActa.state.actaAprobada}
                            FECHA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+moment(this.DatosActa.state.fechaAprobacion).format("YYYY-MM-DD")+'"':null}
                            SISTEMA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+this.DatosActa.state.sistemaAprobacion+'"':null}
                            FIRMADA:${this.DatosActa.state.actaFirmada}
                            ID_COMISION:${this.DatosActa.state.comisionSeleccionada?this.DatosActa.state.comisionSeleccionada.ID_COMISION:null}
                    }){ID_ACTA}}
                `
            }).then(async result=>{
            
                alert("El acta ha sido actualizada correctamente")

                await this.props.client.mutate({
                    mutation: gql`
                        mutation{actualizarActaH(
                            ID_ACTA:${this.state.actaSeleccionada.ID_ACTA},
                                VALORES:{
                                NUMERO:${this.DatosActa.state.numeroActa}
                                ID_CONVOCATORIA:${this.DatosActa.state.convocatoriaSeleccionada?this.DatosActa.state.convocatoriaSeleccionada.value:null}
                                FECHA_REUNION:"${moment(this.DatosActa.state.fechaReunion).format("YYYY-MM-DD HH:mm:ss")}"
                                LUGAR:"${this.DatosActa.state.lugarReunion}"
                                ID_TIPO_CONVOCATORIA:${this.DatosActa.state.tipoReunionSeleccionada.value}
                                SOCIOS_VOTO_PERSONAL:${this.DatosActa.state.asistentesDirectos}
                                SOCIOS_VOTO_PERSONAL_PORCENTAJE:${this.DatosActa.state.porcentaje1}
                                SOCIOS_VOTO_REPRESENTADOS:${this.DatosActa.state.asistentesRepresentados}
                                SOCIOS_VOTO_REPRESENTADOS_PORCENTAJE:${this.DatosActa.state.porcentaje2}
                                RESUMEN:${this.DatosResumen.state.resumen?'"""'+this.DatosResumen.state.resumen+'"""':null}
                                APROBADA:${this.DatosActa.state.actaAprobada}
                                FECHA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+moment(this.DatosActa.state.fechaAprobacion).format("YYYY-MM-DD")+'"':null}
                                SISTEMA_APROBACION:${this.DatosActa.state.actaAprobada?'"'+this.DatosActa.state.sistemaAprobacion+'"':null}
                                FIRMADA:${this.DatosActa.state.actaFirmada}
                                ID_COMISION:${this.DatosActa.state.comisionSeleccionada?this.DatosActa.state.comisionSeleccionada.ID_COMISION:null}
                                FECHA_REGISTRO:"${moment().format("YYYY-MM-DD HH:mm:ss")}",
                                ID_USUARIO:${this.state.idUsuarioActual} 
                        }){ID_ACTA}}
                    `
                }).catch(err=>{
                    alert("Ha ocurrido un error al actualizar el acta.Pongase en contacto con el soporte tecnico")
                    console.log(err)
                })
                
                //eliminamos los antiguos asistentes.
                await this.props.client.mutate({
                    mutation: gql`
                        mutation{eliminarActaAsistente(ID_ACTA:${this.state.actaSeleccionada.ID_ACTA}){ID_ACTA}}
                    `
                })

                //si tenemos asistentes los damos de alta.
                if(this.DatosAsistentes.state.asistentes.length>0){
                    console.log(this.DatosAsistentes.state.asistentes)
                    for(let asistente of this.DatosAsistentes.state.asistentes){
                        console.log(asistente)
                        //creamos el asistente
                        await this.props.client.mutate({
                            mutation: gql`
                                mutation{crearActaAsistente(VALORES:{
                                    ID_ACTA:${this.state.actaSeleccionada.ID_ACTA}
                                    ID_ASISTENTE:${asistente.value}
                                    DERECHO_VOTO:${asistente.vota}
                                    REPRESENTA_A:${asistente.idRepresenta?asistente.idRepresenta:null}
                                }){ID_ACTA,ID_ASISTENTE,DERECHO_VOTO,REPRESENTA_A}}
                            `
                        })

                    }
                }

                cerrarModal()
                this.cargarActas()

            }).catch(err=>{
                console.log(err)
                alert("Ha ocurrido un error al actualizar el acta.Pongase en contacto con el servicio tecnico")
            })

        }

        // const añadirAImprimir = () => {
        //     alert("Aqui añadimos lo que vayamos a imprimir")
        // }

        const handleJoyrideCallback=async(data)=>{
            
            const { action, index, status, type } = data;
         
            if(data.action==="close"){

                localStorage.setItem("ayuda",false)
                this.setState({
                    modalNuevoAbierto:false
                })
                this.calendarioDerecha.setState({
                    modalNuevoAbierto:false,
                    modalInscripcion:false,
                    modalImpugnacion:false,
                    modalCertificacion:false
                })

            }else{

                if ([EVENTS.STEP_AFTER].includes(type)) {
    
                    let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
        
                    // paso en el que nos encontremos.
                    // eslint-disable-next-line default-case
                    switch(paso){
                        
                        case 1:
                            await this.setState({modalNuevoAbierto:false,nuevaActa:false})
                        break;
    
                        case 2: 
                            await this.setState({modalNuevoAbierto:true,nuevaActa:true})
                        break;              
                        case 3: 
                        case 4:
                            await this.setState({ pestañaDialog: "datos-acta" })
                        break;
                        case 5:
                        case 6:
                            await this.setState({ pestañaDialog: "asistentes" })
                        break;
                        case 7:
                            await this.setState({ pestañaDialog: "datos-acta" })
                        break;
                        case 8:
                            await this.setState({ pestañaDialog: "resumen" })
                        break;
                        case 9:
                            await this.setState({ pestañaDialog: "informacion",modalNuevoAbierto:true,nuevaActa:true })
                        break;
                        case 10:
                            await this.setState({ modalNuevoAbierto:false,nuevaActa:false })
                        break;
                        case 11:
                                let acta={
                                    ID_ACTA:0,
                                    adjunto:null,
                                    asistentes:[],
                                    FECHA_REUNION:new Date(),
                                }
                                await this.setState({ pestañaDialog:"adjuntos",actaSeleccionada:acta, modalNuevoAbierto:true,editarActa:true })
                                await this.calendarioDerecha.setState({ modalNuevoAbierto:false,nuevoActivado:false })
    
                        break;
                        case 12:
                                await this.setState({ modalNuevoAbierto:false,editarActa:false })
                                await this.calendarioDerecha.setState({ modalNuevoAbierto:false,nuevoActivado:false })
                        break;
                        
                        case 13:
                                await this.calendarioDerecha.setState({ modalNuevoAbierto:true,nuevoActivado:true })
                        break;
                        case 15:
                            await this.calendarioDerecha.setState({ pestaña:"datos-acuerdo" })
                        break;
                        case 16:
                            await this.calendarioDerecha.setState({ pestaña:"votos" })
                        break;
                        case 17:
                            await this.calendarioDerecha.setState({ pestaña:"datos-acuerdo" })
                        break;
                        case 18:
                            await this.calendarioDerecha.setState({ pestaña:"texto",modalNuevoAbierto:true,nuevoActivado:true  })
                        break;
                        case 19:
                            await this.calendarioDerecha.setState({ modalNuevoAbierto:false,nuevoActivado:false,modalInscripcion:false })
                        break;
                        case 20:
                            await this.calendarioDerecha.setState({ modalInscripcion:true })
                        break;
                        case 21:
                            await this.calendarioDerecha.setState({ modalInscripcion:false,modalCertificacion:false })
                        break;
                        case 22:
                            await this.calendarioDerecha.setState({ modalCertificacion:true })
                        break;
                        case 23:
                            await this.calendarioDerecha.setState({ modalCertificacion:false,modalImpugnacion:false })
                        break;
                        case 24:
                            await this.calendarioDerecha.setState({ modalImpugnacion:true })
                        break;
                        case 25:
                            await this.calendarioDerecha.setState({ modalImpugnacion:false })
                            await this.calendarioDerecha.setState({ modalNuevoAbierto:false,nuevoActivado:false,editarActivado:false })
                        break;
                        case 26:
                            await this.calendarioDerecha.setState({ nuevoActivado:false,editarActivado:true,pestaña:"adjuntos",modalImpugnacion:false,modalNuevoAbierto:true,acuerdoSeleccionado:{datosImpugnacion:{},votos:[]} })
                        break;
                        case 27:
                            await this.calendarioDerecha.setState({ nuevoActivado:false,modalNuevoAbierto:false,editarActivado:false,modalImpugnacion:false})
                        break;
                        case 28:
                            await this.calendarioDerecha.setState({ pestañaImpugnacion:"anulacion",modalImpugnacion:true})
                        break;
                        case 29:
                            await this.calendarioDerecha.setState({pestañaImpugnacion:"adjuntos"})
                        break;
    
                    }
              
                    await this.setState({ pasoGuia: paso });   
        
        
                }
        
                //si termina la guia.
                if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                    localStorage.setItem("ayuda",false)
                    await this.calendarioDerecha.setState({ modalImpugnacion:false})
                    await this.setState({actaSeleccionada:null})
                }

            }
    
         
    
        }

        return (
            <>
            {this.state.cargando?
                    <Cargando/>
            :
            <>
                <Joyride
                    run={localStorage.getItem("ayuda")==="true"?true:false}
                    callback={handleJoyrideCallback}
                    showProgress={true}
                    stepIndex={this.state.pasoGuia}
                    steps={steps}
                    continuous ={true}
                    locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            primaryColor: '#E31E24',
                            }
                    }}
                />
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title="Seleccione el/los órgano(s) de los cuales desea generar el libro de actas y pulse aceptar"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalLibroActas}
                >
                <div className={Classes.DIALOG_BODY}>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Select
                        options={this.state.organos}
                        className="pixelAlrededor"
                        backspaceRemovesValue={true}
                        onChange={seleccionarOrgano}
                        isClearable={true}
                        value={this.state.organoSeleccionado}
                    />
                </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <ReactToPrint
                            trigger={() => <AnchorButton intent={Intent.PRIMARY} disabled={this.state.cargando || !this.state.organoSeleccionado || this.state.libros.length===0} onClick={()=>{this.setState({modalLibroActas:false})}}>{this.state.cargando?"Cargando...":"SI"}</AnchorButton>}
                            content={() => this.libros}
                        />
                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({modalLibroActas:false})}}>
                        NO
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>

                <Dialog 
                    transitionDuration={400}
                    className="calendario-wrapper dialog-ancho"
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.state.nuevaActa?"Añadir nueva acta":"Editar acta"}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                >
                    <div id="dialogo-actas">
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <Tabs 
                            id="propiedades-acta"
                            animate={this.animacion} 
                            onChange={cambiarPestaña} 
                            selectedTabId={this.state.pestañaDialog}
                            renderActiveTabPanelOnly={false}
                        >
                            <Tab id="datos-acta" title="Datos del acta" panel={<DatosActa fechaReunion={this.calendario?new Date(this.calendario.state.fechaSeleccionada):new Date()} editable={this.props.editable} setConvocados={this.setConvocados}  ref={(input) => { this.DatosActa= input; }} setNumeroAsistentes={this.setNumeroAsistentes} acta={this.state.actaSeleccionada} asistentes={this.state.asistentes} setAsistentes={this.setAsistentes} numeroAsistentes={this.state.numeroAsistentes} />} />
                            <Tab id="asistentes" title="Asistentes" panel={<Asistentes editable={this.props.editable} setConvocados={this.setConvocados}  ref={(input) => { this.DatosAsistentes= input; }} setNumeroAsistentes={this.setNumeroAsistentes} acta={this.state.actaSeleccionada} setAsistentes={this.setAsistentes} convocados={this.state.convocados} />} />
                            <Tab id="resumen" title="Resumen" panel={<Resumen editable={this.props.editable} ref={(input) => { this.DatosResumen= input; }} acta={this.state.actaSeleccionada}/>} />
                            <Tab id="adjuntos" title="Archivos Adjuntos" panel={<AdjuntosActa cargarActas={this.cargarActas} editable={this.props.editable} ref={(input) => { this.DatosAdjuntosActa= input; }}  acta={this.state.actaSeleccionada} isNew={this.state.nuevaActa?true:false}/>} />
                            
                            <Tab id="informacion" title="Información" panel={<Informacion editable={this.props.editable} ref={(input) => { this.DatosInformacion= input; }}  />} />
                            
                        </Tabs>
                    </Card>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content="Añadir la acta." position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaActa?anadirActa:actualizarActa}
                            >
                                {this.state.nuevaActa?"Añadir Acta":"Actualizar acta"}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div className="pixelAlrededor" id="vista-calendario-actas">
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" id="btn-nueva-acta" icon="add" text="Subir acta" active={this.state.nuevaActa} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" id="btn-editar-acta" icon="edit" text="Editar" active={this.state.editarActa} onClick={abrirModalEditar} />
                                <NavbarDivider />
                                <div style={{display:"none"}}>
                                    <this.LibroActas numPages={numPages} pageNumber={pageNumber}   />
                                </div>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <div className="pixelAlrededor">
                        <Tabs id="vista-calendario"
                            animate={this.animacion} 
                            onChange={this.cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                        >
                            <Tab id="vista-listado" title="Vista de listado" panel={<VistaListadoActas acta={this.state.actaSeleccionada} setActaSeleccionada={this.setActaSeleccionada} actas={this.state.actas} />} />
                            <Tab id="informacion" title="Información" panel={<InformacionActas />} />
                        </Tabs>
                    </div>
                    {this.state.cargandoAsistente?
                      <Cargando/>
                    :
                    <div className="pixelAlrededor">
                        <CalendarioActasDerecha recargar={this.recargar} ref={(input) => { this.calendarioDerecha= input; }} editable={this.props.editable} cargarActas={this.cargarActas} acta={this.state.actaSeleccionada} acuerdos={this.state.acuerdos} cargarAcuerdos={this.cargarAcuerdos}/>
                    </div>
                    }
                </div>
            </>
            }
          </>   
        )
    }
}export default withApollo(CalendarioActas)