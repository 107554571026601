import React, { Component } from 'react';
import { FormGroup, Card, Elevation, Button, Callout,Dialog,Classes,AnchorButton,Intent } from "@blueprintjs/core"
// import Documento from "../../Assets/Legislacion1.pdf"
import Select from "react-select"
// import Documento2 from "../../Assets/Legislacion1.pdf"
import ReactToPrint from "react-to-print";
// import html2canvas from "html2canvas"
// import jsPDF from 'jspdf';
import axios from 'axios';
import { 
    Document, Page ,pdfjs
} from 'react-pdf';
// import { PDFDownloadLink} from '@react-pdf/renderer';
// import { thisExpression } from '@babel/types';
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
// import ReactDOMServer from "react-dom/server";
// import ReactDOM from 'react-dom';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class LibroSocios extends Component {
    state={
        subida1Activada: false,
        subida2Activada: false,
        edicion1Activada: false,
        edicion2Activada: false,
        numPages: [],
        pageNumber: 1,
        cargando:false,
        modalLibroActas:false,
        organoSeleccionado:null,
        organos:[],
        libros:[],
        cargados:[]
    }

    onDocumentLoad = async({numPages},index) => {
        let nPages=this.state.numPages
        nPages[index]=(numPages)
        let cargados=this.state.cargados
        cargados.push(true)
        if(cargados.length===this.state.libros.length)this.setState({cargando:false})
        await this.setState({ numPages:nPages,cargados:cargados});

    }


    LibroActas=(props)=>{
        
        let libros=[]
    
        for( let libro of this.state.libros){
            let obj=(libro.documento)
            libros.push(
                <>
                    <Document
                        file={`data:application/pdf;base64,${obj}`}
                        onLoadSuccess={(e) => {this.onDocumentLoad(e,libro.index)}}
                    >
                    <h1 className="cabecera-pdf-actas">{`Acta No. ${libro.numero}`}</h1>
                    <br />
                    { this.mostrarPaginas(props.numPages[libro.index])}
                    <br />
                    </Document>
                </>
            )
        }
        
        return (<div ref={(input) => { this.libros = input; }}>{libros}</div>)

    }
    mostrarPaginas=(numPages)=>{

        let Pages=[]
        for(let i=1;i<=numPages;i++){
            Pages.push(<Page pageNumber={i} width={960}/>)
        }
        return Pages
    }

    cargarPdfs=async (acta,index,pdfs)=>{
        await this.props.client.query({
            query:gql`{actasPdf(FILTROS:{ID_ACTA:${acta.ID_ACTA}}){RUTA_ARCHIVO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            if(result.data.actasPdf.length>0){

                const api = axios.create({
                    withCredentials: true
                });

                await api.get("https://localhost:4000"+result.data.actasPdf[0].RUTA_ARCHIVO.substring(1,result.data.actasPdf[0].RUTA_ARCHIVO.length), {responseType: 'arraybuffer'}).then(async(response)=> {
                    pdfs.push({
                        documento:new Buffer(response.data, 'binary').toString('base64'),
                        numero:acta.NUMERO,
                        index:index
                    })

                })

            }
            
        });
        return pdfs;
    }

    cargarLibros=async()=>{

        
        let pdfs=[]
        let index=0
        await this.setState({libros:[],cargados:[],cargando:true})
        await this.props.client.query({
            query:gql`{actas(ORDEN:"NUMERO,ASC",CAMPOS:"ID_ACTA,ID_TIPO_CONVOCATORIA,NUMERO",FILTROS:{ID_TIPO_CONVOCATORIA:${this.state.organoSeleccionado.value}}){ID_ACTA,NUMERO}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            for(let acta of result.data.actas){

                
                pdfs=await this.cargarPdfs(acta,index,pdfs);
                index++
            }

        })
  

        if(pdfs.length===0){
            alert("Este tipo de reunión no tiene ningún acta con documentos.")
            this.setState({cargando:false})
            return
        }
        await this.setState({libros:pdfs})
    }

    componentDidMount=()=>{

        this.props.client.query({
            query:gql`{tipoConvocatorias{ID_TIPO_CONVOCATORIA,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            let tipoConvocatorias=[]
            for(let tipo of result.data.tipoConvocatorias){
                tipoConvocatorias.push({
                    label: tipo.DESCRIPCION,
                    value: tipo.ID_TIPO_CONVOCATORIA,
                })
            }
            this.setState({organos:tipoConvocatorias})
           
        })
    }

    render() {

        document.title ="LexControl | Libros"
        const { pageNumber, numPages } = this.state;

        const seleccionarOrgano = async(value) => {
            await this.setState({organoSeleccionado: value})
            await this.cargarLibros()
        }

        return (
            <div>

                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={()=>{this.setState({modalLibroActas:false})}}
                    title="Seleccione el/los órgano(s) de los cuales desea generar el libro de actas y pulse aceptar"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalLibroActas}
                >
                <div className={Classes.DIALOG_BODY}>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Select
                        options={this.state.organos}
                        className="pixelAlrededor"
                        backspaceRemovesValue={true}
                        onChange={seleccionarOrgano}
                        isClearable={true}
                        value={this.state.organoSeleccionado}
                    />
                </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <ReactToPrint
                            trigger={() => <AnchorButton intent={Intent.PRIMARY} disabled={this.state.cargando || !this.state.organoSeleccionado || this.state.libros.length===0} onClick={()=>{this.setState({modalLibroActas:false})}}>{this.state.cargando?"Cargando...":"SI"}</AnchorButton>}
                            content={() => this.libros}
                        />
                    <AnchorButton intent={Intent.PRIMARY} onClick={()=>{this.setState({modalLibroActas:false})}}>
                        NO
                    </AnchorButton>
                    </div>
                </div>
                </Dialog>

                <Card interactive={true} elevation={Elevation.TWO} id="informe-anual">
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">Imprimir libro de actas</Callout>
                        <FormGroup
                            label="Para generar el documento, pulse aqui: "
                            labelFor="informacion"
                            inline={true}
                        >
                        <Button className="bp3-minimal" icon="print" text="Creación del libro de actas"  onClick={()=>{this.setState({modalLibroActas:true,organoSeleccionado:null})}} />
                        <div style={{display:"none"}}>
                            <this.LibroActas numPages={numPages} pageNumber={pageNumber}   />
                        </div>
                 
                        </FormGroup>
                </Card>
            </div>
        )
    }
}export default withApollo(LibroSocios)