/* eslint-disable array-callback-return */
import React from "react"
import {Spinner, Button, Dialog, InputGroup, Navbar,FormGroup, TextArea, Classes, Tooltip, AnchorButton, Intent, Switch, Elevation, Tabs, Tab } from "@blueprintjs/core"
import { Table, Column, Cell,RegionCardinality} from "@blueprintjs/table"
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import { Callout, Card } from "@blueprintjs/core";
import moment from "moment";
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"


import styles from '../stylesPrint';
import { DatosPersonasFisicas } from "./DatosPersonasFisicas";
import InformacionGeneralNuevo from "./InformacionGeneralNuevo";
import MediosContacto from "./MediosContacto";
import CentrosTrabajo from "./CentrosTrabajo";
import ArchivosAdjuntos from "./ArchivosAdjuntos";
import Documentos from "./Documentos";
import Vinculos from "./Vinculos";
import i18next from 'i18next';


let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});


export default class OtrosDatos extends React.Component {

    state={
        socios:[],
        modalAbierto: false,
        socioSeleccionado: null,
        prestaciones: null,
        ventajas: null,
        excluido: false,
        columnWidths: [100,100,100,100,100,100,100,100,100,100],
        resized: false,
        cargandoImprimir:false,
        cargandoImprimirGral:false,
        modalImprimir:false,
        modalImprimirGral: false,
        modalEditarPersona: false,
        editarPersonaActivado: false,
        modalEditarPersonaJuridica: false,
        cargandoPersonas: false
    }

    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaSocios.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaSocios.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaSocios.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaSocios.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }


   cargarSocios=async()=>{
    let personasFisicas= await client
    .query({
        query: gql`
            {personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
    return result.data.personasFisicas
    }) 
    let personasJuridicas=  await client
    .query({
        query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
        return result.data.personasJuridicas
    })          
    let documentos = await client
    .query({
        query: gql`
        {
            documentosIdentificativos{
                ID_TIPO_DOCUMENTO,
                ID_PERSONA,  
                VALOR
            }
        }
        `,fetchPolicy:'network-only'
    })
        .then(result => {       
        return result.data.documentosIdentificativos;
    });
    let transmisiones=await client.query({
        query:gql`{capSocialTransmisiones{
            FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
        }}`,fetchPolicy:'network-only'
    }).then(async(result)=>{
        return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
    })
    let otrosDatos= await client
    .query({
        query: gql`
        {sociosOtrosDatos{
            ID_SOCIO,
            PRESTACIONES_ACCESORIAS,
            EXCLUIDO,
            VENTAJAS,
            }
        }
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
        return result.data.sociosOtrosDatos
    })
    let capitalActual=await client
    .query({
        query: gql`
            {capSocialActualDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
        `,fetchPolicy:'network-only'
    }).then(async result=>{ 
        return result.data.capSocialActualDistribucion
    })        
            let socios=[]
            for(let socio of this.props.capSocialInicialDistribucion){
               
                let nombreSocio=""
                let id = 0
                let tipo_persona = ""
                let documento= ""

                //Si es persona fisica...
             
                if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    personasFisicas.find(persona => {
                        if(persona.ID_PERSONA===socio.ID_PERSONA){
                            documentos.forEach(doc => {                                
                                if (doc.ID_TIPO_DOCUMENTO === 1 && doc.ID_PERSONA === socio.ID_PERSONA) {
                                    documento= doc.VALOR                                    
                                }
                            })
                            
                                id = persona.ID_PERSONA
                                tipo_persona= socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                                nombreSocio=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                                socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento
                                })       
                            
                        }
                    })
                

                }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    personasJuridicas.find(persona => {
                        if (persona.ID_PERSONA === socio.ID_PERSONA) {                            
                            documentos.forEach(doc=>{
                                if(doc.ID_TIPO_DOCUMENTO===4 && doc.ID_PERSONA===socio.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })
                            id = persona.ID_PERSONA
                            tipo_persona= socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                            nombreSocio=persona.RAZON_SOCIAL
                            socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento})
                        }
                    })
                }

            }
            for(let transmision of transmisiones){
                let nombreSocio=""
                let id = 0
                let tipo_persona = ""
                let documento= ""
                //Si es persona fisica...
                if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    // eslint-disable-next-line array-callback-return
                    personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if( doc.ID_TIPO_DOCUMENTO===1 && doc.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                                    documento = doc.VALOR                            
                                }
                            })
                            
                                    id = persona.ID_PERSONA
                                    tipo_persona=transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA
                                    nombreSocio=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                                    socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento
                                })
                        }
                    })
                   //Si es persona juridica
                }else if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if( doc.ID_TIPO_DOCUMENTO===4 && doc.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })      

                                    id=persona.ID_PERSONA
                                    tipo_persona=transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA
                                    nombreSocio=persona.RAZON_SOCIAL
                                    socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento
                                })
                        }
                    })
                }

            }
           
            for(let socActual of capitalActual){
                let nombreSocio=""
                let id = 0
                let tipo_persona = ""
                let documento= ""
                if(socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    // eslint-disable-next-line array-callback-return
                    personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===socActual.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if(doc.ID_TIPO_DOCUMENTO===1 && doc.ID_PERSONA===socActual.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })
                            id = persona.ID_PERSONA
                            tipo_persona= socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                            nombreSocio=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                            socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,"docIdentificativo":documento})
                        
                        }
                    })
                }else if(socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===socActual.ID_PERSONA){
                            documentos.forEach(doc=>{
                                if(doc.ID_TIPO_DOCUMENTO===4 && doc.ID_PERSONA===socActual.ID_PERSONA){
                                    documento = doc.VALOR
                                }
                            })
                            id = persona.ID_PERSONA
                            tipo_persona= socActual.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA
                            nombreSocio=persona.RAZON_SOCIAL
                            socios.push({"id":id, "tipo_persona":tipo_persona, "nombre":nombreSocio,"nacionalidad":persona.NACIONALIDAD,"domicilio":persona.DOMICILIO,"localidad":persona.LOCALIDAD,"fecha_alta":persona.FECHA_ALTA,"fecha_baja":persona.FECHA_BAJA,"observaciones":persona.OBSERVACIONES,
                                    "docIdentificativo":documento})
                        }
                    })
                }
            }

            let socio= socios.filter((e, i) => {
                return socios.findIndex((x) => {
                return x.id ===  e.id}) === i;
            });

            for(let soc of socio){
                //    Obtenemos los socios otros datos.
                if(otrosDatos.length>0){
                    otrosDatos.find(datos=>{
                        if(datos.ID_SOCIO===soc.id){
                            soc.datosSocios=datos
                        }
                    })       
                }
       }
       this.setState({ socios: socio })
                

   }

    // cargarSocios=async()=>{

    //     //relleno  de la lista de socios.
    // //    let capInicial= 
    // //    await client
    // //     .query({
    // //         query: gql`
    // //             {capSocialInicialDistribucion{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
    // //         `,fetchPolicy:'network-only'
    // //     }).then(async result=>{ 
    // //         return result.data.capSocialInicialDistribucion
    // //     })
    
    //         let docVal=[]
    //         let socios=[]
    //         for(let socio of this.props.capSocialInicialDistribucion){
              
    //             let nombreSocio=""
    //             let id=0

    //             //Si es persona fisica...
             
    //             if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){    
    
                 
    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasFisicas(ORDEN:"APELLIDO1",FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO: 1, ID_PERSONA: ${socio.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
    //                     console.log(doc)
    //                     id=result.data.personasFisicas[0].ID_PERSONA
    //                     nombreSocio=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasFisicas[0].NACIONALIDAD,"domicilio":result.data.personasFisicas[0].DOMICILIO,"localidad":result.data.personasFisicas[0].LOCALIDAD,"fecha_alta":result.data.personasFisicas[0].FECHA_ALTA,"fecha_baja":result.data.personasFisicas[0].FECHA_BAJA,"observaciones":result.data.personasFisicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})
    //                 })

    //             //Si es persona juridica
    //             }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                   

    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasJuridicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO: 4, ID_PERSONA: ${socio.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
    //                     console.log(doc)
    //                     id=result.data.personasJuridicas[0].ID_PERSONA
    //                     nombreSocio=result.data.personasJuridicas[0].RAZON_SOCIAL
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasJuridicas[0].NACIONALIDAD,"domicilio":result.data.personasJuridicas[0].DOMICILIO,"localidad":result.data.personasJuridicas[0].LOCALIDAD,"fecha_alta":result.data.personasJuridicas[0].FECHA_ALTA,"fecha_baja":result.data.personasJuridicas[0].FECHA_BAJA,"observaciones":result.data.personasJuridicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})
    //                 })
            

    //             }

    //         }
            

    //         let transmisiones=await client.query({
    //             query:gql`{capSocialTransmisiones{
    //                 FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //             }}`,fetchPolicy:'network-only'
    //         }).then(async(result)=>{
    //             return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
    //         })

    //         for(let transmision of transmisiones){
    //             let nombreSocio=""
    //             let id=0
    //             //Si es persona fisica...
    //             if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    
    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasFisicas(ORDEN:"APELLIDO1",FILTROS:{ID_PERSONA:${transmision.FK_DESTINO.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO: 1, ID_PERSONA: ${transmision.FK_DESTINO.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
    //                     console.log(doc)
    //                     id=result.data.personasFisicas[0].ID_PERSONA
    //                     nombreSocio=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasFisicas[0].NACIONALIDAD,"domicilio":result.data.personasFisicas[0].DOMICILIO,"localidad":result.data.personasFisicas[0].LOCALIDAD,"fecha_alta":result.data.personasFisicas[0].FECHA_ALTA,"fecha_baja":result.data.personasFisicas[0].FECHA_BAJA,"observaciones":result.data.personasFisicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})
    //                 })

    //             //Si es persona juridica
    //             }else if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    
                      
    
    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasJuridicas(FILTROS:{ID_PERSONA:${transmision.FK_DESTINO.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 

    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO: 4, ID_PERSONA: ${transmision.FK_DESTINO.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
    //                     console.log(doc)
    //                     id=result.data.personasJuridicas[0].ID_PERSONA
    //                     nombreSocio=result.data.personasJuridicas[0].RAZON_SOCIAL
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasJuridicas[0].NACIONALIDAD,"domicilio":result.data.personasJuridicas[0].DOMICILIO,"localidad":result.data.personasJuridicas[0].LOCALIDAD,"fecha_alta":result.data.personasJuridicas[0].FECHA_ALTA,"fecha_baja":result.data.personasJuridicas[0].FECHA_BAJA,"observaciones":result.data.personasJuridicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})
    //                 })

    //             }

    //         }
           
           

    //         let socio= socios.filter((e, i) => {
    //             return socios.findIndex((x) => {
    //             return x.id ===  e.id}) === i;
    //         });

    //         for(let soc of socio){

    //             //    Obtenemos los socios otros datos.
               
    //                 await client
    //                 .query({
    //                     query: gql`
    //                     {sociosOtrosDatos(FILTROS:{ID_SOCIO:${soc.id}}){
    //                         ID_SOCIO,
    //                         PRESTACIONES_ACCESORIAS,
    //                         EXCLUIDO,
    //                         VENTAJAS,
    //                         }
    //                     }
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     if(result.data.sociosOtrosDatos.length>0){
    //                         soc.datosSocios=result.data.sociosOtrosDatos[0]
    //                     }
    //                     console.log(soc.datosSocios)
    //                 })
    //         }
         
           
    
    //         this.setState({socios:socio})
        
    

    //     await client
    //     .query({
    //         query: gql`
    //             {capSocialActualDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
    //         `,fetchPolicy:'network-only'
    //     }).then(async result=>{ 

    //         let socios=[]
    //         for(let socio of result.data.capSocialActualDistribucion){
    //             let nombreSocio=""
    //             let id=0

    //             //Si es persona fisica...
    //             if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasFisicas(ORDEN:"APELLIDO1",FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO: 1, ID_PERSONA: ${socio.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
                       
    //                     id=result.data.personasFisicas[0].ID_PERSONA
    //                     nombreSocio=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasFisicas[0].NACIONALIDAD,"domicilio":result.data.personasFisicas[0].DOMICILIO,"localidad":result.data.personasFisicas[0].LOCALIDAD,"fecha_alta":result.data.personasFisicas[0].FECHA_ALTA,"fecha_baja":result.data.personasFisicas[0].FECHA_BAJA,"observaciones":result.data.personasFisicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})
    //                 })

    //             //Si es persona juridica
    //             }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasJuridicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO: 4, ID_PERSONA: ${socio.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
    //                     console.log(doc)
    //                     id=result.data.personasJuridicas[0].ID_PERSONA
    //                     nombreSocio=result.data.personasJuridicas[0].RAZON_SOCIAL
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasJuridicas[0].NACIONALIDAD,"domicilio":result.data.personasJuridicas[0].DOMICILIO,"localidad":result.data.personasJuridicas[0].LOCALIDAD,"fecha_alta":result.data.personasJuridicas[0].FECHA_ALTA,"fecha_baja":result.data.personasJuridicas[0].FECHA_BAJA,"observaciones":result.data.personasJuridicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})
    //                 })

    //             }

    //         }

    //         let transmisiones=await client.query({
    //             query:gql`{capSocialTransmisiones{
    //                 FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //             }}`,fetchPolicy:'network-only'
    //         }).then(async(result)=>{
    //             return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
    //         })

    //         for(let transmision of transmisiones){
    //             let nombreSocio=""
    //             let id=0
    //             //Si es persona fisica...
    //             if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasFisicas(ORDEN:"APELLIDO1",FILTROS:{ID_PERSONA:${transmision.FK_DESTINO.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO:1, ID_PERSONA: ${transmision.FK_DESTINO.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
    //                     id=result.data.personasFisicas[0].ID_PERSONA
    //                     nombreSocio=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasFisicas[0].NACIONALIDAD,"domicilio":result.data.personasFisicas[0].DOMICILIO,"localidad":result.data.personasFisicas[0].LOCALIDAD,"fecha_alta":result.data.personasFisicas[0].FECHA_ALTA,"fecha_baja":result.data.personasFisicas[0].FECHA_BAJA,"observaciones":result.data.personasFisicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})
    //                 })

    //             //Si es persona juridica
    //             }else if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
    //                 await client
    //                 .query({
    //                     query: gql`
    //                         {personasJuridicas(FILTROS:{ID_PERSONA:${transmision.FK_DESTINO.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,NACIONALIDAD{DESCRIPCION},DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,OBSERVACIONES}}
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     let doc = await client
    //                     .query({
    //                         query: gql`
    //                         {
    //                             documentosIdentificativos(
    //                             FILTROS: { ID_TIPO_DOCUMENTO: 4, ID_PERSONA: ${transmision.FK_DESTINO.ID_PERSONA} }
    //                             ) {
    //                               VALOR
    //                             }
    //                         }
    //                         `,fetchPolicy:'network-only'
    //                     })
    //                     .then(result => {
    //                         return result.data.documentosIdentificativos;
    //                     });
    //                     id=result.data.personasJuridicas[0].ID_PERSONA
    //                     nombreSocio=result.data.personasJuridicas[0].RAZON_SOCIAL
    //                     socios.push({"id":id,"nombre":nombreSocio,"nacionalidad":result.data.personasJuridicas[0].NACIONALIDAD,"domicilio":result.data.personasJuridicas[0].DOMICILIO,"localidad":result.data.personasJuridicas[0].LOCALIDAD,"fecha_alta":result.data.personasJuridicas[0].FECHA_ALTA,"fecha_baja":result.data.personasJuridicas[0].FECHA_BAJA,"observaciones":result.data.personasJuridicas[0].OBSERVACIONES,"docIdentificativo":doc.length>0?doc[0].VALOR:""})

    //                 })

    //             }

    //         }


    //         let socs= socios.filter((e, i) => {
    //             return socios.findIndex((x) => {
    //             return x.id ===  e.id}) === i;
    //         });

    //         for(let soc of socs){

    //                //Obtenemos los socios otros datos.
    //                 await client
    //                 .query({
    //                     query: gql`
    //                     {sociosOtrosDatos(FILTROS:{ID_SOCIO:${soc.id}}){
    //                         ID_SOCIO,
    //                         PRESTACIONES_ACCESORIAS,
    //                         EXCLUIDO,
    //                         VENTAJAS,
    //                         }
    //                     }
    //                     `,fetchPolicy:'network-only'
    //                 }).then(async result=>{ 
    //                     if(result.data.sociosOtrosDatos.length>0){
    //                         soc.datosSocios=result.data.sociosOtrosDatos[0]
    //                     }
    //                 })
    //         }
    //         console.log(socs)
    //         this.setState({socios:socs})

    //     })

    // }
    generarImprimirOD= async()=>{
        this.setState({cargandoImprimir:true, modalImprimir:true}, async() =>{
            this.setState({cargandoImprimir:false},() =>{
                ReactDOM.render(this.ImprimirOD(), document.getElementById('generarImprimir-pdf'))
            })
        })
    }

    ImprimirOD=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
            <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.body}>
                    <View fixed style={styles.row}>
                    <Image
                        style={styles.imageHead}
                        src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                        />
                    </View>
                    <View fixed style={styles.rowHead}>
                        <Text style={styles.labelHead}>EMPRESA:</Text>
                        <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                    </View>
                    <View fixed style={styles.rowHeadTwo}>
                        <Text style={styles.labelHead}>INFORME:</Text>
                        <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                    </View>
                    <View style={styles.rowTitleOne}>
                        <Text style={styles.labelTitle}>OTROS DATOS DE SOCIO</Text>
                    </View>
                        {this.state.socioSeleccionado?
                             
                            <View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>NOMBRE SOCIO:</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.nombre?this.state.socioSeleccionado.nombre:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>PRESTACIONES ACCESORIAS:</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.datosSocios?this.state.socioSeleccionado.datosSocios.PRESTACIONES_ACCESORIAS:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>VENTAJAS DE LOS SOCIOS FUNDADORES DE LAS SOCIEDADES ANÓNIMAS:</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.datosSocios?this.state.socioSeleccionado.datosSocios.VENTAJAS:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>EXCLUIDO (Art. 350 Y Siguiente):</Text>
                                    <Text style={styles.textData}>{this.state.socioSeleccionado.datosSocios && this.state.socioSeleccionado.datosSocios.EXCLUIDO===true?"SI":"NO"}</Text>
                                </View>
                                
                            </View>
                                                  
                        :<></>}      
                </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>   
    )

    generarImprimirGral= async() =>{
        this.setState({
            modalImprimirGral:true,
            cargandoImprimirGral:true
        }, async () =>{
            this.setState({
                cargandoImprimirGral:false
            }, () =>{
                ReactDOM.render(this.ImprimirGral(), document.getElementById('generarImprimirGral-pdf'))
            })
        })
    }

    ImprimirGral=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>OTROS DATOS DE SOCIOS</Text>
                </View>
                    {this.state.socios?this.state.socios.map((socio, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>SOCIO</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>NIF/CIF</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>NACIONALIDAD</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>DOMICILIO</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>LOCALIDAD</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>FECHA ALTA</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>FECHA BAJA</Text>                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>OBSERVACIONES</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>PRESTACIONES ACCESORIAS</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 0, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>VENTAJAS</Text>                                                                
                                                                
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{socio.nombre?socio.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{socio.docIdentificativo?socio.docIdentificativo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.nacionalidad?socio.nacionalidad.DESCRIPCION:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.domicilio?socio.domicilio:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.localidad?socio.localidad:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.fecha_alta?moment(socio.fecha_alta).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.fecha_baja?moment(socio.fecha_baja).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.observaciones?socio.observaciones:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.datosSocios && socio.datosSocios.PRESTACIONES_ACCESORIAS?"SI":"NO"}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 0,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{socio.datosSocios && socio.datosSocios.VENTAJAS?"SI":"NO"}</Text>
                                 </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    componentDidMount(){
        this.setState({cargando:true}, async()=>{
            await this.cargarSocios()
            this.setState({cargando:false})
        })
    }
    

    render(){

        document.title ="LexControl | Otros datos"

        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirGral:false,
                modalImprimir:false
            })
        }

        const cellRendererSocio = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].nombre}`}</Cell>
        }

        const cellRendererNacionalidad = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].nacionalidad?this.state.socios[rowIndex].nacionalidad.DESCRIPCION:""}`}</Cell>
        }

        const cellRendererDomicilio = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].domicilio?this.state.socios[rowIndex].domicilio:""}`}</Cell>
        }

        const cellRendererLocalidad = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].localidad?this.state.socios[rowIndex].localidad:""}`}</Cell>
        }

        const cellRendererFechaAlta = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].fecha_alta?moment(this.state.socios[rowIndex].fecha_alta).format("DD-MM-YYYY"):""}`}</Cell>
        }

        const cellRendererFechaBaja = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].fecha_baja?moment(this.state.socios[rowIndex].fecha_baja).format("DD-MM-YYYY"):""}`}</Cell>
        }

        const cellRendererObservaciones = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].observaciones?this.state.socios[rowIndex].observaciones:""}`}</Cell>
        }

        const cellRendererDocIdentificativo = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].docIdentificativo?this.state.socios[rowIndex].docIdentificativo:""}`}</Cell>
        }

        const cellRendererPrestaciones = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].datosSocios && this.state.socios[rowIndex].datosSocios.PRESTACIONES_ACCESORIAS?"SI":"NO"}`}</Cell>
        }

        const cellRendererVentajas = (rowIndex) => {
            return <Cell>{`${this.state.socios[rowIndex].datosSocios && this.state.socios[rowIndex].datosSocios.VENTAJAS?"SI":"NO"}`}</Cell>
        }

        
        const seleccionarSocio =async (value) => {


            if(value.length===0)return
            let seleccionada=this.state.socios[value[0].rows[0]]       


            //limpiamos los campos
            this.setState({
                ventajas:null,
                prestaciones:null,
                excluido:false
            })

            
            if(seleccionada.datosSocios){

                this.setState({
                    ventajas:seleccionada.datosSocios.VENTAJAS,
                    prestaciones:seleccionada.datosSocios.PRESTACIONES_ACCESORIAS,
                    excluido:seleccionada.datosSocios.EXCLUIDO
                })

            }
            
           
            this.setState({
                modalAbierto: true,
                socioSeleccionado:seleccionada,
                regionSeleccionada:value
            })
            

        }
        const abrirModalEditarPersona = () => {
            if (!this.state.socioSeleccionado) {
                alert("Debe seleccionar un Socio.")
                return
            }
            //no se si hace falta ya que no hay otro estado que abra el componente
            this.setState({ editarPersonaActivado: true })
            
            if (this.state.socioSeleccionado.tipo_persona === 1) {
                this.setState({modalEditarPersona: true})
            } else if (this.state.socioSeleccionado.tipo_persona === 2) {
                this.setState({modalEditarPersonaJuridica: true})
            }
        }
            

        
        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                
            })
        }

        const cerrarModal2 = () => {
            this.setState({
                modalEditarPersona: false,
                editarPersonaActivado:false,
                
            })
        }

        const cerrarModalPersonasJurid =  () => {
            this.setState({
                cargandoPersonas :true
            },async () => {
                await this.cargarSocios()
                this.setState({                
                    modalEditarPersonaJuridica: false,
                    cargandoPersonas: false,
                   
                })
                
            })
            
           
        }

        const handlePrestaciones = (e) => {
            this.setState({ prestaciones: e.target.value })
        }

        const handleVentajas = (e) => {
            this.setState({ ventajas: e.target.value })
        }

        const handleExcluido = () => {
            this.setState({ excluido: !this.state.excluido })
        }

        const actualizarPersona = async () => {
            console.log(this.state.socioSeleccionado)
            await this.datosPersona.actualizarPersona()
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarSocios()
            //cerrar el modal
            cerrarModal2()
            }
        }

        const guardarDatos = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            //si ya tenemos los datos de los socios.
            if(this.state.socioSeleccionado.datosSocios){

                client.mutate({
                    mutation:gql`mutation{actualizarSociosOtrosDatos(
                        ID_SOCIO:${this.state.socioSeleccionado.id},
                        VALORES:{
                            PRESTACIONES_ACCESORIAS:${this.state.prestaciones?'"""'+this.state.prestaciones+'"""':null},
                            EXCLUIDO:${this.state.excluido},
                            VENTAJAS:${this.state.ventajas?'"""'+this.state.ventajas+'"""':null}
                        }){ID_SOCIO}}`
                }).then(async result=>{
                  
                        alert("El registro se ha creado correctamente.")
                        await this.cargarSocios()

                        cerrarModal()

                }).catch(err=>{
                    alert("Ha ocurrido un error al actualizar la alerta.Pongase en contacto con el soporte tecnico")
                })

                
            }else{

                client.mutate({
                    mutation:gql`mutation{crearSociosOtrosDatos(
                        VALORES:{
                            ID_SOCIO:${this.state.socioSeleccionado.id}
                            PRESTACIONES_ACCESORIAS:${this.state.prestaciones?'"""'+this.state.prestaciones+'"""':null},
                            EXCLUIDO:${this.state.excluido},
                            VENTAJAS:${this.state.ventajas?'"""'+this.state.ventajas+'"""':null}
                        }){ID_SOCIO}}`
                }).then(async result=>{
                  
                        alert("El registro se ha creado correctamente.")
                        await this.cargarSocios()

                        cerrarModal()

                }).catch(err=>{
                    alert("Ha ocurrido un error al actualizar la alerta.Pongase en contacto con el soporte tecnico")
                })


            }
        
        }

        return (
            <>
                {this.state.cargando===true?
                    <Cargando/>
                :
                    <>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title="Propiedades de la persona"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalEditarPersona}
                >
                    <DatosPersonasFisicas i18next={i18next} datosPersona={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content="Cerrar y volver." position="top">
                            <Button onClick={cerrarModal2}>Cerrar</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaPresonaActivado?"Añadir persona.":"Actualizar persona."} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={actualizarPersona} 
                            >
                           Actualizar Persona
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModalPersonasJurid}
                title="Propiedades de la empresa"
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalEditarPersonaJuridica}
                className="datos-empresa-dialog"
            >
                <Tabs id="datos-empresa"
                    animate={this.animacion} 
                    onChange={this.cambiarPestaña} 
                    selectedTabId={this.state.pestaña}
                >
                    <Tab id="informacion-general" title="Información general" panel={ <InformacionGeneralNuevo empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null}  ref={(input) => { this.datos= input; }} i18next={i18next} /> } />
                    <Tab id="medios de contacto" title="Medios de Contacto" panel={<MediosContacto empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} />} />
                    <Tab id="centros de trabajo" title="Centros de trabajo / Sucursales" panel={<CentrosTrabajo empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null}  />} />
                    {this.state.socioSeleccionado && this.state.socioSeleccionado.esPrincipal?<Tab id="documentos" title="Documentos" panel={<Documentos empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} />} />:<></>}
                    {this.state.socioSeleccionado && this.state.socioSeleccionado.esPrincipal?<Tab id="vinculos" title="Vínculos" panel={<Vinculos empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} />} />:<></>}
                    <Tab id="archivos-adjuntos" title="Archivos adjuntos" panel={<ArchivosAdjuntos   empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.id:null} />} />
                </Tabs>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                </div>
            </div>  
            </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Otros datos de socio seleccionado"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generarImprimir-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
            <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir Informe de otros datos de socios"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirGral}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimirGral?
                            <div style={{ width: '100%', height: '600px'}} id='generarImprimirGral-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title="Socios: Otros datos"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                        >
                            
                            {!this.state.cargandoPersonas ?
                                <div className="pixelAlrededorGrande">
                                    <Card interactive={true} elevation={Elevation.TWO} id="vista-general">
                                        <FormGroup
                                            label="Socio:"
                                            labelFor="socio"
                                        >
                                            <InputGroup dsabled value={this.state.socioSeleccionado ? this.state.socioSeleccionado.nombre : null} />
                                        
                                            <FormGroup>
                                                <Button id="boton-editar-socio" icon="edit" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                                            </FormGroup>
                                        </FormGroup>
                                        <FormGroup
                                            label="Prestaciones accesorias:"
                                            labelFor="prestaciones-accesorias"

                                        >
                                            <TextArea
                                                onChange={handlePrestaciones}
                                                value={this.state.prestaciones}
                                                fill={true}
                                            />
                                        </FormGroup>
                                        <FormGroup
                                            label="Ventajas de los socios fundadores de las sociedades anónimas"
                                            labelFor="ventajas"
                                        >
                                            <TextArea
                                                onChange={handleVentajas}
                                                value={this.state.ventajas}
                                                fill={true}
                                            />
                                        </FormGroup>
                                        <Switch innerLabel="NO" innerLabelChecked="SI" checked={this.state.excluido} label="Excluido (art. 350 y siguientes)" onChange={handleExcluido} />
                                        <div className={Classes.DIALOG_FOOTER}>
                                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                <Tooltip content="Desea imprimir los datos de socio seleccionado" position="right" >
                                                    <Button icon="print" disabled={this.state.socioSeleccionado ? false : true} text="Imprimir Datos" onClick={() => this.generarImprimirOD()} />
                                                </Tooltip>
                                                <Tooltip content="Cerrar y volver." position="top">
                                                    <Button onClick={cerrarModal}>Cerrar</Button>
                                                </Tooltip>
                                                <Tooltip content="Guardar datos" position="top">
                                                    <AnchorButton
                                                        intent={Intent.PRIMARY}
                                                        onClick={guardarDatos}
                                                    >
                                                        Guardar datos
                                                    </AnchorButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                         : <Spinner /> }
                </Dialog>

                    <Card>
                    <Callout className="bp3-intent-danger">
                            Haga click en el nombre de un socio para ver sus datos:
                        </Callout>
                        <Navbar>
					    <Navbar.Group>
                        		<Tooltip content="Desea imprimir todos los datos de socios" position="right" >
                            		<Button className="bp3-minimal" icon="print" text="Imprimir General" onClick={()=>this.generarImprimirGral()}/>
                        		</Tooltip>
                        </Navbar.Group>
                        </Navbar>
                        <Table columnWidths={this.state.columnWidths} ref={(input) => { this.tablaSocios= input; }}  selectionModes={[RegionCardinality.CELLS,RegionCardinality.FULL_ROWS]} enableMultipleSelection={false} onSelection={seleccionarSocio} numRows={this.state.socios.length} onCompleteRender={() => {
                            if (!this.state.resized) {
                                this.resizeColumns();
                            }
                        }}>
                            <Column name="Socio"  cellRenderer={cellRendererSocio}/>   
                            <Column name="NIF/CIF"  cellRenderer={cellRendererDocIdentificativo}/>  
                            <Column name="Nacionalidad"  cellRenderer={cellRendererNacionalidad}/>   
                            <Column name="Domicilio"  cellRenderer={cellRendererDomicilio}/>   
                            <Column name="Localidad"  cellRenderer={cellRendererLocalidad}/>   
                            <Column name="Fecha Alta"  cellRenderer={cellRendererFechaAlta}/>   
                            <Column name="Fecha Baja"  cellRenderer={cellRendererFechaBaja}/>   
                            <Column name="Observaciones"  cellRenderer={cellRendererObservaciones}/>   
                            <Column name="Prestaciones accesorias"  cellRenderer={cellRendererPrestaciones}/>   
                            <Column name="Ventajas"  cellRenderer={cellRendererVentajas}/>   
                        </Table>
                    </Card>
            </>
            }
         </>   
        )
    }
}