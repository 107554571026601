/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Spinner,Navbar, FormGroup, Button, Callout, Alignment, ButtonGroup, Dialog, InputGroup, Classes, Tooltip, AnchorButton, Intent, Card, Elevation, Switch } from "@blueprintjs/core"
import { Table, Column, Cell, Regions } from "@blueprintjs/table"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import moment from "moment"
import { Cargando } from "../Pantallas/Cargando"
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import styles from './stylesPrint';


class ObligacionesEmitidas extends Component {

    state = {
        obligaciones:[
        ],
        series:null,
        numOblig:1,
        importeNom:0.00,
        importeNomValido:false,
        convertibles:false,
        nuevoActivado:false,
        editarActivado:false,
        borrarActivado:false,
        obligacionSeleccionada:null,
        importeTotalObligaciones:0,
        regionSeleccionada:null,
        cargando: false,
        cargandoImprimir:false,
        modalImprimir:false,
        imprimirActivado:false,
        columnWidths: [100,100,100,100,100],
        resized: false,
    }
    resizeColumns() {
        
        let containerSize = document.getElementsByClassName("bp3-table-container")[0].clientWidth
        let totalMinSizeReq = 0;
        let cumulativeColumnWidths= [];
        for (let index = 0; index < this.tablaObligaciones.locator.grid.numCols; index++) {
            totalMinSizeReq = totalMinSizeReq + this.tablaObligaciones.locator.getWidestVisibleCellInColumn(index);
            cumulativeColumnWidths.push(this.tablaObligaciones.locator.getWidestVisibleCellInColumn(index));
        }
        if (totalMinSizeReq > containerSize) {
            this.setState({
                columnWidths: cumulativeColumnWidths,
                resized: true
            });
        } else {
            let diff = ((containerSize - totalMinSizeReq) / this.tablaObligaciones.locator.grid.numCols);
            this.setState({
                columnWidths: cumulativeColumnWidths.map(columnWidt => columnWidt + diff),
                resized: true
            });
        }
        
    }


    cargarObligaciones=async()=>{

        //reiniciamos el valor de las obliaciones
        this.setState({importeTotalObligaciones:0})

        let obligaciones=await this.props.client
        .query({
            query: gql`
                {obligacionesEmitidas{
                    ID_OBLIGACION,
                    NUMERO,
                    SERIES,
                    IMPORTE_NOMINAL,
                    CONVERTIBLES
                }}
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.obligacionesEmitidas
        })
        let totalObligaciones=0
        let i=0
        //calculamos el importe total de las obligaciones.
        obligaciones.forEach(obligacion=>{
            totalObligaciones+=(obligacion.NUMERO*obligacion.IMPORTE_NOMINAL)
            i++
            if(i===obligaciones.length){
                this.setState({importeTotalObligaciones:parseFloat(totalObligaciones).toFixed(2)})
            }
        })

        this.setState({obligaciones:obligaciones})

    }

   

    componentDidMount(props){
        this.setState({ cargando: true }, async() =>{
            await this.cargarObligaciones()
            this.setState({cargando:false})
        })
    }
    generarImprimirOE=async()=>{
        this.setState({
            cargandoImprimir:true,
        modalImprimir:true}, async () =>{
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirOE(), document.getElementById('generarImprimir-pdf'))
            })
        })
    }
    ImprimirOE=() =>(
    <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>EMPRESA:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>INFORME:</Text>
                    <Text style={styles.text}>ESTRUCTURA SOCIETARIA</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>OBLIGACIONES EMITIDAS</Text>
                </View>
                    {this.state.obligaciones?this.state.obligaciones.map((obligacion, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>SERIES</Text>
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>NÚMERO DE OBLIGACIONES</Text>                                                                
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>IMPORTE NOMINAL</Text>                                                                
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>IMPORTE TOTAL</Text>                                                                
                                        <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 0, fontSize:9, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>CONVERTIBLES</Text>                                                             
                                    </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.SERIES?obligacion.SERIES:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.NUMERO?obligacion.NUMERO:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.IMPORTE_NOMINAL?parseFloat(obligacion.IMPORTE_NOMINAL).toFixed(2):''}€</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.IMPORTE_NOMINAL?parseFloat(obligacion.IMPORTE_NOMINAL * obligacion.NUMERO).toFixed(2):''}€</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 0,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{obligacion.CONVERTIBLES?"SI":"NO"}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}
                    <View style={styles.rowTitleOne}>
                    	<Text style={styles.labelTitle}>IMPORTE TOTAL DE LAS OBLIGACIONES EMITIDAD:</Text>
            		</View>
                    <View style={styles.rowTitleOne}>
                        <Text style={styles.labelData}>{this.state.importeTotalObligaciones}€</Text>
                    </View>    
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>Documento impreso mediante la aplicación LexControl el día {moment().format("DD/MM/YYYY")} a las {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    

    render() {

        document.title ="LexControl | Obligaciones emitidas"

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }

            this.setState({
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                modalAbierto: true,
                series:null,
                numOblig:1,
                importeNom:0.00,
                convertibles:false
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            if(!this.state.obligacionSeleccionada){
                alert("Debe seleccionar una obligación")
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                modalAbierto: true,
                series:this.state.obligacionSeleccionada.SERIES,
                numOblig:this.state.obligacionSeleccionada.NUMERO,
                importeNom:Intl.NumberFormat('es-ES').format(this.state.obligacionSeleccionada.IMPORTE_NOMINAL),
                convertibles:this.state.obligacionSeleccionada.CONVERTIBLES,
                importeNomValido:true
            })

        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert("No tienes permisos para realizar esta acción")
                return
            }
            
            if(!this.state.obligacionSeleccionada){
                alert("Debe seleccionar una obligación")
                return
            }

            this.setState({
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                modalBorrarAbierto: true,
            })

        }

        const añadirNuevo = (value) => {

            if(!this.state.series)return
            if(!this.state.importeNom || !this.state.importeNomValido)return
            if(this.state.numOblig<1)return

            //comprobacion de que no exista una obligacion con el nombre dado.
            let existe=this.state.obligaciones.find(obligacion=>{return obligacion.SERIES===this.state.series})
            if(existe){
                alert("Ya existe una obligación con esta serie")
                return
            }
            //mutation para añadir la obligacion emitida.
            this.props.client.mutate({
                mutation:gql`mutation{crearObligacionEmitida(
                    VALORES:{
                        NUMERO:${this.state.numOblig},
                        SERIES:"${this.state.series}",
                        IMPORTE_NOMINAL:${this.state.importeNom.replace(/\./g, '').replace(/\,/g, '.')},
                        CONVERTIBLES:${this.state.convertibles},
                    }){ID_OBLIGACION}}`

            }).then(async result=>{

                alert("El registro se ha insertado correctamente.")
                await this.cargarObligaciones()

                //seleccionamos el creado
                let creado=this.state.obligaciones.find((obligacion,index)=>{
                    if(obligacion.ID_OBLIGACION===result.data.crearObligacionEmitida.ID_OBLIGACION){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return obligacion.ID_OBLIGACION===result.data.crearObligacionEmitida.ID_OBLIGACION

                })

                this.setState({
                    obligacionSeleccionada:creado
                })

                cerrarModal()

            }).catch(err=>{
                alert("Ha ocurrido un error al crear la obligacion.Pongase en contacto con el soporte técnico")
            })


        }

        const actualizarObligacion=()=>{

            if(!this.state.series)return
            if(!this.state.importeNom || !this.state.importeNomValido)return
            if(this.state.numOblig<1)return

            let existe=this.state.obligaciones.find(obligacion=>{return obligacion.SERIES===this.state.series})
            if(existe && this.state.obligacionSeleccionada.ID_OBLIGACION!==existe.ID_OBLIGACION){
                alert("Ya existe una obligación con esta serie")
                return
            }

            //mutation para actualizar la obligacion emitida.
            this.props.client.mutate({
                mutation:gql`mutation{actualizarObligacionEmitida(
                    ID_OBLIGACION:${this.state.obligacionSeleccionada.ID_OBLIGACION},
                    VALORES:{
                        NUMERO:${this.state.numOblig},
                        SERIES:"${this.state.series}",
                        IMPORTE_NOMINAL:${this.state.importeNom.replace(/\./g, '').replace(/\,/g, '.')},
                        CONVERTIBLES:${this.state.convertibles},
                    }){ID_OBLIGACION}}`

            }).then(async result=>{

                alert("El registro se ha actualizado correctamente.")
                await this.cargarObligaciones()

                //seleccionamos el creado
                let creado=this.state.obligaciones.find((obligacion,index)=>{
                    if(obligacion.ID_OBLIGACION===this.state.obligacionSeleccionada.ID_OBLIGACION){
                        this.setState({regionSeleccionada:[Regions.row(index)]})
                    }
                    return obligacion.ID_OBLIGACION===this.state.obligacionSeleccionada.ID_OBLIGACION

                })

                this.setState({
                    obligacionSeleccionada:creado
                })

                cerrarModal()
            }).catch(err=>{
                alert("Ha ocurrido un error al actualizar la obligacion.Pongase en contacto con el soporte técnico")
            })


        }

        const eliminarObligacion=()=>{

            //mutation para eliminar la obligacion emitida.
            this.props.client.mutate({
                mutation:gql`mutation{eliminarObligacionEmitida(
                    ID_OBLIGACION:${this.state.obligacionSeleccionada.ID_OBLIGACION},
                ){ID_OBLIGACION}}`
            }).then(async result=>{

                alert("El registro se ha eliminado correctamente.")
                await this.cargarObligaciones()

                this.setState({
                    obligacionSeleccionada:null,
                    regionSeleccionada:null
                },()=>{
                    this.tablaObligaciones.clearSelection()
                })
                cerrarModal()
            
            }).catch(err=>{
                alert("Ha ocurrido un error al eliminar la obligacion.Pongase en contacto con el soporte técnico")
            })

        }

        const cellRendererSeries = (rowIndex) => {
            return <Cell>{`${this.state.obligaciones[rowIndex].SERIES}`}</Cell>
        }

        const cellRendererNumOblig = (rowIndex) => {
            return <Cell>{`${this.state.obligaciones[rowIndex].NUMERO}`}</Cell>
        }

        const cellRendererImpNom = (rowIndex) => {
            return <Cell>{`${Intl.NumberFormat('es-ES').format(parseFloat(this.state.obligaciones[rowIndex].IMPORTE_NOMINAL).toFixed(2))} €`}</Cell>
        }

        const cellRendererImpTot = (rowIndex) => {
            return <Cell>{`${Intl.NumberFormat('es-ES').format(parseFloat(this.state.obligaciones[rowIndex].IMPORTE_NOMINAL * this.state.obligaciones[rowIndex].NUMERO).toFixed(2))} €`}</Cell>
        }

        const cellRendererConv = (rowIndex) => {
            return <Cell>{this.state.obligaciones[rowIndex].CONVERTIBLES? "Si" : "No"}</Cell>
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalBorrarAbierto: false,
            })
        }
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false
            })
        }

        const cambiarSeries = (ev) => {
            this.setState({series: ev.currentTarget.value})
        }

        const cambiarNumeroObligaciones = (ev) => {
            this.setState({numOblig: ev.currentTarget.value})
        }

        const formatearMiles=(numero)=>{
            return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
        }

        const cambiarImporteNom = (ev) => {
            //solo permitir en el input numeros,comas y puntos.
            let pattern=/^[0-9,.]*$/
            if(pattern.test(ev.currentTarget.value)===false)return

            //comprobacion que sea un número válido
            let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
            if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
                this.setState({importeNomValido:true})
            }else{
                this.setState({importeNomValido:false})
            }

            this.setState({importeNom:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
        }

        const handleConvertibles = () => {
            this.setState({
                convertibles: !this.state.convertibles
            })
        }

        const seleccionarObligacion=(value)=>{

            if(value.length===0)return

            let seleccionada=this.state.obligaciones[value[0].rows[0]]                
            this.setState({
                obligacionSeleccionada:seleccionada,
                regionSeleccionada:value
            })
        }

        return (
            <>
            {
                this.state.cargando === true?
                <Cargando />
                :
                <>
                        <Dialog 
                            transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="print"
                            onClose={cerrarModalImprimir}
                            title="Imprimir Informe de obligaciones emitidas"
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            usePortal={true}
                            isOpen={this.state.modalImprimir}
                            style={styles.dialog}
                        >
                            {!this.state.cargandoImprimir?
                                    <div style={{ width: '100%', height: '600px'}} id='generarImprimir-pdf'>
                                    </div>
                                    :
                                        <Spinner />
                                }

                        </Dialog>
                        <Dialog transitionDuration={400}
                            autoFocus={true}
                            enforceFocus={true}
                            icon="info-sign"
                            onClose={cerrarModal}
                            title="Propiedades del grupo de obligaciones emitidas"
                            canEscapeKeyClose={true}
                            canOutsideClickClose={true}
                            isCloseButtonShown={true}
                            className="pixelAlrededorGrande"
                            isOpen={this.state.modalAbierto}
                        >
                            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande">
                                <FormGroup
                                    label="Series:"
                                    labelFor="series"
                                    helperText={!this.state.series?"Debe rellenar el nombre del grupo":""}
                                    intent="danger"
                                >
                                    <InputGroup onChange={cambiarSeries} intent={!this.state.series?"danger":"primary"} value={this.state.series} className="pixelAlrededor" fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Número de obligaciones:"
                                    labelFor="numro-obligaciones"
                                    intent="danger"
                                    helperText={this.state.numOblig<1?"El numero de obligaciones debe ser mayor a 0":""}
                                >
                                    <InputGroup type="number"  step="1" min={1} value={this.state.numOblig} intent={this.state.numOblig<1?"danger":"primary"} onChange={cambiarNumeroObligaciones} className="pixelAlrededor" fill={true} />
                                </FormGroup>
                                <FormGroup
                                    label="Importe nominal:"
                                    labelFor="importe-nominal"
                                    helperText={!this.state.importeNom || !this.state.importeNomValido?"Debe insertar el importe nominal":""}
                                    intent="danger"
                                >
                                    <InputGroup onChange={cambiarImporteNom} type="text" value={this.state.importeNom} intent={!this.state.importeNom || !this.state.importeNomValido?"danger":"primary"} className="pixelAlrededor" min={0} fill={true} />
                                </FormGroup>
                                <FormGroup
                                    labelFor="convertibles"
                                >
                                    <Switch checked={this.state.convertibles} innerLabel="NO" innerLabelChecked="SI" value={this.state.convertibles} label="Convertible" alignIndicator={Alignment.RIGHT} onChange={handleConvertibles} />
                                </FormGroup>
                            </Card>
                            <div className={[Classes.DIALOG_FOOTER,"pixelAlrededor"]}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip content="Cerrar y volver." position="top">
                                    <Button onClick={cerrarModal}>Cerrar</Button>
                                </Tooltip>
                                <Tooltip content={this.state.nuevoActivado?"Añadir obligación":"Editar obligación"} position="top">
                                    <AnchorButton
                                        intent={Intent.PRIMARY}
                                        onClick={this.state.nuevoActivado?añadirNuevo:actualizarObligacion}
                                    >
                                        {this.state.nuevoActivado?"Añadir obligación":"Editar obligación"}
                                    </AnchorButton>
                                </Tooltip>
                            </div>
                        </div>
                        </Dialog>
                        <Dialog transitionDuration={400}
                                autoFocus={true}
                                enforceFocus={true}
                                icon="warning-sign"
                                onClose={cerrarModal}
                                title="Borrar una obligación"
                                canEscapeKeyClose={true}
                                canOutsideClickClose={true}
                                isCloseButtonShown={true}
                                usePortal={true}
                                isOpen={this.state.modalBorrarAbierto}
                            >
                                <div className={Classes.DIALOG_BODY}>
                                    <h2>¿Desea eliminar la obligación?</h2>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content="Eliminar obligación." position="top">
                                        <AnchorButton
                                            intent={Intent.PRIMARY}
                                            onClick={eliminarObligacion}
                                        >
                                            Si
                                        </AnchorButton>
                                    </Tooltip>
                                    <Tooltip content="Cerrar y volver." position="top">
                                        <Button onClick={cerrarModal}>No</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Dialog>
                        <Card interactive={true} elevation={Elevation.TWO}>
                        <FormGroup>
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" icon="add" text="Nuevo" active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" icon="edit" text="Editar" active={this.state.editarActivado} onClick={abrirModalEditar} />
                                        <Button className="bp3-minimal" icon="delete" text="Borrar" active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                        <Button  className="bp3-minimal"  icon="print" text="Imprimir" active={this.state.imprimirActivado} onClick={()=>this.generarImprimirOE()} />

                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <Table columnWidths={this.state.columnWidths} ref={(input) => { this.tablaObligaciones= input; }} onSelection={seleccionarObligacion} numRows={this.state.obligaciones.length} className="tabla-rebelde" onCompleteRender={() => {
                            if (!this.state.resized) {
                                this.resizeColumns();
                            }
                        }}>
                                <Column name="Series" cellRenderer={cellRendererSeries}/>   
                                <Column name="Número de obligaciones" cellRenderer={cellRendererNumOblig}/>   
                                <Column name="Importe nominal" cellRenderer={cellRendererImpNom}/>   
                                <Column name="Importe Total" cellRenderer={cellRendererImpTot}/>   
                                <Column name="Convertibles" cellRenderer={cellRendererConv}/>   
                            </Table>
                            <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger callout-rebelde">
                                Importe total de las obligaciones emitidas:                         
                                <InputGroup value={Intl.NumberFormat('es-ES').format(this.state.importeTotalObligaciones)} className="pixelAlrededor" readOnly={true} fill={false} />
                            </Callout>
                        </FormGroup>
                    </Card>
                </>
            }
            </>
        )
    }
}
export default withApollo(ObligacionesEmitidas)