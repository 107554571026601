import React, {Component} from 'react';
import { withApollo } from 'react-apollo';
import { Cargando } from './Cargando';
import { Card, 
    Elevation, 
    Callout,
    Navbar,
    ButtonGroup,
    Button,
    Alignment,
    Dialog,
    Tabs,
    Tab,
    Tooltip,
    AnchorButton,
    Intent,
    Classes,
    NavbarDivider,
    FormGroup,
    InputGroup
} from "@blueprintjs/core";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import const_table from "./BootstrapTable";
import ControlAcuerdo from "./Elementos/ControlAcuerdo";
import Adjuntos from "./Elementos/Adjuntos"
import Votos from "./Elementos/VotosAcuerdo";
import TextoActas from "./Elementos/TextoActas";
import DatosAcuerdo from "./Elementos/DataAcuerdo";
import gql from "graphql-tag";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import Select from "react-select";
import Impugnacion from "./Elementos/Impugnacion";
import Anulacion from "./Elementos/Anulacion";
import ReactDOM from 'react-dom';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import pantallaInicio from '../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import iconoFooter from '../../Assets/lc.png';
import styles from './stylesPrint';

class AcuerdosActas extends Component {
    state={
        acuerdoSeleccionado:null,
        nuevoAcuerdo:true,
        modalNuevoAbierto: false,
        pestaña:"datos-acuerdo",
        votos:[],
        cargando:false,
        favor:0,
        contra:0,
        abstencion:0,
        porcAbstencion:0,
        porcContra:0,
        porcFavor:0,
        adjuntosAcuerdo:[],
        adjuntosImpugnacion:[],
        modalInscripcion:false,
        fechaPresentacion: new Date(),
        fechaInscripcion: new Date(),
        fechaEfecto: new Date(),
        inscripcionSeleccionada: null,
        registroSeleccionada:null,
        diario: '',
        asiento: '',
        tomo: '',
        folio: '',
        inscripcion: '',
	    hoja: '',
        modalNuevoRegistro:false,
        registroNuevo: '',
        tieneInscripcion: false,
        datosImpugnacion:null,
        datosAnulacion:null,
        modalImpugnacion:false,
        pestañaImpugnacion:"datos-impugnacion",
        tipoAdjuntosAcuerdo:[
            {"label": "Acuerdo","value":12},
            {"label": "Otros","value":3}
        ],
        tipoAdjuntosImpugnacion:[
            {"label": "Impugnación de acuerdo","value":13},
            {"label": "Anulación de acuerdo impugnado","value":14},
            {"label": "Otros","value":3}
        ],
        entidadArchivoAcuerdo:7,
        entidadArchivoImpugnacion:8,
        cargandoImprimir:false,
        modalImprimir: false
    }

    formatearFecha=(fecha)=>{

        let arrayMeses=[
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ]
    
    
        return `${moment(fecha).format("DD")} de ${arrayMeses[parseInt(moment(fecha).format("MM"))-1]} de ${moment(fecha).format("YYYY")}`
    }

    generarImprimir= async() => {

        if(!this.state.acuerdoSeleccionado){
            alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
            return
        }

        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async () => {
             this.setState({cargandoImprimir:false}, () => {
                 ReactDOM.render(this.Imprimir(), document.getElementById('generar-pdf'))
             })
        })
    }

    Imprimir=()=>{
        let persona=this.props.empleados.find(persona=>{return persona.value===this.state.acuerdoSeleccionado.ID_SOLICITANTE});
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View style={styles.row}>
                  <Text style={styles.labelTitleCompliance}>{this.props.i18next.t("actas.titlecertificado",{ns:"page"})}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.labelTitleCompliance}>{this.props.i18next.t("actas.ddsecretario",{ns:"page"})}, {persona?persona.label:'secretario/a'} {this.props.i18next.t("actas.de",{ns:"page"})} {this.props.nombreEmpresa}</Text>
                </View>
                <View style={{height:5}}></View>
                <View style={styles.row}>
                  <Text style={styles.labelTitleCompliance}>{this.props.i18next.t("actas.certifica",{ns:"page"})}:</Text>
                </View>
                <View style={{height:10}}></View>
                <View style={styles.row}>
                  <Text style={styles.text}>{this.props.i18next.t("actas.quesegun",{ns:"page"})} {this.props.acta?this.props.acta.NUMERO:null} {this.props.i18next.t("actas.enlasesion",{ns:"page"})} {this.props.acta?this.props.acta.TIPO_CONVOCATORIA.DESCRIPCION:null},
                                {this.props.i18next.t("actas.celebradaen",{ns:"page"})} {this.props.acta?this.props.acta.LUGAR:null} a {this.props.acta?this.formatearFecha(this.props.acta.FECHA_REUNION):null}, {this.props.i18next.t("actas.seadopta",{ns:"page"})}:</Text>
                </View>
                <View style={{height:5}}></View>
                {this.state.acuerdoSeleccionado ? <View style={styles.row} ><Text style={styles.text}>* {this.state.acuerdoSeleccionado.TEXTO}</Text></View> : <View style={styles.row}><Text style={styles.text}>* {this.props.i18next.t("actas.noacuerdo",{ns:"page"})}</Text></View>}
                              
                <View style={{height:10}}></View>
                <View style={styles.row}>
                  <Text style={styles.labelTitleCompliance}>En {this.props.acta?this.props.acta.LUGAR:null}, a {this.formatearFecha(new Date())}.</Text>
                </View>
                <View style={{height:20}}></View>
                <View style={styles.row}>
                  <Text style={styles.labelFirmaLeft}>{this.props.i18next.t("actas.firmaleft",{ns:"page"})}</Text>
                  <Text style={styles.labelFirmaRight}>{this.props.i18next.t("actas.firmaright",{ns:"page"})}</Text>
                </View>
            </View>
      
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
      )
    }

    cargarDatosImpugnacion=async () => {
        await this.props.client.query({
			query: gql`
			  {
				actasAcuerdosImpugnaciones(FILTROS:{ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}}){
				  ID_IMPUGNACION,
				  ID_ACUERDO,
				  TEXTO,
				  RESOLUCION,
				  ANULACION,
				  SUBSANACION,
				  FECHA_EFECTO}
			  }
			`,fetchPolicy:'network-only'
        }).then(async result =>{
            if(result.data.actasAcuerdosImpugnaciones.length>0){
                this.setState({
                    datosImpugnacion:result.data.actasAcuerdosImpugnaciones[0]
                },async () => {
                    await this.props.client.query({
                        query: gql`
                          {
                            actasAcuerdosImpugnacionesAnulaciones(FILTROS:{ID_IMPUGNACION:${result.data.actasAcuerdosImpugnaciones[0].ID_IMPUGNACION}}){
                              ID_ANULACION,
                              ID_IMPUGNACION,
                              FECHA_FIRMEZA,
                              ID_REGISTRO,
                              DIARIO,
                              ASIENTO,
                              TOMO,
                              FOLIO,
                              INSCRIPCION,
                              HOJA
                            }
                          }
                        `,fetchPolicy:'network-only'
                    }).then(async resultAnulacion =>{
                        if(resultAnulacion.data.actasAcuerdosImpugnacionesAnulaciones.length>0){
                            this.setState({
                                datosAnulacion:resultAnulacion.data.actasAcuerdosImpugnacionesAnulaciones[0],
                                pestañaImpugnacion:"datos-impugnacion",
                                modalImpugnacion:true
                            });
                        } else {
                            this.setState({
                                pestañaImpugnacion:"datos-impugnacion",
                                modalImpugnacion:true
                            });
                        
                        }
                    })
                });
            } else {
                this.setState({
                    pestañaImpugnacion:"datos-impugnacion",
                    modalImpugnacion:true
                });
            }
        });
    }

    cargarInscripcion=async () => {
        //obtenemos la inscripcion de los acuerdos
        await this.props.client.query({
            query: gql`
                {
                actasAcuerdosInscripcionRm(FILTROS:{ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}}){
                    ID_ACUERDO,
                    FECHA_PRESENTACION,
                    ID_CALIFICACION_ACUERDO,
                    FECHA_INSCRIPCION,
                    FECHA_EFECTO,
                    ID_REGISTRO,
                    DIARIO,
                    ASIENTO,
                    TOMO,
                    FOLIO,
                    INSCRIPCION,
                    HOJA,
                }
                }
            `,fetchPolicy:'network-only'
        }).then(async result =>{
            if(result.data.actasAcuerdosInscripcionRm.length>0){
                let acuerdo_inscripcion=JSON.parse(JSON.stringify(result.data.actasAcuerdosInscripcionRm[0]));
                let calificacionesAcuerdo=this.props.inscripcionOptions.find(inscripcion=>{return inscripcion.value===acuerdo_inscripcion.ID_CALIFICACION_ACUERDO})
		        let registro=this.props.registroOptions.find(resgistro=>{return resgistro.value===acuerdo_inscripcion.ID_REGISTRO})

                this.setState({
                    fechaPresentacion:new Date(acuerdo_inscripcion.FECHA_PRESENTACION),
                    fechaInscripcion:new Date(acuerdo_inscripcion.FECHA_INSCRIPCION),
                    fechaEfecto:new Date(acuerdo_inscripcion.FECHA_EFECTO),
                    diario:acuerdo_inscripcion.DIARIO,
                    asiento:acuerdo_inscripcion.ASIENTO,
                    tomo:acuerdo_inscripcion.TOMO,
                    folio:acuerdo_inscripcion.FOLIO,
                    inscripcion:acuerdo_inscripcion.INSCRIPCION,
			        hoja:acuerdo_inscripcion.HOJA,
                    inscripcionSeleccionada:calificacionesAcuerdo,
			        registroSeleccionada:registro,
                    tieneInscripcion:true,
                    modalInscripcion:true
                });
            } else {
                this.setState({
                    fechaPresentacion:new Date(),
                    fechaInscripcion:new Date(),
                    fechaEfecto:new Date(),
                    diario: '',
                    asiento: '',
                    tomo: '',
                    folio: '',
                    inscripcion: '',
                    hoja: '',
                    inscripcionSeleccionada:null,
                    registroSeleccionada:null,
                    tieneInscripcion:false,
                    modalInscripcion:true
                });
            }
        })
      }

    setVotos=(votos)=>{
        this.setState({
            votos:votos
        },async()=>{
            this.calcularVotos();
        })
    }

    calcularVotos=()=>{
        if(this.state.votos && this.state.votos.length>0){
            let votos=this.state.votos;
            let votosFavor=votos.filter(voto=>{return voto.voto===1});
            let votosContra=votos.filter(voto=>{return voto.voto===-1})
            let votosAbstencion=votos.filter(voto=>{return voto.voto===0})
            let total=votosFavor.length+votosContra.length+votosAbstencion.length
            let porcentajeFavor= total>0?Math.round((votosFavor.length / total)*100):0;
            let porcentajeContra=total>0?Math.round((votosContra.length/ total)*100):0;
            let porcentajeAbstencion=total>0?Math.round((votosAbstencion.length / total)*100):0;
            this.setState({
                favor:votosFavor.length,
                contra:votosContra.length,
                abstencion:votosAbstencion.length,
                porcFavor:porcentajeFavor,
                porcContra:porcentajeContra,
                porcAbstencion:porcentajeAbstencion
            })
        } else {
            this.setState({
                favor:0,
                contra:0,
                abstencion:this.props.votos?this.props.votos.length:0,
                porcFavor:0,
                porcContra:0,
                porcAbstencion:this.props.votos?this.props.votos.length>0?100:0:0
            })
        }
    }

    cargarAdjuntosImpugnacion=async ()=>{
        let copyAdjuntos=[];

        await this.props.client.query({
            query: gql`{adjuntos(FILTROS:{ID_ASOCIADO:${this.state.acuerdoSeleccionado.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:${this.state.entidadArchivoImpugnacion},ID_TIPO_ADJUNTO:3}){
                ID_ADJUNTO,
                DESCRIPCION,
                NOMBRE,
                EXTENSION,
                DATOS,
                TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}
            }}`,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({
                    "id":adjunto.ID_ADJUNTO,
                    "descripcion":adjunto.DESCRIPCION,
                    "nombre":adjunto.NOMBRE,
                    "tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,
                    "idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,
                    "mime":extension,
                    "ruta":adjunto.DATOS})
            }
        });

        await this.props.client.query({
            query: gql`{adjuntos(FILTROS:{ID_ASOCIADO:${this.state.acuerdoSeleccionado.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:${this.state.entidadArchivoImpugnacion},ID_TIPO_ADJUNTO:13}){
                ID_ADJUNTO,
                DESCRIPCION,
                NOMBRE,
                EXTENSION,
                DATOS,
                TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}
            }}`,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({
                    "id":adjunto.ID_ADJUNTO,
                    "descripcion":adjunto.DESCRIPCION,
                    "nombre":adjunto.NOMBRE,
                    "tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,
                    "idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,
                    "mime":extension,
                    "ruta":adjunto.DATOS})
            }
        });

        await this.props.client.query({
            query: gql`{adjuntos(FILTROS:{ID_ASOCIADO:${this.state.acuerdoSeleccionado.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:${this.state.entidadArchivoImpugnacion},ID_TIPO_ADJUNTO:14}){
                ID_ADJUNTO,
                DESCRIPCION,
                NOMBRE,
                EXTENSION,
                DATOS,
                TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}
            }}`,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({
                    "id":adjunto.ID_ADJUNTO,
                    "descripcion":adjunto.DESCRIPCION,
                    "nombre":adjunto.NOMBRE,
                    "tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,
                    "idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,
                    "mime":extension,
                    "ruta":adjunto.DATOS})
            }
        });

        this.setState({adjuntosImpugnacion:copyAdjuntos})
    }

    cargarAdjuntosAcuerdo=async ()=>{

        let copyAdjuntos=[]

        //obtener los tipos de documento 'otros'
        await this.props.client.query({
            query: gql`{adjuntos(FILTROS:{ID_ASOCIADO:${this.state.acuerdoSeleccionado.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:${this.state.entidadArchivoAcuerdo},ID_TIPO_ADJUNTO:3}){
                ID_ADJUNTO,
                DESCRIPCION,
                NOMBRE,
                EXTENSION,
                DATOS,
                TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}
            }}`,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({
                    "id":adjunto.ID_ADJUNTO,
                    "descripcion":adjunto.DESCRIPCION,
                    "nombre":adjunto.NOMBRE,
                    "tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,
                    "idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,
                    "mime":extension,
                    "ruta":adjunto.DATOS})
            }
        })

        //obtener los tipos de documento 'impugnacion de acuerdo'
        await this.props.client.query({
            query: gql`{adjuntos(FILTROS:{ID_ASOCIADO:${this.state.acuerdoSeleccionado.ID_ACUERDO},ID_ENTIDAD_ASOCIADA:${this.state.entidadArchivoAcuerdo},ID_TIPO_ADJUNTO:12}){
                ID_ADJUNTO,
                DESCRIPCION,
                NOMBRE,
                EXTENSION,
                DATOS,
                TIPO_ADJUNTO{ID_TIPO_ADJUNTO,DESCRIPCION}
            }}`,fetchPolicy:'network-only'
        }).then(result=>{ 
            let adjuntos=result.data.adjuntos;
            for(let adjunto of adjuntos){
                let extension=this.obtenterTipo("."+adjunto.EXTENSION)
                copyAdjuntos.push({"id":adjunto.ID_ADJUNTO,"descripcion":adjunto.DESCRIPCION,"nombre":adjunto.NOMBRE,"tipo":adjunto.TIPO_ADJUNTO.DESCRIPCION,"idTipo":adjunto.TIPO_ADJUNTO.ID_TIPO_ADJUNTO,"mime":extension,"ruta":adjunto.DATOS})
            }
        })

        this.setState({adjuntosAcuerdo:copyAdjuntos})

    }

    obtenterTipo(nombreExtension){

        let extension=""
        switch(nombreExtension){
            case ".pdf":
                extension="Documento PDF"
            break;
            case ".doc":
            case ".docx":
                extension="Documento de Microsoft Word"
            break;
            case ".xls":
            case ".xlsx":
                extension="Documento de Microsoft Excel"
            break;
            case ".txt":
                extension="Documento de Texto"
            break;
            case ".png":
            case ".jpg":
            case ".jpeg":
            case ".svg":
                extension="Imagen"
            break;
            default:
                extension="Otros"
            break;
        }
        return extension

    }

    cargarVotosAsistentes=()=>{
        let votos=[];
        if (this.props.asistentes.length>0) {
            for(let voto of this.props.asistentes){

                votos.push({
                    idPersona:voto.value,
                    nombre:voto.label,
                    voto:0
                })
    
            }
        }
        this.setState({
            votos:votos,
            nuevoAcuerdo:true,
            modalNuevoAbierto: true,
            pestaña:"datos-acuerdo",
            cargando:true,
            acuerdoSeleccionado:null,
        },async()=>{
            this.calcularVotos();
        });
    }

    cargarVotos=async()=>{
        let votos=[]
                
        if (this.state.acuerdoSeleccionado) {
            this.props.client.query({
                query:gql`{actasAcuerdosVotos(FILTROS:{ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}}){
                    ID_ACUERDO,
                    ID_PERSONA,
                    PERSONA{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                    VOTO,
                }}`,
                fetchPolicy:'network-only'
            }).then(async(result)=>{
                for(let voto of JSON.parse(JSON.stringify(result.data.actasAcuerdosVotos))){
                    await this.props.obtenerNombreMiembros(voto.PERSONA)
                    votos.push({
						idPersona:voto.ID_PERSONA,
						nombre:voto.PERSONA.label,
						voto:voto.VOTO
					})
                }
                
                this.setState({
                    votos:votos,
                    nuevoAcuerdo:false,
                    pestaña:"datos-acuerdo",
                    modalNuevoAbierto: true
                },async()=>{
                    this.calcularVotos();
                });
            })
        }

        
    }

    render() {
        const abrirModalNuevo = async() => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.props.acta){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({votos:[],adjuntosAcuerdo:[]},async () => {
                this.cargarVotosAsistentes();
            });
            
            

        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.props.acta){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(!this.state.acuerdoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({votos:[],adjuntosAcuerdo:[]},async () => {
                this.cargarVotos();
                await this.cargarAdjuntosAcuerdo();
            })

        }

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    acuerdoSeleccionado: row
                },async()=>{
                })
                
            }
            return true; // return true or dont return to approve current select action
        }

        const columns = [{
            dataField: 'NUMERO',
            text: this.props.i18next.t("identificacion.numero",{ns:"page"})
            }, {
            dataField: 'TEXTO',
            text: this.props.i18next.t("identificacion.texto",{ns:"page"})
            }, {
            dataField: 'PLAZO',
            text: this.props.i18next.t("actas.plazos",{ns:"page"})
            }, {
            dataField: 'CUMPLIMIENTO',
            text: this.props.i18next.t("actas.cumplimiento",{ns:"page"})
            }, {
            dataField: 'RESPONSABLE',
            text: this.props.i18next.t("actas.responsable",{ns:"page"})
        }];

        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                nuevoAcuerdo:true,
                cargando:false
            })
        }

        const cerrarModalInscripcion = () => {
            this.setState({
                modalInscripcion: false
            })
        }

        const cerrarModalImpugnacion = () => {
            this.setState({
                modalImpugnacion: false
            })
        }

        const cerrarModalRegistro=()=>{
            this.setState({modalNuevoRegistro: false})
          }

        const cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });
        
        const cambiarPestañaImpugnacion = (navbarTabId) => this.setState({ pestañaImpugnacion: navbarTabId });

        const anadirAcuerdo = async() => {
            if(!this.textoActas.state.texto){
                this.setState({pestaña:"texto"});
                alert(this.props.i18next.t("actas.validacuerdo1",{ns:"page"}));
                return
            }
            let certificado=false;
            let id_solicitante=null;
            if (this.ControlAcuerdo.state.personaSeleccionada) {
                certificado=true;
                id_solicitante=this.ControlAcuerdo.state.personaSeleccionada.value;
            }
            let fechaSolicitud=null;
            if (this.ControlAcuerdo.state.fechaSolicitud) {
                fechaSolicitud=moment(this.ControlAcuerdo.state.fechaSolicitud).format("YYYY-MM-DD");
            }
            await this.props.client.mutate({
                mutation: gql`mutation{crearActaAcuerdo(VALORES:{          
                            ID_ACTA:${this.props.acta.ID_ACTA}
                            NUMERO:${this.props.acuerdos.length + 1}
                            TEXTO:"""${this.textoActas.state.texto}"""
                            INSCRIBIBLE_RM:${
                            this.datosAcuerdos.state.inscripcionRegistro
                            }
                            CERTIFICADO:${certificado}
                            ID_SOLICITANTE:${id_solicitante}
			                FECHA_SOLICITUD:"${fechaSolicitud}"
                            PLAZO:"${this.ControlAcuerdo.state.plazo}"
                            CUMPLIMIENTO:"${this.ControlAcuerdo.state.cumplimiento}"
                            RESPONSABLE:"${this.ControlAcuerdo.state.responsable}"
                            OBSERVACIONES:"""${this.ControlAcuerdo.state.observaciones}"""
                        }){ID_ACUERDO}}`
            }).then(async result => {
                if(this.state.votos.length>0){
                    for(let voto of this.state.votos){
                        this.props.client.mutate({
                            mutation: gql`
                                mutation{crearActaAcuerdoVoto(VALORES:{
                                    ID_ACUERDO:${result.data.crearActaAcuerdo.ID_ACUERDO}
                                    ID_PERSONA:${voto.idPersona}
                                    VOTO:${voto.voto}
                                }){ID_ACUERDO}}
                            `
                        })
                    }
                }

                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}));
                this.setState({ 
                    cargando: true,
                },async ()=>{
                    await this.props.recargarAcuerdos();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err);
                this.setState({ 
                    cargando: false,
                },async ()=>{
                    await cerrarModal()
                });
                alert(this.props.i18next.t("error",{ns:"global"}))
            })
        }

        const actualizarAcuerdo = async() => {
            if(!this.textoActas.state.texto){
                this.setState({pestaña:"texto"});
                alert(this.props.i18next.t("actas.validacuerdo1",{ns:"page"}));
                return
            }
            let certificado=false;
            let id_solicitante=null;
            if (this.ControlAcuerdo.state.personaSeleccionada) {
                certificado=true;
                id_solicitante=this.ControlAcuerdo.state.personaSeleccionada.value;
            }
            let fechaSolicitud=null;
            if (this.ControlAcuerdo.state.fechaSolicitud) {
                fechaSolicitud='"'+moment(this.ControlAcuerdo.state.fechaSolicitud).format("YYYY-MM-DD")+'"';
            }
            await this.props.client.mutate({
                mutation: gql`mutation{actualizarActaAcuerdo(
                    ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO},        
                    VALORES:{  
                        TEXTO:"""${this.textoActas.state.texto}"""
                        INSCRIBIBLE_RM:${this.datosAcuerdos.state.inscripcionRegistro}
                        CERTIFICADO:${certificado}
                        ID_SOLICITANTE:${id_solicitante}
                        FECHA_SOLICITUD:${fechaSolicitud}
                        PLAZO:"${this.ControlAcuerdo.state.plazo}"
                        CUMPLIMIENTO:"${this.ControlAcuerdo.state.cumplimiento}"
                        RESPONSABLE:"${this.ControlAcuerdo.state.responsable}"
                        OBSERVACIONES:"""${this.ControlAcuerdo.state.observaciones}"""
                    }){ID_ACUERDO}}`
            }).then(async result => {
                await this.props.client.mutate({
                    mutation: gql`mutation{eliminarActaAcuerdoVoto(ID_ACUERDO:${
                        this.state.acuerdoSeleccionado.ID_ACUERDO
                    }){ID_ACUERDO}}`
                });

                if(this.state.votos.length>0){
                    for(let voto of this.state.votos){
                        this.props.client.mutate({
                            mutation: gql`
                                mutation{crearActaAcuerdoVoto(VALORES:{
                                    ID_ACUERDO:${result.data.crearActaAcuerdo.ID_ACUERDO}
                                    ID_PERSONA:${voto.idPersona}
                                    VOTO:${voto.voto}
                                }){ID_ACUERDO}}
                            `
                        })
                    }
                }

                alert(this.props.i18next.t("updatecorrecto",{ns:"global"}));

                this.setState({ 
                    cargando: true,
                },async ()=>{
                    await this.props.recargarAcuerdos();
                    await cerrarModal()
                });
            }).catch(err=>{
                console.log(err);
                this.setState({ 
                    cargando: false,
                },async ()=>{
                    await cerrarModal()
                });
                alert(this.props.i18next.t("error",{ns:"global"}))
            })
		  
        }

        const abrirDatosInscripcion=async ()=>{
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.acuerdoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if(!this.state.acuerdoSeleccionado.INSCRIBIBLE_RM){
                alert(this.props.i18next.t("actas.validacuerdo2",{ns:"page"}))
                return
            }
            await this.cargarInscripcion();
        }

        const abrirDatosImpugnacion=()=>{
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.acuerdoSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                datosImpugnacion:null,
                datosAnulacion:null
            },async ()=>{
                await this.cargarAdjuntosImpugnacion();
                await this.cargarDatosImpugnacion();
            })
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFechaPresentacion = nuevaFecha => {
            this.setState({ fechaPresentacion: nuevaFecha });
        };

        const escogeFechaInscripcion = nuevaFecha => {
            this.setState({ fechaInscripcion: nuevaFecha });
        };

        const escogeFechaEfecto = nuevaFecha => {
            this.setState({ fechaEfecto: nuevaFecha });
        };

        const seleccionInscripcion = value => {
            this.setState({ inscripcionSeleccionada: value });
        };

        const seleccionRegistro = value => {
            this.setState({ registroSeleccionada: value });
        };

        const cambiarDiario = e => {
            this.setState({ diario: e.currentTarget.value });
        };
    
        const cambiarAsiento = e => {
            this.setState({ asiento: e.currentTarget.value });
        };
    
        const cambiarTomo = e => {
            this.setState({ tomo: e.currentTarget.value });
        };
    
        const cambiarFolio = e => {
            this.setState({ folio: e.currentTarget.value });
        };
    
        const cambiarInscripcion = e => {
            this.setState({ inscripcion: e.currentTarget.value });
        };
    
        const cambiarHoja = e => {
            this.setState({ hoja: e.currentTarget.value });
        };

        const guardarImpugnacion=()=>{
            if(this.datosImpugnacion.state.textoImpugnacion===''){
                alert(this.props.i18next.t("actas.validacuerdo3",{ns:"page"}))
                this.setState({pestañaImpugnacion:"datos-impugnacion"})
                return
            }
            if(this.datosAnulacion && this.datosAnulacion.state.fechaFirmeza && !this.datosAnulacion.state.registroSeleccionado){
                alert(this.props.i18next.t("actas.validacuerdo4",{ns:"page"}))
                this.setState({pestañaImpugnacion:"anulacion"})
                return
            }
            if(this.datosAnulacion && !this.datosAnulacion.state.fechaFirmeza && this.datosAnulacion.state.registroSeleccionado){
                alert(this.props.i18next.t("actas.validacuerdo5",{ns:"page"}))
                this.setState({pestañaImpugnacion:"anulacion"})
                return
            }

            if (!this.state.datosImpugnacion) {
                this.props.client.mutate({
                    mutation:gql`mutation{crearActaAcuerdoImpugnacion(
                      VALORES:{
                        ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO}
                        TEXTO:"""${this.datosImpugnacion.state.textoImpugnacion}"""
                        RESOLUCION:${this.datosImpugnacion.state.textoResolucion?'"""'+this.datosImpugnacion.state.textoResolucion+'"""':null}
                        ANULACION:${this.datosImpugnacion.state.textoAnulacion?'"""'+this.datosImpugnacion.state.textoAnulacion+'"""':null}
                        SUBSANACION:${this.datosImpugnacion.state.textoSubsanacion?'"""'+this.datosImpugnacion.state.textoSubsanacion+'"""':null}
                        FECHA_EFECTO:"${moment(this.datosImpugnacion.state.fechaEfectoImpugnacion).format("YYYY-MM-DD")}"
                      }
                    ){ID_IMPUGNACION,ID_ACUERDO}}`
            
                }).then(async result=>{
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}));
                    cerrarModalImpugnacion();
                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                });
            } else {
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarActaAcuerdoImpugnacion(
                      ID_IMPUGNACION:${this.state.datosImpugnacion.ID_IMPUGNACION},
                      VALORES:{
                        TEXTO:"""${this.datosImpugnacion.state.textoImpugnacion}"""
                        RESOLUCION:${this.datosImpugnacion.state.textoResolucion?'"""'+this.datosImpugnacion.state.textoResolucion+'"""':null}
                        ANULACION:${this.datosImpugnacion.state.textoAnulacion?'"""'+this.datosImpugnacion.state.textoAnulacion+'"""':null}
                        SUBSANACION:${this.datosImpugnacion.state.textoSubsanacion?'"""'+this.datosImpugnacion.state.textoSubsanacion+'"""':null}
                        FECHA_EFECTO:"${moment(this.datosImpugnacion.state.fechaEfectoImpugnacion).format("YYYY-MM-DD")}"
                      }
                    ){ID_IMPUGNACION,ID_ACUERDO}}`
            
                }).then(async result=>{
                    if(!this.state.datosAnulacion && this.datosAnulacion.state.fechaFirmeza){
                        await this.props.client.mutate({
                            mutation:gql`mutation{crearActaAcuerdoImpugnacionAnulacion(
                              VALORES:{
                                ID_IMPUGNACION:${this.state.datosImpugnacion.ID_IMPUGNACION},
                                FECHA_FIRMEZA:"${moment(this.datosAnulacion.state.fechaFirmeza).format("YYYY-MM-DD")}",
                                ID_REGISTRO:${this.datosAnulacion.state.registroSeleccionado.value},
                                DIARIO:${this.datosAnulacion.state.diario?'"'+this.datosAnulacion.state.diario+'"':null},
                                ASIENTO:${this.datosAnulacion.state.asiento?'"'+this.datosAnulacion.state.asiento+'"':null},
                                TOMO:${this.datosAnulacion.state.tomo?'"'+this.datosAnulacion.state.tomo+'"':null},
                                FOLIO:${this.datosAnulacion.state.folio?'"'+this.datosAnulacion.state.folio+'"':null},
                                INSCRIPCION:${this.datosAnulacion.state.inscripcion?'"'+this.datosAnulacion.state.inscripcion+'"':null},
                                HOJA:${this.datosAnulacion.state.hoja?'"'+this.datosAnulacion.state.hoja+'"':null},
                              }
                            ){ID_IMPUGNACION,ID_ANULACION}}`
                    
                        }).then(async()=>{
                        }).catch(err=>{
                            console.log(err)
                            alert(this.props.i18next.t("error",{ns:"global"}))
                        });
                    } else if(this.state.datosAnulacion && this.datosAnulacion.state.fechaFirmeza){
                        await this.props.client.mutate({
                            mutation:gql`mutation{actualizarActaAcuerdoImpugnacionAnulacion(
                              ID_ANULACION:${this.state.datosAnulacion.ID_ANULACION},
                              VALORES:{
                                FECHA_FIRMEZA:"${moment(this.datosAnulacion.state.fechaFirmeza).format("YYYY-MM-DD")}",
                                ID_REGISTRO:${this.datosAnulacion.state.registroSeleccionado.value},
                                DIARIO:${this.datosAnulacion.state.diario?'"'+this.datosAnulacion.state.diario+'"':null},
                                ASIENTO:${this.datosAnulacion.state.asiento?'"'+this.datosAnulacion.state.asiento+'"':null},
                                TOMO:${this.datosAnulacion.state.tomo?'"'+this.datosAnulacion.state.tomo+'"':null},
                                FOLIO:${this.datosAnulacion.state.folio?'"'+this.datosAnulacion.state.folio+'"':null},
                                INSCRIPCION:${this.datosAnulacion.state.inscripcion?'"'+this.datosAnulacion.state.inscripcion+'"':null},
                                HOJA:${this.datosAnulacion.state.hoja?'"'+this.datosAnulacion.state.hoja+'"':null},
                              }
                            ){ID_IMPUGNACION,ID_ANULACION}}`
                    
                        }).then(async()=>{
                        }).catch(err=>{
                            console.log(err)
                            alert(this.props.i18next.t("error",{ns:"global"}))
                        });
                    }
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}));
                    cerrarModalImpugnacion();
                }).catch(err=>{
                    console.log(err)
                    alert(this.props.i18next.t("error",{ns:"global"}))
                });
            }
        };

        const guardarInscripcion=()=>{
            if(!this.state.inscripcionSeleccionada){
                alert(this.props.i18next.t("actas.validacuerdo6",{ns:"page"}))
                return
            }
            
            if(!this.state.registroSeleccionada){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            if (this.state.tieneInscripcion) {
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarActasAcuerdosInscripcionRm(
                        ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO},
                        VALORES:{  
                        FECHA_PRESENTACION:"${moment(this.state.fechaPresentacion).format("YYYY-MM-DD")}",
                        ID_CALIFICACION_ACUERDO:${this.state.inscripcionSeleccionada.value},
                        FECHA_INSCRIPCION:"${moment(this.state.fechaInscripcion).format("YYYY-MM-DD")}",
                        FECHA_EFECTO:"${moment(this.state.fechaEfecto).format("YYYY-MM-DD")}",
                        ID_REGISTRO:${this.state.registroSeleccionada.value},
                        DIARIO:${this.state.diario?'"'+this.state.diario+'"':null},
                        ASIENTO:${this.state.asiento?'"'+this.state.asiento+'"':null},
                        TOMO:${this.state.tomo?'"'+this.state.tomo+'"':null},
                        FOLIO:${this.state.folio?'"'+this.state.folio+'"':null},
                        INSCRIPCION:${this.state.inscripcion?'"'+this.state.inscripcion+'"':null},
                        HOJA:${this.state.hoja?'"'+this.state.hoja+'"':null},
                    }){ID_ACUERDO}}`
      
                }).then(async result=>{
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
		  
                    cerrarModalInscripcion()
                    
                }).catch(err=>{
                    console.log(err)
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })
            } else {
                this.props.client.mutate({
                    mutation:gql`mutation{crearActasAcuerdosInscripcionRm(VALORES:{  
                        ID_ACUERDO:${this.state.acuerdoSeleccionado.ID_ACUERDO},        
                        FECHA_PRESENTACION:"${moment(this.state.fechaPresentacion).format("YYYY-MM-DD")}",
                        ID_CALIFICACION_ACUERDO:${this.state.inscripcionSeleccionada.value},
                        FECHA_INSCRIPCION:"${moment(this.state.fechaInscripcion).format("YYYY-MM-DD")}",
                        FECHA_EFECTO:"${moment(this.state.fechaEfecto).format("YYYY-MM-DD")}",
                        ID_REGISTRO:${this.state.registroSeleccionada.value},
                        DIARIO:${this.state.diario?'"'+this.state.diario+'"':null},
                        ASIENTO:${this.state.asiento?'"'+this.state.asiento+'"':null},
                        TOMO:${this.state.tomo?'"'+this.state.tomo+'"':null},
                        FOLIO:${this.state.folio?'"'+this.state.folio+'"':null},
                        INSCRIPCION:${this.state.inscripcion?'"'+this.state.inscripcion+'"':null},
                        HOJA:${this.state.hoja?'"'+this.state.hoja+'"':null},
                    }){ID_ACUERDO}}`
      
                }).then(async result=>{
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
		  
                    cerrarModalInscripcion()
                    
                }).catch(err=>{
                    console.log(err)
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })
            }
        }

        const abrirModalNuevoRegistro = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }
      
            this.setState({ 
                modalNuevoRegistro: true,
                registroNuevo: ''
            });
        }; 

        const cambiarNuevoRegistro=(ev)=>{
            this.setState({registroNuevo:ev.currentTarget.value})
        }
        
        const guardarRegistro=()=>{
            if(this.state.registroNuevo===''){
                alert(this.props.i18next.t("actas.validacuerdo7",{ns:"page"}));
                return
            }

            let existe=this.props.registroOptions.find(reg=>{return reg.label===this.state.registroNuevo})
            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }

            this.props.client.mutate({
                mutation:gql`mutation{crearRegistro(VALORES:{          
                    DESCRIPCION:"${this.state.registroNuevo}"
                }){ID_REGISTRO,DESCRIPCION}}`
            }).then(async(result)=>{
      
                await this.props.cargarRegistros()
                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}));
                cerrarModalRegistro();
      
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
                console.log(err)
            })
        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimir:false,
            })
        }

        return (
            <>
            {this.props.cargandoAcuerdo?
                <Cargando/>
            :
            <>
            <Dialog 
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="print"
            onClose={cerrarModalImprimir}
            title={this.props.i18next.t("print",{ns:"global"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalImprimir}
            style={styles.dialogL}
            >
                {!this.state.cargandoImprimir?
                    <div style={{ width: '100%', height: '500px'}} id='generar-pdf'>
                    </div>
                :
                    <Cargando />
                }

            </Dialog>
            <Dialog
            autoFocus={true}
            enforceFocus={true}
            icon="warning-sign"
            onClose={cerrarModalImpugnacion}
            title={this.props.i18next.t("actas.impugnacion",{ns:"page"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalImpugnacion}
            className="dialog-ancho dialog-scroll"
            >
            <div id="dialogo-actas">
                <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                    <Tabs 
                        id="datos-impugnaion"
                        animate={this.animacion} 
                        onChange={cambiarPestañaImpugnacion} 
                        selectedTabId={this.state.pestañaImpugnacion}
                        //renderActiveTabPanelOnly={true}
                    >
                        <Tab id="datos-impugnacion" title={this.props.i18next.t("actas.datosimpugnacion",{ns:"page"})} panel={
                            <Impugnacion
                            i18next={this.props.i18next}
                            datosImpugnacion={this.state.datosImpugnacion}
                            ref={(input) => { this.datosImpugnacion= input; }} />
                        } />

                        {this.state.datosImpugnacion?
					        <Tab id="anulacion"  title={this.props.i18next.t("actas.anulacion",{ns:"page"})}  panel={
                                <Anulacion 
                                i18next={this.props.i18next}
                                datosAnulacion={this.state.datosAnulacion} 
                                editable={this.props.editable} 
                                ref={(input) => { this.datosAnulacion= input; }}  />
                            } />
				        :<></>}

                        {this.state.datosImpugnacion?
					    	<Tab id="adjuntos"  title={this.props.i18next.t("actas.adjuntos",{ns:"page"})}  panel={
                                <Adjuntos 
                                    i18next={this.props.i18next}
                                    editable={this.props.editable} 
                                    id_asociado={this.state.acuerdoSeleccionado.ID_ACUERDO}
                                    adjuntos={this.state.adjuntosImpugnacion}
                                    cargarAdjuntos={this.cargarAdjuntosImpugnacion}
                                    entidad={this.state.entidadArchivoImpugnacion}
                                    tipoadjuntos={this.state.tipoAdjuntosImpugnacion} 
                                />
                            } />
				        :<></>}
                    </Tabs>
                </Card>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <AnchorButton intent={Intent.PRIMARY} onClick={guardarImpugnacion}>
                    {this.props.i18next.t("aceptar",{ns:"global"})}
                </AnchorButton>
                <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModalImpugnacion}>
                    {this.props.i18next.t("cancelar",{ns:"global"})}
                </AnchorButton>
                </div>
            </div>
            </Dialog>
            <Dialog
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title={this.props.i18next.t("actas.titleacuerdo",{ns:"page"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevoAbierto}
            className="dialog-ancho dialog-scroll"
            >
                <div id="dialogo-actas">
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <Tabs
                            id="propiedades-acuerdos"
                            animate={this.animacion}
                            onChange={cambiarPestaña}
                            selectedTabId={this.state.pestaña}
                            //renderActiveTabPanelOnly={true}
                        >
                            <Tab
                                id="datos-acuerdo"
                                title={this.props.i18next.t("actas.datosacuerdo",{ns:"page"})}
                                panel={
                                    <DatosAcuerdo
                                    i18next={this.props.i18next}
                                    votos={this.state.votos}
                                    editable={this.props.editable}
                                    numeroAcuerdo={this.props.acuerdos.length + 1}
                                    acuerdo={this.state.acuerdoSeleccionado}
                                    ref={input => {
                                        this.datosAcuerdos = input;
                                    }}
                                    favor={this.state.favor}
                                    contra={this.state.contra}
                                    abstencion={this.state.abstencion}
                                    porcFavor={this.state.porcFavor}
                                    porcContra={this.state.porcContra}
                                    porcAbstencion={this.state.porcAbstencion}
                                    />
                                }
                            />
                            <Tab
                                id="texto"
                                title={this.props.i18next.t("actas.texto",{ns:"page"})}
                                panel={
                                    <TextoActas
                                    i18next={this.props.i18next}
                                    editable={this.props.editable}
                                    ref={input => {
                                        this.textoActas = input;
                                    }}
                                    acuerdo={this.state.acuerdoSeleccionado}
                                    />
                                }
                            />
                            <Tab
                                id="votos"
                                title={this.props.i18next.t("actas.votos",{ns:"page"})}
                                panel={
                                    <Votos
                                    i18next={this.props.i18next}
                                    editable={this.props.editable}
                                    ref={input => {
                                        this.Votos = input;
                                    }}
                                    votos={this.state.votos}
                                    setVotos={this.setVotos} 
                                    />
                                }
                            />
                            <Tab
                                id="control_seguimiento"
                                title={this.props.i18next.t("actas.control",{ns:"page"})}
                                panel={
                                    <ControlAcuerdo
                                    i18next={this.props.i18next}
                                    editable={this.props.editable}
                                    ref={input => {
                                        this.ControlAcuerdo = input;
                                    }}
                                    acuerdo={this.state.acuerdoSeleccionado}
                                    empleados={this.props.empleados}
                                    />
                                }
                            />
                            {this.state.acuerdoSeleccionado ? (
                            <Tab
                                id="adjuntos"
                                title={this.props.i18next.t("actas.adjuntos",{ns:"page"})}
                                panel={
                                    <Adjuntos 
                                    i18next={this.props.i18next}
                                    editable={this.props.editable} 
                                    id_asociado={this.state.acuerdoSeleccionado.ID_ACUERDO}
                                    adjuntos={this.state.adjuntosAcuerdo}
                                    cargarAdjuntos={this.cargarAdjuntosAcuerdo}
                                    entidad={this.state.entidadArchivoAcuerdo}
                                    tipoadjuntos={this.state.tipoAdjuntosAcuerdo} 
                                    />
                                }
                            />
                            ) : null}
                        </Tabs>
                    </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                    </Tooltip>
                    <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                        <AnchorButton
                        id="guardar-cambios-acuerdos"
                        intent={Intent.PRIMARY}
                        onClick={
                            this.state.nuevoAcuerdo ? anadirAcuerdo : actualizarAcuerdo
                        }
                        >
                        {this.state.nuevoAcuerdo?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={cerrarModalInscripcion}
                title={this.props.i18next.t("actas.inscripcion",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalInscripcion}
                className="dialog-ancho"
            >
                <div id="dialogo-actas">
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <FormGroup label={this.props.i18next.t("actas.fechapresentacion",{ns:"page"})+":"} inline={true}>
                            <DateInput minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter}
                            onChange={escogeFechaPresentacion}
                            value={this.state.fechaPresentacion}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.fechainscripcion",{ns:"page"})+":"} inline={true}>
                            <DateInput minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter}
                            onChange={escogeFechaInscripcion}
                            value={this.state.fechaInscripcion}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.fechaefecto",{ns:"page"})+":"} inline={true}>
                            <DateInput minDate={new Date(1900, 1 ,1)}
                            {...jsDateFormatter}
                            onChange={escogeFechaEfecto}
                            value={this.state.fechaEfecto}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.calificacioninscripcion",{ns:"page"})+":"}>
                            <div style={!this.state.inscripcionSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.props.inscripcionOptions}
                                className="pixelAlrededor"
                                onChange={seleccionInscripcion}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                value={this.state.inscripcionSeleccionada}
                            />
                            </div>
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.registro",{ns:"page"})+":"}>
                            <div style={!this.state.registroSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.props.registroOptions}
                                className="pixelAlrededor"
                                onChange={seleccionRegistro}
                                backspaceRemovesValue={true}
                                isClearable={true}
                                value={this.state.registroSeleccionada}
                            />
                            </div>
                            <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})}>
                            <Button icon="add" onClick={abrirModalNuevoRegistro} />
                            </Tooltip>
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.diario",{ns:"page"})+":"}>
                            <InputGroup
                            value={this.state.diario}
                            onChange={cambiarDiario}
                            intent={"primary"}
                            fill={true}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.asiento",{ns:"page"})}>
                            <InputGroup
                            value={this.state.asiento}
                            onChange={cambiarAsiento}
                            intent={"primary"}
                            fill={true}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.tomo",{ns:"page"})}>
                            <InputGroup
                            value={this.state.tomo}
                            onChange={cambiarTomo}
                            intent={"primary"}
                            fill={true}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.folio",{ns:"page"})}>
                            <InputGroup
                            value={this.state.folio}
                            onChange={cambiarFolio}
                            intent={"primary"}
                            fill={true}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.inscripcion",{ns:"page"})}>
                            <InputGroup
                            value={this.state.inscripcion}
                            onChange={cambiarInscripcion}
                            intent={"primary"}
                            fill={true}
                            />
                        </FormGroup>
                        <FormGroup label={this.props.i18next.t("actas.hoja",{ns:"page"})}>
                            <InputGroup
                            value={this.state.hoja}
                            onChange={cambiarHoja}
                            intent={"primary"}
                            fill={true}
                            />
                        </FormGroup>
                    </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={guardarInscripcion}>
                        {this.props.i18next.t("aceptar",{ns:"global"})}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModalInscripcion}>
                        {this.props.i18next.t("cancelar",{ns:"global"})}
                    </AnchorButton>
                    </div>
                </div>
            </Dialog>
            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={cerrarModalRegistro}
                title={this.props.i18next.t("new",{ns:"global"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalNuevoRegistro}
            >
                <div id="dialogo-actas">
                    <Card interactive={true} elevation={Elevation.TWO} className="separarBordes">
                        <FormGroup label={this.props.i18next.t("actas.newregistro",{ns:"page"})} inline={true}>
                            <InputGroup
                                value={this.state.registroNuevo}
                                onChange={cambiarNuevoRegistro}
                                intent={this.state.registroNuevo ? "primary" : "danger"}
                            />
                        </FormGroup>
                    </Card>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton intent={Intent.PRIMARY} onClick={guardarRegistro}>
                        {this.props.i18next.t("aceptar",{ns:"global"})}
                    </AnchorButton>
                    <AnchorButton intent={Intent.PRIMARY} onClick={cerrarModalRegistro}>
                        {this.props.i18next.t("cancelar",{ns:"global"})}
                    </AnchorButton>
                    </div>
                </div>
            </Dialog>
            <Card id="acuerdos-actas" interactive={true} elevation={Elevation.TWO}>
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">
                    {this.props.i18next.t("actas.titleacuerdo",{ns:"page"})}
                </Callout>
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup align={Alignment.LEFT}>
                            <Button className="bp3-minimal" disabled={!this.props.acta} icon="add" text={this.props.i18next.t("new",{ns:"global"})}   onClick={abrirModalNuevo} />
                            <Button className="bp3-minimal" disabled={!this.props.acta} icon="edit" text={this.props.i18next.t("edit",{ns:"global"})}  onClick={abrirModalEditar}/>
                            <NavbarDivider />
                            <Tooltip content={this.props.i18next.t("actas.inscripcion",{ns:"page"})}>
                                <Button
                                className="bp3-minimal"
                                onClick={abrirDatosInscripcion}
                                id="inscripcion-mercantil"
                                disabled={!this.props.acta}
                                icon="label"
                                text={this.props.i18next.t("actas.inscripcion",{ns:"page"})}
                                />
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("actas.impugnacion",{ns:"page"})}>
                                <Button
                                className="bp3-minimal"
                                onClick={abrirDatosImpugnacion}
                                id="impugnacion-acuerdos"
                                disabled={!this.props.acta}
                                icon="ban-circle"
                                text={this.props.i18next.t("actas.impugnacion",{ns:"page"})}
                                />
                            </Tooltip>
                            <Tooltip content={this.props.i18next.t("print",{ns:"global"})}>
                                <Button 
                                    className="bp3-minimal" 
                                    onClick={()=>this.generarImprimir()}
                                    id="certificado-imprimir"
                                    icon="print" 
                                    disabled={!this.props.acta}
                                    text={this.props.i18next.t("print",{ns:"global"})}
                                />
                            </Tooltip>
                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                {this.state.cargando?
                    <Cargando/>
                :
                <BootstrapTable
                    keyField='ID_ACUERDO'
                    data={ this.props.acuerdos }
                    columns={ columns }
                    selectRow={ const_table.selectRow(handleOnSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                />
                }
            </Card>
            </>
            }
            </>
        )
    }
}

export default withApollo(AcuerdosActas);